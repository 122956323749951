import * as React from 'react'
import {FC} from 'react'
import MarginWrapper from '../../layout/margin-wrapper/'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import {useEffect} from 'react'
import {getInfo} from '../../../store/actions/creators-for-requests/info'
import {Heading} from '../../kit/heading'
import Paragraph from '../../kit/paragraph/'
import PersonalInfoList from './components/personal-info-list/'

export const PersonalData: FC = () => {
    const dispatch = useDispatch(),
        {data} = useSelector((state: RootStateType) => state.info)

    useEffect(() => {
        dispatch(getInfo())
    }, [dispatch])

    return (
        <main className={'main'}>
            <MarginWrapper>
                <div className={'heading-wrapper'}>
                    <Heading>Ваши личные данные</Heading>
                </div>
                <Paragraph>
                    Корректность и полнота указанных данных позволят сэкономить
                    время при расчёте стоимости полиса и получить персональные
                    предложения.
                </Paragraph>
                <PersonalInfoList data={data} />
            </MarginWrapper>
        </main>
    )
}
