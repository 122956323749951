import * as React from 'react'
import {UseBonusesReturnInterface} from '../../../../hooks/use-bonuses'
import BonusesActivated from './bonuses-activated'
import BonusesNotActivated from './bonuses-not-activated'
import {BonusesDisplayStatus} from '../../../../store/reducers/bonuses/count-and-card'
import BonusesActivationError from './bonuses-activation-error'

//import BonusesActivateError from './bonuses-activate-error'

export interface RenderBonusesContentPropsInterface
    extends React.ComponentProps<any> {
    bonusesInfo: UseBonusesReturnInterface
    showBonusesHistory: boolean
    bonusesDisplayMode: BonusesDisplayStatus
}

const RenderBonusesContent = ({
    bonusesInfo,
    showBonusesHistory,
    bonusesDisplayMode,
}: RenderBonusesContentPropsInterface) => {
    const {bonuses, chargeWelcomeBonusesCallback, bonusesHistory} = bonusesInfo
    switch (bonusesDisplayMode) {
        case BonusesDisplayStatus.notActivated: {
            return (
                <BonusesNotActivated
                    chargeWelcomeBonusesCallback={chargeWelcomeBonusesCallback}
                />
            )
        }
        case BonusesDisplayStatus.activated: {
            return (
                <BonusesActivated
                    showHistory={showBonusesHistory}
                    bonuses={bonuses}
                    bonusesHistory={bonusesHistory}
                />
            )
        }

        case BonusesDisplayStatus.subEvent: {
            return <BonusesActivationError />
        }

        default: {
            return null
        }
    }
}

export default React.memo(RenderBonusesContent)
