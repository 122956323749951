import * as React from 'react'
import {useEffect} from 'react'
import {Heading} from '../../../../kit/heading'
import MarginWrapper from '../../../../layout/margin-wrapper'
import './index.less'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../../../store/reducers'
import {
    addCalculatorToPage,
    CALCULATOR_PATHS,
} from '../../../../../helpers/for-calculators'
import {getInfo} from '../../../../../store/actions/creators-for-requests/info'
import {getBonuses} from '../../../../../store/actions/creators-for-requests/bonuses'
import RingLoaderCustomized from '../../../../kit/ring-loader-customized'

export const ShopTravelStandard: React.FunctionComponent = () => {
    const dispatch = useDispatch(),
        {
            info: {data: info},
            bonuses: {
                clientBonuses: {data: bonuses},
            },
        } = useSelector((state: RootStateType) => state)

    useEffect(() => {
        if (info) {
            // Если информация о пользователе уже загружена
            // мы извлекаем kiasId
            // делаем запрос баллов
            dispatch(getBonuses({kiasIsn: +info.isn}))
        } else {
            // Если информация о пользователе не загружена
            // загружаем ее
            dispatch(getInfo())
        }
    }, [dispatch, info])

    useEffect(() => {
        if (bonuses !== null && info !== null) {
            // todo - Сделать калька при размонтировании
            addCalculatorToPage(CALCULATOR_PATHS.TRAVEL_MAIN)
            window.addEventListener('eCalcLoaded', () => {
                interface WindowWithCalc extends Window {
                    TravelCalculator: any
                }
                // fix react #200 error
                const el = document.getElementById('eu-travel-calc-cont')
                if (el) {
                    new (window as WindowWithCalc &
                        typeof globalThis).TravelCalculator(
                        'eu-travel-calc-cont',
                        {
                            code: 'MTAwMTEwMDE4MDAwMDc4',
                            bonuses: {
                                count: bonuses.count,
                            },
                            clientIsn: +info.isn,
                        }
                    )
                }
            })
        }
    }, [info, bonuses])

    return (
        <MarginWrapper>
            <Heading>Страхование выезжающих</Heading>
            <div id={'eu-travel-calc-cont'}>
                <RingLoaderCustomized />
            </div>
            <div style={{width: 100, height: 200}}></div>
        </MarginWrapper>
    )
}
