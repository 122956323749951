import * as React from 'react'
import {CALC_IFRAMES} from '../../../../constants/calc-iframes'
import {Route} from 'react-router'
import './index.less'

type IframeInterface = {name: string; url: string}

const Iframe = ({name, url}: IframeInterface) => (
    <div className={`shop-calc-wrapper`}>
        <iframe className={`shop-calc-${name}`} title={'calc'} src={url} />
    </div>
)

export const CalcIframesRoutes = () => {
    return (
        <>
            {CALC_IFRAMES.map(({name, calcUrl, route}) => (
                <Route
                    exact
                    key={calcUrl}
                    path={route}
                    render={() => <Iframe name={name} url={calcUrl} />}
                />
            ))}{' '}
        </>
    )
}
