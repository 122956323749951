import {URL_REGISTRATION} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'
import {RegistrationInterface} from '../../types/requests'

const registrationRequest = (data: RegistrationInterface) => {
    return fetch(URL_REGISTRATION, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse)
}

export default registrationRequest
