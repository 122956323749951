import {authenticate} from '../../../store/actions/creators-for-requests/authentication'
import {LoginFormValuesInterface} from '../../../types/forms'

export default async (
    {phone, password}: LoginFormValuesInterface,
    executeRecaptcha: any,
    dispatch: any
) => {
    if (!executeRecaptcha) {
        return
    }

    const userToken = await executeRecaptcha('login') //берем данные о юзере чтобы отправить на бэк для проверки
    console.log('submitted')

    const formattedPhone = phone.replace(/\D/g, '').slice(1) // убираем все НЕ-цифры + семерку
    dispatch(
        authenticate({
            phone: formattedPhone,
            password,
            recaptchaResponse: userToken,
        })
    )
}
