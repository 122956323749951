import {TYPES} from '../../actions/actionTypes'
import {BonusesHistoryStateInterface} from '../../../types/state'
import {LoadBonusesHistoryActionsInterface} from '../../../types/actions'

const initialState: BonusesHistoryStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
}

export function bonusesHistory(
    state = initialState,
    action: LoadBonusesHistoryActionsInterface
): BonusesHistoryStateInterface {
    switch (action.type) {
        case TYPES.LOAD_BONUSES_HISTORY_STARTED: {
            return {
                ...initialState,
                requestStatus: 'started',
            }
        }

        case TYPES.LOAD_BONUSES_HISTORY_SUCCESS: {
            return {
                requestStatus: 'success',
                data: action.payload,
                errorMessage: '',
            }
        }

        case TYPES.LOAD_BONUSES_HISTORY_ERROR: {
            return {
                ...state,
                requestStatus: 'error',
                errorMessage: action.payload,
            }
        }

        default: {
            return state
        }
    }
}
