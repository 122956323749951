import * as React from 'react'
import './index.less'
// import CSS from 'csstype'

interface ParahraphOverrideStylesInterface {
    [key: string]: any
    // fontSize?: CSS.FontSizeProperty<string>
    // fontStyle?: CSS.FontStyleProperty
    // fontWeight?: CSS.FontWeightProperty
    // color?: CSS.ColorProperty
    // textAlign?: CSS.TextAlignProperty
    // paddingBottom?: CSS.PaddingBottomProperty<string>
}

interface ParagraphPropsInterface {
    overrideStyles?: ParahraphOverrideStylesInterface
    className?: string
    children: React.ReactNode
}

const DEFAULT_STYLES = {
    fontSize: '1rem',
    fontWeight: 400,
    color: 'black',
    textAlign: 'initial',
    fontStyle: 'initial',
    paddingBottom: '0.8rem',
} as const

const Paragraph = ({
    children,
    className = '',
    overrideStyles = DEFAULT_STYLES,
}: ParagraphPropsInterface) => {
    return (
        <p className={`paragraph ${className ?? ''}`} style={overrideStyles}>
            {children}
        </p>
    )
}

export default React.memo(Paragraph)
