import {ROUTES} from './routes'

export type MenuInterface = {
    url: string
    desktopIconClassName: string
    name: string
}[]

const MENU = [
    {
        name: 'Мой ДМС',
        url: ROUTES.DMS,
        desktopIconClassName: 'icon-menu-dms',
    },
    {
        name: 'Полисы',
        url: ROUTES.POLICIES,
        desktopIconClassName: 'icon-menu-policies',
    },
    {
        name: 'Магазин',
        url: ROUTES.SHOP,
        desktopIconClassName: 'icon-menu-store',
    },
    {
        name: 'Зарабатывай',
        url: ROUTES.CLIENT_AGENT,
        desktopIconClassName: 'icon-menu-money',
    },
    {
        name: 'Бонусы',
        url: ROUTES.BONUSES,
        desktopIconClassName: 'icon-menu-bonuses',
    },
    {
        name: 'Связаться',
        url: ROUTES.APPEALS,
        desktopIconClassName: 'icon-menu-message',
    },
    {
        name: 'Данные',
        url: ROUTES.PERSONAL_DATA,
        desktopIconClassName: 'info-menu-personal-data',
    },
] as const

export default MENU
