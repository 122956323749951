const PoliciesTypesNormalized: {[key: string]: string} = {
    'КАСКО на случай хищения и полной гибели': 'КАСКО',
    'КАСКО (ver 2.0)': 'КАСКО',
    'КАСКО автотранспортных средств': 'КАСКО',
    'КАСКО (ver 2.0) (резерв)': 'КАСКО',
    'Такси КАСКО': 'КАСКО',
    'Такси УГОН': 'Угон авто',
    'Страхование ДМС- НС (студент)': 'ДМС - НС',
    'Страхование ВЗР по Рссии и странам СНГ': 'Путешествия',
    'Страхование ВЗР': 'Путешествия',
    'Страхование ВЗР путешественников': 'Путешествия',
    'Страхование расходов по отмене поездки (ОВ)': 'Отмена поездки',
    'Ипотечное страхование основное': 'Ипотека',
    'Ипотечное страхование для банков': 'Ипотека',
    'Страхование спецтехники': 'Спецтехника',
    'Страхование водного транспорта': 'Водный транспорт',
    'Страхование водного транспорта (судов)': 'Водный транспорт',
    'Страхование грузов': 'Грузы',
    'Страхование животных': 'Питомцы',
    'Стандартное страхование СХР с гос поддержкой': 'СХР',
    'Страхование СХР с гос поддержкой': 'СХР',
    'Страхование СМР': 'СМР',
    'Страхование залога (заклада)': 'Залог',
    'Страхование расходов по отмене поездки': 'Отмена поездки',
    'Страхование предпринимательских рисков': 'Предпринимательские риски',
    'Страхование финансовых рисков': 'Финансовые риски',
    'Страхование ЧС': 'ЧС',
    'Страхование жилых помещений Краснодарского края': 'Имущество (помещения)',
    'Страхование Городская квартира': 'Городская квартира',
    'Страхование ЖД транспорта': 'ЖД транспорт',
    'Морское страхование': 'Морское',
    'Страхование бытовой техники': 'Бытовая техника',
    'Страхование Загородный дом': 'Загородный дом',
    'Страхование урожая сельхоз культур с ГОС поддержкой': 'Урожай',
    'Страхование Жилой дом': 'Жилой дом',
    'Страхование  сельхоз животных с ГОС поддержкой': 'Сельхоз животные',
    'Страхование  аквакультуры с ГОС поддержкой': 'Аквакультура',
    'Комплексное страхование грузов': 'Грузы',
    'Страхование Квартира Лайт': 'Квартира',
    'Страхование Квартира': 'Квартира',
    'Страхование Твое имущество (Квартира)': 'Квартира',
    'Страхование Домашний очаг (Жилой дом)': 'Жилой дом',
    'Страхование Домашний очаг (Квартира)': 'Квартира',
    'Комплексное страхование спецтехники': 'Спецтехника',
    'Страхование животных (ver 2.0) (для физ. лиц)': 'Питомцы',
    'Ипотечное страхование №2_БанкДОМ.РФ': 'Ипотека',
    'Страхование Твое имущество (Жилой дом)': 'Жилой дом',
    'Страхование животных (ver 2.0) (для юр. лиц)': 'Жилой дом',
    'Страхование имущества на территории Чувашской Республики':
        'Имущество (Чувашия)',
    'Страхование от НС (Программа А и Б)': 'Несчастный случай',
    'Страхование от НС и Б': 'Несчастный случай',
    'Страхование от НС пассажиров': 'НС пассажиры',
    'Страхование НС военнослужащих': 'НС военнослужащие',
    'Страхование от НС (Экскурсия)': 'НС экскурсия',
    'Страхование от НС (Защита водителя)': 'НС водитель',
    'Страхование от НС (ОЛИМП)': 'Несчастный случай',
    'Страхование от НС (ОЛИМП 3)': 'Несчастный случай',
    'Страхование от НС (Эконом НС)': 'НС эконом',
    'Страхование от НС (Отдыхающие)': 'НС отдыхающие',
    'Страхование от НС (Надежный мир)': 'НС надежный мир',
    'Страхование от НС (Школа и детский сад)': 'НС кола и детсад',
    'Страхование от НС (Юниор)': 'НС юниор',
    'Страхование от НС (Стандарт-плюс Спорт)': 'Несчастный случай',
    'Страхование от НС (Защита НС)': 'Несчастный случай',
    'Страхование от НС (Туристы и спорт)': 'Несчастный случай',
    'Копия Страхование КВАРТИРА': 'Квартира',
    'Страхование от НС (ОЛИМП 2)': 'Несчастный случай',
    'Страхование от НС (Парусный спорт)': 'НС спорт',
    'Страхование от НС (Беговел)': 'Несчастный случай',
    'Страхование от НС (Экспресс-Стандарт)': 'Несчастный случай',
    'Страхование от НС (Экспресс-Спорт-Детский)': 'Несчастный случай',
    'Страхование от НС (Фехтование)': 'НС фехтование',
    'Страхование от НС (Борьба)': 'НС борьба',
    'Страхование от НС (СПОРТ)': 'НС спорт',
    'Страхование от НС (Экспресс-Спорт)': 'Несчастный случай',
    'Страхование от НС (Семья)': 'НС семья',
    'Страхование от НС и Б (СТОПВИРУС)': 'НС стопвирус',
    'Страхование экипажа судов': 'Экипаж судов',
    'Страхование ГО СРО': 'Гражданская ответственность',
    'Страхование субсидиарной ГО СРО': 'Гражданская ответственность',
    'Страхование ГО врачей, мед учереждений': 'Гражданская ответственность',
    'Страхование ГО качества товаров, работ и услуг':
        'Гражданская ответственность',
    'Страхование ГО застройщика': 'Гражданская ответственность',
    'Страхование ГО автоперевозчиков. Экспедиторов.':
        'Гражданская ответственность',
    'Страхование ГО при эксплуатации ОПО': 'Гражданская ответственность',
    'Страхование ГО при осуществлении оценочной деятельности':
        'Гражданская ответственность',
    'Страхование ГО аудиторов': 'Гражданская ответственность',
    'Страхование ГО ЧОП': 'Гражданская ответственность',
    'Страхование ГО туроператоров': 'Гражданская ответственность',
    'Страхование ГО физических и юридических лиц':
        'Гражданская ответственность',
    'Страхование ГО СМР': 'Гражданская ответственность',
    'Страхование ГО водного транспорта': 'Гражданская ответственность',
    'Страхование ГО нотариусов': 'Гражданская ответственность',
    'Страхование ГО ТСЖ': 'Гражданская ответственность',
    'Страхование ГО арбитражного управляющего': 'Гражданская ответственность',
    'Страхование ГО СРО ОДО': 'Гражданская ответственность',
    'Страхование ОВВТ': 'Гражданская ответственность',
    'Страхование ГО кадастрового инженера': 'Гражданская ответственность',
    'Страхование ГО за нарушение договорных обязательств':
        'Гражданская ответственность',
    'Страхование ГО работодателей': 'Гражданская ответственность',
}

const getInsuranceType = (type: string): string =>
    PoliciesTypesNormalized[type] ?? type

export default getInsuranceType
