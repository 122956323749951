import {
    loadAppealsError,
    loadAppealsStarted,
    loadAppealsSuccess,
} from '../actionCreators'
import {URL_APPEALS} from '../../../constants/urls'
import {handleResponse} from '../../../helpers/for-requests'
import {GetAppealsResponseType} from '../../../types/responses'

export function getAppeals() {
    return async function(dispatch: any) {
        dispatch(loadAppealsStarted())
        return fetch(URL_APPEALS, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((data: GetAppealsResponseType) => {
                if (data.success === true)
                    dispatch(loadAppealsSuccess(data.data))
                else dispatch(loadAppealsError(data.error))
            })
            .catch((error: Error) => dispatch(loadAppealsError(error.message)))
    }
}
