import * as React from 'react'
import './index.less'

import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'

import '../../common-styles/index.less'
import {Heading} from '../../kit/heading'
import Paragraph from '../../kit/paragraph'
import BonusesPromo from '../../kit/bonuses-promo'
import useBonuses from '../../../hooks/use-bonuses'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import moment from 'moment'
import {formatBonusesAmount} from '../../../helpers/for-formatting/formatBonuses'

const sizesHeading = {
    xs: 24,
    sm: 24,
    lg: 16,
}

const Bonuses: React.FC = props => {
    const bonusesInfo = useBonuses()

    // отображаем загрузку, если:
    // 1) нет инфы по бонусам
    // 2) есть инфа, есть номер карты, нет истории бонусов
    if (
        !bonusesInfo.bonuses ||
        (bonusesInfo.bonuses?.cardNumber && !bonusesInfo.bonusesHistory)
    ) {
        return <RingLoaderCustomized />
    }

    return (
        <main className='main bonuses-main'>
            <div className={'bonuses-main__content'}>
                <Row justify={'space-between'}>
                    <Col {...sizesHeading}>
                        <Heading>Бонусные баллы</Heading>
                        <Paragraph overrideStyles={{fontStyle: 'italic'}}>
                            Баллы можно использовать для оплаты любого вида
                            страхования, кроме ОСАГО. Баллами можно оплатить до
                            20% стоимости полиса. Чтобы оплатить полис баллами,
                            рассчитайте стоимость полиса в онлайн калькуляторе и
                            оплатите часть полиса баллами.
                        </Paragraph>
                    </Col>
                </Row>
                <div className={'bonuses-main__history text_large'}>
                    {bonusesInfo.bonusesHistory?.map(val => (
                        <div key={val.date.toString()}>
                            <Row>
                                <span className={'text_bold'}>
                                    {moment(val.date).format('DD-MM-YYYY')}
                                </span>
                            </Row>
                            <Row>
                                <Col>{val.name}</Col>
                                <Col>
                                    <span className={'text_bold'}>
                                        {formatBonusesAmount(val.amount)}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            </div>
            <div className={'bonuses-main__bonuses-wrapper'}>
                <BonusesPromo
                    bonusesInfo={bonusesInfo}
                    showBonusesHistory={false}
                />
            </div>
        </main>
    )
}

export default React.memo(Bonuses)
