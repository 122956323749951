import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import {InfoInterface} from '../../../types'
import {getInfo} from '../../../store/actions/creators-for-requests/info'
import MarginWrapper from '../margin-wrapper'
import hideJdiv from '../../../helpers/jivo/hide-jdiv'
import showJdiv from '../../../helpers/jivo/show-jdiv'
import {getDmsContracts} from '../../../store/actions/creators-for-requests/dmsContracts'

interface ShortClientDataInterface {
    phone: string
    firstName: string
    lastName: string
    middleName: string
}

const addEventListenerOnChat = ({
    firstName,
    lastName,
    middleName,
    phone,
}: ShortClientDataInterface) => {
    window.addEventListener('onBitrixLiveChat', (event: any) => {
        const widget = event.detail.widget

        // Сокрытие формы контактных данных
        widget.mutateTemplateComponent('bx-livechat-form-welcome', {
            template: '',
        })

        // Установка дополнительных данных
        // В тексте вы можно использовать HTML-теги: BR, B, U, I, S, URL.
        // [b]Виктор Иванов[/b] (victor@ivanov.ru) [br]
        widget.setCustomData(
            `Данные о клиенте из ЛК: [br]
            ФИО: ${lastName} ${firstName} ${middleName} [br]
            Телефон: ${phone}`
        )
    })
}

const addChat = () => {
    ;(function(w, d, u) {
        var s = d.createElement('script')
        s.async = true
        s.src = u + '?' + ((Date.now() / 60000) | 0)
        var h = d.getElementsByTagName('script')[0]
        h.parentNode!.insertBefore(s, h)
    })(window, document, '//code-ya.jivosite.com/widget/78rOqrgI9d')
}

let chatWasConnected: boolean = false

const EuroinsChat = ({children}: React.PropsWithChildren<{}>) => {
    const dispatch = useDispatch(),
        clientDmsContracts = useSelector(
            (state: RootStateType) => state.dmsContracts
        )

    // инициализируем первичную загрузку ДМС контрактов
    React.useEffect(() => {
        if (clientDmsContracts.requestStatus === 'didNotLoad')
            dispatch(getDmsContracts())
    }, [clientDmsContracts, dispatch])

    const isAuthenticated = useSelector(
            (state: RootStateType) => state.authentication.authenticated
        ),
        clientData = useSelector((state: RootStateType) => state.info.data)

    const addChatToPage = React.useCallback(() => {
        if (!clientData) return

        window.jivo_onChangeState = state => {
            if (state === 'label') {
                hideJdiv()
            } else if (state === 'chat' || state === 'departments') {
                const {
                    lastName,
                    firstName,
                    middleName,
                    phone,
                    email,
                } = clientData

                const dmsContracts =
                    clientDmsContracts.data &&
                    clientDmsContracts.data.length > 0
                        ? clientDmsContracts.data.map(contract => {
                              return {
                                  content: `${contract.beginDate} - ${contract.endDate}`,
                                  title: `${contract.id} (${contract.status})`,
                                  key: 'Период действия',
                                  link: '',
                              }
                          })
                        : [
                              {
                                  content: ``,
                                  title: 'Договоров ДМС нет',
                                  key: '',
                                  link: '',
                              },
                          ]

                window.jivo_api.setContactInfo({
                    name: `${lastName} ${firstName} ${middleName}`,
                    phone: '8' + phone,
                    email,
                    description: '',
                })

                window.jivo_api.setCustomData([
                    {
                        content: `${lastName} ${firstName} ${middleName}, ${phone}`,
                        title: '',
                        key: 'Данные о клиенте из ЛК',
                        link: '',
                    },
                    ...dmsContracts,
                ])
            }
        }

        // @ts-ignore
        window.jivo_onClose = () => {
            hideJdiv()
        }
        // @ts-ignore
        window.jivo_onMessageReceived = () => {
            showJdiv()
        }

        addChat()
    }, [clientData, clientDmsContracts])

    React.useEffect(() => {
        dispatch(getInfo())
    }, [dispatch])

    React.useEffect(() => {
        // чат загрузится только после ответ о ДМС контрактах
        if (
            isAuthenticated &&
            clientData !== null &&
            clientDmsContracts !== null &&
            (clientDmsContracts.requestStatus === 'success' ||
                clientDmsContracts.requestStatus === 'error') &&
            !chatWasConnected
        ) {
            chatWasConnected = true
            addChatToPage()
        }
        // todo - данные о клиенте могут обновится
    }, [isAuthenticated, clientData, clientDmsContracts, addChatToPage])

    return null
}

export default React.memo(EuroinsChat)
