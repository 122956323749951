import * as React from 'react'
import BurgerMenu from './burger-menu/'
import Menu from './menu'
import Logo from '../../kit/logo/'
import './index.less'
import MarginWrapper from '../margin-wrapper/'

export const Header: React.FunctionComponent = () => {
    return (
        <header className={'header'}>
            <MarginWrapper className={'header__content'}>
                <Logo className={'header__logo'} />
                <Menu />
                <BurgerMenu />
            </MarginWrapper>
        </header>
    )
}
