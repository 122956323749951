import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import HowWorkWithEuroins from '../how-work-with-euroins'
import PaymentClaimForm from '../../forms/payment-claim-form'
import PurchasesPaymentByCode from '../purchases-payments-by-code'
import ClientAgentPromoCode from '../client-agent-promo-code'
import {RequestStateInterface} from '../../../types/state'
import BecomeAgentStatusInterface from '../../../types/models/become-agent-status-interface'
import {ClientAgentSaleInterface} from '../../../types/models/client-agent-sale'

enum PANEL_ROUTES {
    HOW_WORK_WITH_EUROINS,
    PAYMENT_CLAIM_FORM,
    PROMO_CODE,
    PURCHASES_PAYMENTS_BY_CODE,
}

interface ClientAgentFirstPanelPropsInterface {
    becomeAgentStatus: RequestStateInterface<BecomeAgentStatusInterface>
}

const ClientAgentFirstPanel: FC<ClientAgentFirstPanelPropsInterface> = ({
    becomeAgentStatus,
}) => {
    const [panelRoute, setPanelRoute] = useState<PANEL_ROUTES>(
            PANEL_ROUTES.HOW_WORK_WITH_EUROINS
        ),
        [
            saleForPaymentClaimForm,
            setSaleForPaymentClaimForm,
        ] = useState<ClientAgentSaleInterface | null>(null),
        handleShowPurchasesByCode = useCallback(() => {
            setPanelRoute(PANEL_ROUTES.PURCHASES_PAYMENTS_BY_CODE)
        }, []),
        handleShowPromoCode = useCallback(() => {
            setPanelRoute(PANEL_ROUTES.PROMO_CODE)
        }, []),
        handleGetPaid = useCallback((sale: ClientAgentSaleInterface) => {
            setSaleForPaymentClaimForm(sale)
            setPanelRoute(PANEL_ROUTES.PAYMENT_CLAIM_FORM)
        }, [])

    useEffect(() => {
        if (becomeAgentStatus.data === null) return
        if (becomeAgentStatus.data.state === 'Подписан')
            setPanelRoute(PANEL_ROUTES.PROMO_CODE)
    }, [becomeAgentStatus])

    switch (panelRoute) {
        case PANEL_ROUTES.HOW_WORK_WITH_EUROINS:
            return <HowWorkWithEuroins />
        case PANEL_ROUTES.PAYMENT_CLAIM_FORM:
            return (
                <PaymentClaimForm
                    sale={saleForPaymentClaimForm}
                    onBack={handleShowPurchasesByCode}
                />
            )
        case PANEL_ROUTES.PROMO_CODE:
            return (
                <ClientAgentPromoCode
                    onShowPurchasesByCode={handleShowPurchasesByCode}
                />
            )
        case PANEL_ROUTES.PURCHASES_PAYMENTS_BY_CODE:
            return (
                <PurchasesPaymentByCode
                    onBack={handleShowPromoCode}
                    onGetPaid={handleGetPaid}
                />
            )
    }
}

export default ClientAgentFirstPanel
