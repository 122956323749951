import {SendAppealInterface, UploadsType} from '../../../types/requests'
import * as H from 'history'
import sendAppealRequest from '../../../requests/send-appeal'
import {ROUTES} from '../../../navigation/routes'

export default async (
    values: {textAppeal: string},
    uploads: UploadsType,
    setErrors: {(error: string): void},
    history: H.History
) => {
    const reqBody: SendAppealInterface = {
        text: values.textAppeal,
        attachments: uploads.map(cur => {
            return {
                fileRemark: '',
                fileData: cur,
            }
        }),
    }

    sendAppealRequest(reqBody)
        .then(json => {
            if (json.success === true) {
                history.push(ROUTES.APPEALS)
            } else throw new Error('Ошибка отправки обращения')
        })
        .catch(setErrors)
}
