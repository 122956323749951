import {useEffect, useState} from 'react'
import DmsContractInterface from '../../../../../types/models/dms-contract'
import {RequestStateInterface} from '../../../../../types/state'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../../helpers/for-changing-requests-state/create-request-state'
import getDmsContract from '../../../../../requests/contracts/get-dms-contract'

const getDmsContractInitState = createInitialState<DmsContractInterface>()

export const useContract = (isn: string) => {
    const [state, setState] = useState<
        RequestStateInterface<DmsContractInterface>
    >(getDmsContractInitState)

    const onComponentDidMount = async () => {
        setState(createStartedState())
        try {
            const result = await getDmsContract(isn)
            if (result.success) setState(createSuccessState(result.data))
            else setState(createErrorState(result.errors))
        } catch (e) {
            //@ts-ignore
            setGetDmsContractState(createErrorState([e.message]))
        }
    }

    useEffect(() => {
        onComponentDidMount()
    }, [])

    return state
}
