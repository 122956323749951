import * as React from 'react'
import {FunctionComponent} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {RootStateType} from '../../../store/reducers'
import './index.less'
import RegisterForm from '../../forms/register-form'
import AfterRegSuccess from '../../forms/register-form/registration-success'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {PUBLIC_RECAPTCHA_KEY} from '../../../constants'
import EnterPagesBg from '../../layout/bg/enter-pages'

enum DisplayMode {
    form,
    afterSuccess,
}

export const Registration: FunctionComponent = props => {
    const [mode, setMode] = React.useState<DisplayMode>(DisplayMode.form)
    const {authenticated} = useSelector(
            (state: RootStateType) => state.authentication
        ),
        history = useHistory()

    React.useEffect(() => {
        if (authenticated) {
            history.push('/policies')
        }
    }, [history, authenticated])

    return (
        <EnterPagesBg>
            <GoogleReCaptchaProvider reCaptchaKey={PUBLIC_RECAPTCHA_KEY}>
                <h1 className='auth-heading'>Регистрация</h1>
                {mode === DisplayMode.form ? (
                    <RegisterForm
                        onSuccess={() => setMode(DisplayMode.afterSuccess)}
                    />
                ) : (
                    <AfterRegSuccess />
                )}
            </GoogleReCaptchaProvider>
        </EnterPagesBg>
    )
}
