import * as React from 'react'
import {FC} from 'react'
import RingLoaderCustomized from '../../../kit/ring-loader-customized'
import ClientAgentPanel from '../../../kit/client-agent-panel'

const InitialPanel: FC = () => {
    return (
        <ClientAgentPanel bgFilled>
            <p className={'become-agent__heading'}>Стать агентом</p>
            <RingLoaderCustomized verticalMargin={'50px auto 0'} />
            <p className={'text_center'}>Загрузка статуса заявки...</p>
        </ClientAgentPanel>
    )
}

export default InitialPanel
