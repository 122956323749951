import {URL_ATTACHMENT} from '../../../../constants/urls'

const downloadFileCallback = (isn: number, refIsn: number) => {
    return fetch(URL_ATTACHMENT, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({isn, refIsn}),
    })
        .then(res => {
            if (res.status === 200) {
                return {
                    blob: res.blob(),
                    type: res.headers.get('content-type'),
                }
            } else throw new Error('download file error')
        })
        .then(async obj => {
            // может быть, это можно как-то переписать?
            const indexOfTypeName = obj.type!.toString().indexOf('/') + 1 // парсит тип файла в хедере контент-тайп
            const type = obj.type!.slice(indexOfTypeName)
            const filename = `attachment.${type}`
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(await obj.blob)
            link.download = filename

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)
        })
        .catch(console.log)
}
export default downloadFileCallback
