import * as React from 'react'
import './index.less'
import {Link, LinkProps} from 'react-router-dom'

interface ELinkPropsInterface extends LinkProps<any> {
    buttonType?: 'primary' | 'secondary' | 'inverted'
    iconClassName?: string
}

const ELink = React.memo(
    ({
        buttonType,
        iconClassName = '',
        className = '',
        children,
        ...rest
    }: ELinkPropsInterface) => {
        return buttonType ? (
            <Link
                {...rest}
                className={`e-link e-link-button  e-link-button_${buttonType} ${
                    iconClassName ? 'e-link_with-icon' : ''
                } ${className}`}
            >
                {iconClassName ? (
                    <span className={`${iconClassName} e-link__icon`} />
                ) : null}
                {children}
            </Link>
        ) : (
            <Link className={`e-link ${className}`} {...rest}>
                {children}
            </Link>
        )
    }
)

export default ELink
