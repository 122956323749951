import * as React from 'react'
import MarginWrapper from '../../../layout/margin-wrapper'
import {Heading} from '../../../kit/heading'
import '../../../common-styles/index.less'
import {Link, useParams} from 'react-router-dom'
import {ROUTES} from '../../../../navigation/routes'
import './index.less'
import Col from 'antd/es/col'
import 'antd/es/col/style'
import Row from 'antd/es/row'
import 'antd/es/row/style'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../../store/reducers'
import {AppealInterface} from '../../../../types'
import {useEffect} from 'react'
import {getAppeals} from '../../../../store/actions/creators-for-requests/appeals'
import RingLoaderCustomized from '../../../kit/ring-loader-customized'
import downloadFileCallback from './download-file'

// todo: описать интерфейсы

const tableSizes = {
    xs: 24,
    sm: 24,
    lg: 12,
} as const

const Appeal = () => {
    const dispatch = useDispatch(),
        {id} = useParams<{id: string}>(),
        {data} = useSelector((state: RootStateType) => state.appeals)

    useEffect(() => {
        if (!data) dispatch(getAppeals())
    }, [data, dispatch])

    let appeal: AppealInterface

    if (data && id) {
        //@ts-ignore
        appeal = data[id]
    }

    const downloadFile = React.useCallback(downloadFileCallback, [])

    return (
        <main className={'main'}>
            <MarginWrapper>
                <Row justify={'space-between'}>
                    <Col flex={'initial'}>
                        <Heading>
                            Обращение № {data ? appeal!.id ?? '' : ''}
                        </Heading>
                    </Col>
                    <Col flex={'initial'}>
                        <Link
                            to={ROUTES.APPEALS}
                            className={'main__heading-link'}
                        >
                            Назад
                        </Link>
                    </Col>
                </Row>
                {data ? (
                    <Row>
                        <Col {...tableSizes}>
                            <table className={'appeal'}>
                                <tbody>
                                    <tr>
                                        <td>Дата создания</td>
                                        <td>{appeal!.dateCreated}</td>
                                    </tr>
                                    <tr>
                                        <td>Статус</td>
                                        <td>{appeal!.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Текст обращения</td>
                                        <td>{appeal!.textAppeal}</td>
                                    </tr>
                                    <tr>
                                        <td>Ответ</td>
                                        <td>
                                            {appeal!.answer
                                                ? `${appeal!.answer}`
                                                : 'ответа пока нет'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Прикрепленные файлы</td>
                                        <td>
                                            {appeal!.attachments.length > 0
                                                ? appeal!.attachments.map(
                                                      (
                                                          cur: any,
                                                          idx: number
                                                      ) => {
                                                          return (
                                                              <div
                                                                  key={cur.isn}
                                                                  onClick={() =>
                                                                      downloadFile(
                                                                          cur.isn,
                                                                          cur.refIsn
                                                                      )
                                                                  }
                                                                  className={
                                                                      'appeal__link'
                                                                  }
                                                              >{`Скачать файл ${idx +
                                                                  1}`}</div>
                                                          )
                                                      }
                                                  )
                                                : null}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                ) : (
                    <RingLoaderCustomized />
                )}
            </MarginWrapper>
        </main>
    )
}

export default React.memo(Appeal)
