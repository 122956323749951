import CreateFileRequestInterface from '../types/requests/create-file'
import {URL_FILE} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'
import {CreateFileResponseType} from '../types/responses/create-file'

const createFile = (
    data: CreateFileRequestInterface
): Promise<CreateFileResponseType> =>
    fetch(URL_FILE, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// const createFile = (
//     data: CreateFileRequestInterface
// ): Promise<CreateFileResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: true,
//             })
//         }, 2000)
//     })

/*
    Фейковый ошибочный запрос
 */

export default createFile
