import {FC, default as React, useMemo} from 'react'
import PersonalInfoItem from '../personal-info-item'
import PersonalInfoCard from '../personal-info-card'
import {RegistrationAddressFormInterface} from '../../../../../types/forms'
import isEmpty from '../../../../../helpers/for-objects/is-empty'
import {POPUP_FORM_ID} from '../personal-info-list'
import {ROUTES} from '../../../../../navigation/routes'

interface DriverLicenseCardPropsInterface {
    data: RegistrationAddressFormInterface | null
}

const CARD_TITLE = 'Адрес регистрации'

const RegistrationAddressCard: FC<DriverLicenseCardPropsInterface> = ({
    data,
}) => {
    console.log('RegistrationAddressCard', data)
    const linkToPopup = useMemo(
        () => `${ROUTES.PERSONAL_DATA}/${POPUP_FORM_ID.REGISTRATION_ADDRESS}`,
        []
    )

    return (
        <>
            <PersonalInfoCard title={CARD_TITLE} linkTo={linkToPopup}>
                <PersonalInfoItem
                    label={'Адрес'}
                    value={isEmpty(data?.address)}
                />
                {/* <PersonalInfoItem label={'Улица'} value={values.street} />
                <PersonalInfoItem label={'Дом'} value={isEmpty(data?.house)} />
                <PersonalInfoItem label={'Строение'} value={values.building} />
                <PersonalInfoItem label={'Квартира'} value={values.flat} />
                <PersonalInfoItem
                    label={'Почтовый индекс'}
                    value={values.postCode}
                /> */}
            </PersonalInfoCard>

            {/* <PopupWrapper open={openPopup} onClose={closePopup}>
                <RegistrationAddressForm
                    title={CARD_TITLE}
                    data={data}
                    onSuccessfulDataChange={goToPersonalData}
                    cityName={values.cityName}
                />
            </PopupWrapper> */}
        </>
    )
}

export default React.memo(RegistrationAddressCard)
