import {combineReducers} from 'redux'
import {clientBonuses} from './count-and-card'
import {isActivated} from './isActivated'
import {activateWelcome} from './activate-welcome'
import {bonusesHistory} from './bonuses-history'

export const bonuses = combineReducers({
    clientBonuses,
    isActivated,
    activateWelcome,
    bonusesHistory,
})
