import * as React from 'react'
import Input from '../input'
import './index.less'
import ErrorParagraph from '../error-paragraph'
import {ReactNode} from 'react'

interface FieldPropsInterface {
    label?: string | undefined
    fieldName?: string
    value?: string | string[] | number
    onChange?: (
        eventOrPath: string | React.ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
    errors?: string | undefined
    inputClassName?: string
    type?: string
    required?: boolean
    disabled?: boolean
    placeholder?: string
    component?: ReactNode
}

const Field = ({
    fieldName = 'fieldName',
    value = '',
    onChange = () => {},
    label = undefined,
    errors = undefined,
    type = 'text',
    inputClassName = '',
    required = false,
    disabled = false,
    placeholder = '',
    component,
}: FieldPropsInterface) => {
    return (
        <>
            <div className={'field'}>
                {label ? (
                    <label className={'field__label'} htmlFor={fieldName}>
                        {required ? (
                            <span style={{color: 'red'}}>*</span>
                        ) : null}{' '}
                        {label}
                    </label>
                ) : null}
                {component ? (
                    component
                ) : (
                    <Input
                        placeholder={placeholder}
                        className={inputClassName}
                        value={value}
                        type={type}
                        onChange={onChange}
                        fieldName={fieldName}
                        disabled={disabled}
                    />
                )}
            </div>
            {errors ? <ErrorParagraph errors={errors} /> : null}
        </>
    )
}

export default React.memo(Field)
