import {URL_ATTACHMENT} from '../../constants/urls'
import {handleResponseWithBlob} from '../../helpers/for-requests'
import {GetPdfResponseType} from '../../types/responses/get-pdf'
import GetPdfRequestInterface from '../../types/requests/get-pdf'

const getPdfEpolicy = (
    data: GetPdfRequestInterface
): Promise<GetPdfResponseType> =>
    fetch(URL_ATTACHMENT, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            isn: data.isn,
            refIsn: data.refIsn,
        }),
    }).then(handleResponseWithBlob)

export default getPdfEpolicy
