export function statusIsCorrect(status: number) {
    return status === 200 || status === 401 || status === 400
}

export function handleResponse(response: Response) {
    if (statusIsCorrect(response.status)) {
        return response.json()
    } else throw new Error('Статус ответа сервера ' + response.status)
}

export function handleResponseWithBlob(response: Response) {
    if (statusIsCorrect(response.status)) {
        return response.blob()
    } else throw new Error('Статус ответа сервера ' + response.status)
}
