import * as React from 'react'
import './index.less'

interface InputPropsInterface extends React.HTMLAttributes<HTMLInputElement> {
    fieldName: string
    value: string | string[] | number
    className?: string
    type?: string
    disabled?: boolean
    placeholder?: string
}

const Input = ({
    fieldName,
    value,
    onChange,
    placeholder = '',
    className = '',
    type = 'text',
    disabled = false,
}: InputPropsInterface) => {
    return (
        <input
            placeholder={placeholder}
            name={fieldName}
            value={value}
            onChange={onChange}
            className={`input ${className}`}
            type={type}
            disabled={disabled}
        />
    )
}

export default React.memo(Input)
