import * as React from 'react'
import {FC} from 'react'
import ELink from '../../../kit/e-link'
import {ROUTES} from '../../../../navigation/routes'

interface OsagoProjectProps {
    isn: number
}

const OsagoProject: FC<OsagoProjectProps> = ({isn}) => {
    return (
        <ELink
            buttonType={'primary'}
            to={`${ROUTES.SHOP_OSAGO_STANDARD}/${isn}`}
        >
            Перейти в проект
        </ELink>
    )
}

export default OsagoProject
