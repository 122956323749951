import * as React from 'react'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import ClientAgentPanel from '../../kit/client-agent-panel'
import Button from '../../kit/button'
import 'antd/lib/upload/style'
import './index.less'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'
import UploadDocumentContainer, {
    FileInfoInterface,
} from './upload-document-container'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import {getInfo} from '../../../store/actions/creators-for-requests/info'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import Field from '../../kit/forms-common/field'
import {RequestInfoInterface, RequestStateInterface} from '../../../types/state'
import BecomeAgentInterface from '../../../types/models/become-agent-interface'
import createBecomeAgent from '../../../requests/create-become-agent'
import {CreateBecomeAgentResponseType} from '../../../types/responses/create-become-agent'
import CreateBecomeAgentRequestInterface from '../../../types/requests/create-become-agent'
import createFile from '../../../requests/create-file'
import {CreateFileResponseType} from '../../../types/responses/create-file'
import validate from './validate'
import useFieldsStateAndCallbacks from './use-fields-state-and-callbacks'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../helpers/for-changing-requests-state/create-request-state'

interface FilesStateInterface extends RequestInfoInterface {}

interface BecomeAgentFormPropsInterface {
    onSubmitBecomeAgentForm: () => void
}

export type FileInfoStateType = FileInfoInterface | null

const becomeAgentInitialState = createInitialState<BecomeAgentInterface>(),
    filesInitialState: FilesStateInterface = {
        requestStatus: 'didNotLoad',
        errorMessage: '',
    }

// todo - очень универсальный код - можно вынести
const createFilesStartedState = (): FilesStateInterface => ({
        requestStatus: 'started',
        errorMessage: '',
    }),
    createFilesSuccessState = (): FilesStateInterface => ({
        requestStatus: 'success',
        errorMessage: '',
    }),
    createFilesErrorState = (errors: string[]): FilesStateInterface => ({
        requestStatus: 'error',
        errorMessage: errors.join(', '),
    })

/*
   Проверяем статус,
   затем если клиент не отправлял заявку светим форму
   если клиент отправлял заявку светим блок статуса заявки
 */
const BecomeAgentForm: FC<BecomeAgentFormPropsInterface> = ({
    onSubmitBecomeAgentForm,
}) => {
    // todo - не отображать форму и все эти конетели если клиент уже агент
    const clientInfo = useSelector((state: RootStateType) => state.info),
        dispatch = useDispatch(),
        {
            email,
            passportMain,
            passportAddress,
            snils,
            handleSubmit,
            handleChangeEmail,
            handleSelectPassportMain,
            handleSelectPassportAddress,
            handleSelectSnils,
            handleRemovePassportMain,
            handleRemovePassportAddress,
            handleRemoveSnils,
        } = useFieldsStateAndCallbacks(),
        [becomeAgent, setBecomeAgent] = useState<
            RequestStateInterface<BecomeAgentInterface>
        >(becomeAgentInitialState),
        // [a, setA] = useState<number>(4),
        [files, setFiles] = useState<FilesStateInterface>(filesInitialState),
        [errors, setErrors] = useState<string>(''),
        loaderText = useMemo(() => {
            if (becomeAgent.requestStatus === 'started')
                return 'Отправка заявки...'
            else if (files.requestStatus === 'started')
                return 'Прикрепление файлов к заявке...'
            return ''
        }, [becomeAgent.requestStatus, files.requestStatus]),
        extractDataForCreateBecomeAgent = useCallback((): CreateBecomeAgentRequestInterface => {
            if (clientInfo.data !== null) {
                const {data} = clientInfo
                return {
                    remark: `${data.lastName} ${data.firstName} ${data.middleName} ${email}`,
                }
            } else {
                throw new Error('Нет данных о клиенте')
            }
        }, [clientInfo, email]),
        handleSend = useCallback(async () => {
            setErrors('')

            const errors = validate(
                [passportMain, passportAddress, snils],
                email,
                clientInfo.data
            )

            if (errors.length !== 0) return setErrors(errors.join(', '))

            const data = extractDataForCreateBecomeAgent()
            let becomeAgentResponseData: CreateBecomeAgentResponseType | null = null

            setBecomeAgent(createStartedState())
            try {
                becomeAgentResponseData = await createBecomeAgent(data)

                if (becomeAgentResponseData.success)
                    setBecomeAgent(
                        createSuccessState(becomeAgentResponseData.data)
                    )
                else
                    setBecomeAgent(
                        createErrorState(becomeAgentResponseData.errors)
                    )
            } catch (e) {
                setBecomeAgent(createErrorState([e.message]))
            }

            /*
                Если отправка заявки не удалась,
                файлы не загружаем
             */
            if (
                becomeAgentResponseData === null ||
                !becomeAgentResponseData.success
            )
                return

            /*
                Ставим флаг что идет
                загрузка файлов и отправка заявки
             */
            setFiles(createFilesStartedState())
            try {
                /*
                    Делаем как as FileInfoInterface,
                    так как проверили что not null выше
                    в функции валидации
                 */
                const refIsn = becomeAgentResponseData.data.isn,
                    responsesData: CreateFileResponseType[] = await Promise.all(
                        [
                            createFile({
                                refIsn,
                                ...(passportMain as FileInfoInterface),
                            }),
                            createFile({
                                refIsn,
                                ...(passportAddress as FileInfoInterface),
                            }),
                            createFile({
                                refIsn,
                                ...(snils as FileInfoInterface),
                            }),
                        ]
                    )

                /*
                    Если хоть один файл не загрузился,
                    говорим что ошибка загрузки файлов
                 */
                responsesData.forEach(responseData => {
                    if (!responseData.success)
                        throw new Error(responseData.errors.join(', '))
                })

                setFiles(createFilesSuccessState())
            } catch (e) {
                setFiles(createFilesErrorState([e.message]))
            }
        }, [
            extractDataForCreateBecomeAgent,
            passportMain,
            passportAddress,
            snils,
            email,
            clientInfo.data,
        ])

    useEffect(() => {
        dispatch(getInfo())
    }, [dispatch])

    useEffect(() => {
        /*
            Смотрим на статусы запросов,
            если они успешные то вызываем кулбэк
            на отправку формы
         */
        if (
            becomeAgent.requestStatus === 'success' &&
            files.requestStatus === 'success'
        )
            onSubmitBecomeAgentForm()
    }, [
        becomeAgent.requestStatus,
        files.requestStatus,
        onSubmitBecomeAgentForm,
    ])

    useEffect(() => {
        if (clientInfo.data !== null)
            handleChangeEmail({target: {value: clientInfo.data.email}})
    }, [clientInfo.data, handleChangeEmail])

    if (clientInfo.data === null)
        return (
            <ClientAgentPanel bgFilled>
                <p className={'become-agent__heading'}>Стать агентом</p>
                <RingLoaderCustomized verticalMargin={'50px'} />
                <p className={'text_center'}>Загрузка данных клиента...</p>
            </ClientAgentPanel>
        )

    const {lastName, firstName, middleName} = clientInfo.data

    if (
        becomeAgent.requestStatus === 'error' ||
        files.requestStatus === 'error'
    )
        return (
            <ClientAgentPanel bgFilled>
                <p className={'become-agent__heading'}>Ошибка отправки!</p>
                <p className={'text_center'}>
                    Пожалуйста обратитесь в службу технической поддержки. <br />
                    {becomeAgent.errorMessage} {files.errorMessage}
                </p>
            </ClientAgentPanel>
        )

    return (
        <ClientAgentPanel bgFilled>
            <p className={'become-agent__heading'}>Стать агентом</p>
            <p className={'text_center'}>
                Для создания заявки на заключение агентского договора с ЕВРОИНС
                необходимо предоставить следующие данные:
                <br />
                <span className={'become-agent__formats'}>
                    (формат документов pdf, jpeg или png)
                </span>
            </p>

            {loaderText.length > 0 ? (
                <>
                    <RingLoaderCustomized verticalMargin={'50px'} />
                    <p className={'text_center'}>{loaderText}</p>
                </>
            ) : (
                <form
                    className={'become-agent__form'}
                    onSubmit={handleSubmit}
                    method={'POST'}
                >
                    <div className={'become-agent__client-data'}>
                        <p>
                            {lastName} {firstName} {middleName}{' '}
                        </p>
                        <Field
                            fieldName={'email'}
                            value={email}
                            onChange={handleChangeEmail}
                        />
                    </div>

                    <UploadDocumentContainer
                        label={
                            'Копия или фотография паспорта (основной разворот):'
                        }
                        onSelectFile={handleSelectPassportMain}
                        onRemoveFile={handleRemovePassportMain}
                    />
                    <UploadDocumentContainer
                        label={
                            'Копия или фотография паспорта (страница регистрации):'
                        }
                        onSelectFile={handleSelectPassportAddress}
                        onRemoveFile={handleRemovePassportAddress}
                    />
                    <UploadDocumentContainer
                        label={'Копия или фотография СНИЛС:'}
                        onSelectFile={handleSelectSnils}
                        onRemoveFile={handleRemoveSnils}
                    />
                    <div style={{marginTop: '.5rem'}} />
                    <ErrorParagraph errors={errors} />
                    <div className={'become-agent__send-button-wrapper'}>
                        <Button buttonType={'secondary'} onClick={handleSend}>
                            Отправить
                        </Button>
                    </div>
                </form>
            )}
        </ClientAgentPanel>
    )
}

export default BecomeAgentForm
