import * as React from 'react'
import {FC, useState} from 'react'
import {Header} from '../../../layout/header'
import MarginWrapper from '../../../layout/margin-wrapper'
import {Heading} from '../../../kit/heading'
import {useParams} from 'react-router-dom'
import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'
import Filters from './filters'
import FilialsOnMap from './filials-on-map'
import {useContract} from './hooks/use-contract'
import {useFilials} from './hooks/use-filials'
import FilialsList from './filials-list'
import SavePolicy from './save-policy'
import RingLoaderCustomized from '../../../kit/ring-loader-customized'
import './index.less'
import {useSelector} from 'react-redux'
import {RootStateType} from '../../../../store/reducers'

interface ContractProps {}

const Contract: FC<ContractProps> = () => {
    const {isn} = useParams<{isn: string}>()
    const getDmsContractState = useContract(isn)
    const contract = getDmsContractState.data
    // const [medcenterIsn, setMedcenterIsn] = useState<number | null>(0)
    const info = useSelector((state: RootStateType) => state.info.data)
    // const filials = useFilials(medcenterIsn).data || []

    if (!contract) return <RingLoaderCustomized />
    console.log(isn, info?.isn, isn && info?.isn && 'render')
    return (
        <>
            <Header />
            <main className={'main dms-main'}>
                <MarginWrapper
                    className={'dms-main__wrapper dms-contract__wrapper'}
                >
                    <Heading>Договор №{contract.id}</Heading>
                    <Row
                        style={{marginBottom: '1rem'}}
                        justify={'space-between'}
                    >
                        <Row gutter={24}>
                            <Col>Статус: {contract.status}</Col>
                            <Col>Дата начала: {contract.beginDate}</Col>
                            <Col>Дата окончания: {contract.endDate}</Col>
                        </Row>
                        <SavePolicy contractIsn={+isn} />
                    </Row>

                    <div className={'lpu-map'}>
                        {isn && info?.isn && (
                            <iframe
                                src={`https://dms-lpu.euro-ins.ru/${isn}/${info?.isn}`}
                                className={'lpu-map__iframe'}
                            />
                        )}
                    </div>
                </MarginWrapper>
            </main>
        </>
    )
}

export default Contract
