import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import ClientAgentPanel from '../../kit/client-agent-panel'
import './index.less'
import {Row, Col} from 'antd/lib/grid'
import 'antd/lib/grid/style'
import Button from '../../kit/button/'
import {RequestStateInterface} from '../../../types/state'
import {
    ClientAgentSaleInterface,
    ClientAgentSalesType,
} from '../../../types/models/client-agent-sale'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../helpers/for-changing-requests-state/create-request-state'
import readPurchasesPaymentByCode from '../../../requests/read-purchases-payment-by-code'
import RingLoaderCustomized from '../../kit/ring-loader-customized'

interface PurchasesPaymentByCodePropsInterface {
    onBack: () => void
    onGetPaid: (sale: ClientAgentSaleInterface) => void
}

const initialState = createInitialState<ClientAgentSalesType>()

const PurchasesPaymentByCode: FC<PurchasesPaymentByCodePropsInterface> = ({
    onBack,
    onGetPaid,
}) => {
    const [sales, setSales] = useState<
            RequestStateInterface<ClientAgentSalesType>
        >(initialState),
        handleComponentDidMount = useCallback(async () => {
            try {
                setSales(createStartedState())
                const result = await readPurchasesPaymentByCode()
                if (result.success) setSales(createSuccessState(result.data))
                else setSales(createErrorState(result.errors))
            } catch (e) {
                setSales(createErrorState([e.message]))
            }
        }, [])

    useEffect(() => {
        handleComponentDidMount().then()
    }, [handleComponentDidMount])

    return (
        <ClientAgentPanel>
            <p className={'text_bold text_center'}>
                Покупки и выплаты вознаграждения <br /> по Вашему промокоду
            </p>
            {/* todo - Не забыть просклонять комиссию (рубля, рублей) */}
            {sales.requestStatus === 'started' ? (
                <RingLoaderCustomized verticalMargin={'60px'} />
            ) : null}
            <ul
                className={
                    'purchases-payments-list purchases-payments-by-code__list'
                }
            >
                {sales.data?.map((sale, i) => (
                    <li className={'purchases-payments-list__item'} key={i}>
                        <div>
                            <p className={'purchases-payments-list__ins-type'}>
                                Вид страхования (10%)
                            </p>
                            <div className={'purchases-payments-list__date'}>
                                {sale.datePay}
                            </div>
                            <div className={'purchases-payments-list__client'}>
                                {sale.name}
                            </div>
                        </div>
                        <div>
                            <p
                                className={
                                    'purchases-payments-list__commission'
                                }
                            >
                                {sale.amount} руб.
                            </p>
                            <p
                                className={
                                    'purchases-payments-list__claim-status'
                                }
                            >
                                {sale.statusName === 'Не выплачено' ? (
                                    <Button
                                        onClick={() => {
                                            onGetPaid(sale)
                                        }}
                                    >
                                        Получить
                                    </Button>
                                ) : (
                                    sale.statusName
                                )}
                            </p>
                        </div>
                    </li>
                ))}

                {/*<li className={'purchases-payments-list__item'}>*/}
                {/*    <div>*/}
                {/*        <p className={'purchases-payments-list__ins-type'}>*/}
                {/*            Комиссия ДМС (10%)*/}
                {/*        </p>*/}
                {/*        <div className={'purchases-payments-list__date'}>*/}
                {/*            29 апреля 2020*/}
                {/*        </div>*/}
                {/*        <div className={'purchases-payments-list__client'}>*/}
                {/*            Пётр, +79263245652*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <p className={'purchases-payments-list__commission'}>*/}
                {/*            1609 рублей*/}
                {/*        </p>*/}
                {/*        <p className={'purchases-payments-list__claim-status'}>*/}
                {/*            <Button buttonType={'primary'}>Получить</Button>*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</li>*/}
            </ul>

            <div className={'text_center'}>
                <Button buttonType={'secondary'} onClick={onBack}>
                    Назад
                </Button>
            </div>
        </ClientAgentPanel>
    )
}

export default PurchasesPaymentByCode
