import * as React from 'react'
import {FunctionComponent} from 'react'
import MarginWrapper from '../../../layout/margin-wrapper/'
import {Heading} from '../../../kit/heading'
import '../../../common-styles/index.less'

const InsuranceCaseProperty: FunctionComponent = props => {
    return (
        <main className={'main'}>
            <MarginWrapper>
                <div className={'heading-wrapper'}>
                    <Heading>Имущество</Heading>
                </div>
                <ul>заполнить</ul>
            </MarginWrapper>
        </main>
    )
}

export default React.memo(InsuranceCaseProperty)
