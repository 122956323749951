import {
    loadInfoError,
    loadInfoStarted,
    loadInfoSuccess,
} from '../actionCreators'
import {URL_INFO} from '../../../constants/urls'
import {handleResponse} from '../../../helpers/for-requests'
import {GetInfoResponseType} from '../../../types/responses'

export function getInfo() {
    return async function(dispatch: any) {
        dispatch(loadInfoStarted())
        return fetch(URL_INFO, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((data: GetInfoResponseType) => {
                if (data.success === true) dispatch(loadInfoSuccess(data.data))
                else dispatch(loadInfoError(data.error))
            })
            .catch((error: Error) => dispatch(loadInfoError(error.message)))
    }
}
