import * as React from 'react'
import Paragraph from '../../../paragraph'
import Button from '../../../button'
import Field from '../../../forms-common/field'
import {FormikErrors, useFormik} from 'formik'
import ErrorParagraph from '../../../forms-common/error-paragraph'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../../../store/reducers'
import {welcomeBonusesActivationRevertInitialState} from '../../../../../store/actions/actionCreators'

interface BonusesNotActivatedPropsInterface {
    chargeWelcomeBonusesCallback: {(cardNumber?: string): void}
}

const buttonStyles = {
    color: '#f7d117',
    background: '#0a1971',
    fontSize: '0.85em',
    padding: '10px 20px',
    minWidth: '150px',
}

interface CardFormInterface {
    cardNumber: string
}

export default React.memo(
    ({chargeWelcomeBonusesCallback}: BonusesNotActivatedPropsInterface) => {
        const activationError = useSelector(
                (state: RootStateType) =>
                    state.bonuses.activateWelcome.errorMessage
            ),
            dispatch = useDispatch()
        const formik = useFormik<CardFormInterface>({
            initialValues: {
                cardNumber: '',
            },
            validate: values => {
                const errors: FormikErrors<CardFormInterface> = {}
                if (!values.cardNumber) {
                    errors.cardNumber = 'Введите номер карты'
                }

                return errors
            },
            onSubmit: values => {
                chargeWelcomeBonusesCallback(values.cardNumber)
            },
        })
        React.useEffect(
            () =>
                dispatch.bind(
                    null,
                    welcomeBonusesActivationRevertInitialState()
                ),
            [dispatch]
        )

        return (
            <>
                <div>
                    <div
                        className={
                            'bonuses-promo__text_huge bonuses-promo__text_blue'
                        }
                    >
                        +500
                    </div>
                    <div
                        className={
                            'bonuses-promo__text_big bonuses-promo__text_blue'
                        }
                    >
                        получите баллы за регистрацию
                    </div>
                </div>
                <div
                    className={'bonuses-promo__text_middle text_weight-normal'}
                >
                    Привяжите пластиковую клубную карту или моментально получите
                    электронную
                </div>
                <form
                    className={'changing-area__enter-promocode'}
                    onSubmit={formik.handleSubmit}
                >
                    <Paragraph
                        overrideStyles={{
                            paddingBottom: '0.3rem',
                            fontSize: '1.1em',
                        }}
                    >
                        Уже есть пластиковая карта/промокод
                    </Paragraph>
                    <Field
                        inputClassName={'changing-area__input'}
                        fieldName={'cardNumber'}
                        value={formik.values.cardNumber}
                        onChange={formik.handleChange}
                        errors={formik.errors.cardNumber}
                        type={'text'}
                        placeholder={'введите номер карты или промокод'}
                    />
                    <Button style={buttonStyles} buttonType={'inverted'}>
                        Начислить
                    </Button>
                    <ErrorParagraph errors={activationError} />
                </form>
                <div className={'changing-area__get-bonus'}>
                    <Paragraph
                        overrideStyles={{
                            paddingBottom: '0.3rem',
                            fontSize: '1.1em',
                        }}
                    >
                        У меня нет карты EUROINS CLUB
                    </Paragraph>
                    <Button
                        buttonType={'inverted'}
                        onClick={chargeWelcomeBonusesCallback.bind(
                            null,
                            undefined
                        )}
                    >
                        Получить карту
                    </Button>
                </div>
            </>
        )
    }
)
