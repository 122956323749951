import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAgreements} from '../../../../store/actions/creators-for-requests/agreements'
import {RootStateType} from '../../../../store/reducers'
import {Heading} from '../../../kit/heading'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../../navigation/routes'

import MarginWrapper from '../../../layout/margin-wrapper/'
import PolicyCard from '../policy-card/'
import moment from 'moment'
import '../index.less'

import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'

import '../../../common-styles/index.less'
import RingLoaderCustomized from '../../../kit/ring-loader-customized'

const cardSizes = {
    xs: 24,
    sm: 12,
    lg: 8,
    xl: 6,
}

const PoliciesArchive: React.FC = props => {
    const dispatch = useDispatch(),
        {data} = useSelector((state: RootStateType) => state.agreements)

    React.useEffect(() => {
        if (!data) dispatch(getAgreements())
    }, [data, dispatch])

    return (
        <main className='main'>
            <MarginWrapper className='policies-page-wrapper'>
                <Row justify={'space-between'}>
                    <Col>
                        <Heading>Истекшие полисы</Heading>
                    </Col>
                    <Col flex={'0 0 90px'}>
                        <Link
                            to={ROUTES.POLICIES}
                            className={'main__heading-link'}
                        >
                            Назад
                        </Link>
                    </Col>
                </Row>
                {data ? (
                    <div className='policy-card-list'>
                        <Row gutter={[24, 30]}>
                            {data!.map(cur => {
                                if (
                                    moment(cur.endDate, 'DD-MM-YYYY').isBefore(
                                        Date.now()
                                    )
                                ) {
                                    return (
                                        <Col key={cur.id} {...cardSizes}>
                                            <PolicyCard
                                                epolicy={cur.epolicy}
                                                isn={cur.isn}
                                                policyType={cur.productName}
                                                activeUntilDate={moment(
                                                    cur.endDate,
                                                    'DD-MM-YYYY'
                                                )}
                                                insuredName={cur.id}
                                            />
                                        </Col>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </Row>
                    </div>
                ) : (
                    <RingLoaderCustomized />
                )}
            </MarginWrapper>
        </main>
    )
}

export default React.memo(PoliciesArchive)
