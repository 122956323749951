import {ROUTES} from '../navigation/routes'

export default [
    {
        bgClassName: 'osago',
        title: 'ОСАГО',
        calcURL: ROUTES.SHOP_OSAGO_STANDARD,
    },
    {
        bgClassName: 'property',
        title: 'Страхование квартиры',
        calcURL: ROUTES.SHOP_PROPERTY_STANDARD,
    },
    {
        bgClassName: 'dms',
        title: 'Добровольное медицинское страхование',
        calcURL: ROUTES.SHOP_DMS_STANDARD,
    },
    {
        bgClassName: 'kasko',
        title: 'КАСКО',
        calcURL: ROUTES.SHOP_KASKO_STANDARD,
    },
    {
        bgClassName: 'vzr',
        title: 'Страхование путешествий',
        calcURL: ROUTES.SHOP_TRAVEL_STANDARD,
    },
    {
        bgClassName: 'ns',
        title: 'Страхование от несчастного случая (sport)',
        calcURL: ROUTES.SHOP_NS_SPORT,
    },
    {
        bgClassName: 'ns',
        title: 'Страхование от несчастного случая (covid)',
        calcURL: ROUTES.SHOP_NS_COVID,
    },
    {
        bgClassName: 'ns',
        title: 'Страхование от несчастного случая (antimite)',
        calcURL: ROUTES.SHOP_NS_ANTIMITE,
    },
] as const
