import * as React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import './index.less'

interface RingLoaderCustomizedInterface {
    size?: number
    color?: string
    verticalMargin?: string
}

const RingLoaderCustomized = React.memo(
    ({
        size = 100,
        color = '#1b167e',
        verticalMargin = '200px',
    }: RingLoaderCustomizedInterface) => {
        return (
            <div className={'ring-loader'}>
                <ClipLoader
                    size={size}
                    color={color}
                    css={`
                        margin: ${verticalMargin} auto;
                    `}
                />
            </div>
        )
    }
)

export default RingLoaderCustomized
