import {ROUTES} from '../navigation/routes'

export const PHONE = '+7 (495) 926-51-55'
export const PHONE_HREF = '+74959265155'

export const PUBLIC_RECAPTCHA_KEY = '6LeN1OcUAAAAACGHK_wHWQNG4Sef_49nLH3jF9JQ'

export const numberOfMonthToString = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
] as const

export const VALID_POLICY_NAMES = ['Полис', 'policy'] as const

export const insuranceTypes = [
    {
        name: 'Автострахование',
        url: ROUTES.INSURANCE_CASE_AUTO,
        iconClass: 'icon-auto',
    },
    {
        name: 'Путешествия',
        url: ROUTES.INSURANCE_CASE_TRAVEL,
        iconClass: 'icon-travel',
    },
    {
        name: 'Несчастный случай',
        url: ROUTES.INSURANCE_CASE_ACCIDENT,
        iconClass: 'icon-accident',
    },
    {
        name: 'Имущество',
        url: ROUTES.INSURANCE_CASE_PROPERTY,
        iconClass: 'icon-property',
    },
    {
        name: 'Медицина',
        url: ROUTES.INSURANCE_CASE_HEALTH,
        iconClass: 'icon-medicine',
    },
] as const
