import * as React from 'react'
import {FC} from 'react'
import Form from 'antd/lib/form'
import {Row, Col, Input, Button, Modal} from 'antd'
import 'antd/lib/grid/style'
import 'antd/lib/input/style'
import 'antd/lib/button/style'
import 'antd/lib/modal/style'
import './index.less'
import putDmsContract from '../../../requests/contracts/put-dms-contract'
import DmsContractMainPartInterface from '../../../types/models/dms-contract-main-part'

interface SearchDmsContractFormPropsInterface {
    className?: string
    runGetDmsContracts?: Function
    contracts: DmsContractMainPartInterface[]
}

const SearchDmsContractForm: FC<SearchDmsContractFormPropsInterface> = ({
    className = '',
    runGetDmsContracts,
    contracts,
}) => {
    const [form] = Form.useForm()

    const validator = async (r: any, v: string) => {
        if (!v) return Promise.reject('Введите номер')
        const length = v.trim().length
        if (length !== 7 && length !== 8)
            return Promise.reject('Должен состоять из 7-8 цифр')
        const contractWasAdded = contracts.some(contract =>
            contract.id.endsWith(v.trim())
        )
        if (contractWasAdded) return Promise.reject('Договор уже добавлен')
        return Promise.resolve()
    }

    const onSearch = async () => {
        try {
            await form.validateFields()

            const searchFieldValue = form
                .getFieldValue('dmsContractSearchField')
                .replaceAll('/', '')

            putDmsContract(searchFieldValue)
                .then(result => {
                    if (result.success) {
                        Modal.success({
                            title: 'Договор найден',
                            content: `Договор ${searchFieldValue} найден и успешно прикреплён с списку договоров`,
                            onOk: () => {
                                if (runGetDmsContracts) runGetDmsContracts()
                            },
                        })
                    } else {
                        Modal.error({
                            title: 'Договор не найден',
                            content: `Поиск по номеру договора "${searchFieldValue}" не дал результатов`,
                        })
                        return
                    }
                })
                .catch(() => {
                    Modal.error({
                        title: 'Ошибка',
                        content: `Во время отправки запроса произошла ошибка, попробуйте повторить позднее`,
                    })
                })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Row className={className}>
            <Col sm={24}>
                <p>
                    Если вы не нашли договор в списке ниже, то попробуйте найти
                    через поиск.
                </p>
            </Col>
            <Col sm={20}>
                <Form layout='vertical' form={form}>
                    <Row gutter={24}>
                        <Col xs={24} sm={16} md={14} lg={10}>
                            <Form.Item
                                label='Номер договора (последние 7-8 цифр):'
                                name='dmsContractSearchField'
                                rules={[{validator, required: true}]}
                            >
                                <Input
                                    placeholder='Например: 9872772'
                                    size='middle'
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={8} className={'search-form__button-col'}>
                            <Form.Item name='dmsContractSearchButton'>
                                <Button
                                    type='primary'
                                    shape='round'
                                    size='middle'
                                    className={'search-form__button'}
                                    onClick={onSearch}
                                >
                                    Найти
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default SearchDmsContractForm
