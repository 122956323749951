import moment from 'moment'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import PolicyCard from './policy-card'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import * as React from 'react'
import {AgreementInterface, UserAgreementInterface} from '../../../types'
import Paragraph from '../../kit/paragraph'

const cardSizes = {
        xs: 24,
        sm: 12,
        lg: 8,
    } as const,
    isActive = (policy: AgreementInterface) => {
        return moment(Date.now()).isBefore(moment(policy.endDate, 'DD-MM-YYYY'))
    }

interface PoliciesListPropsInterface {
    data: (AgreementInterface | UserAgreementInterface)[] | null
    className?: string
}

const PoliciesList: React.FC<PoliciesListPropsInterface> = ({
    data,
    className,
}) => {
    if (!data) return <RingLoaderCustomized />

    if (data.length === 0)
        return <Paragraph>У вас пока нет действующих полисов.</Paragraph>

    return (
        <Row
            className={`policy-card-list ${className ? className : ''}`}
            justify={'start'}
            gutter={[24, 30]}
        >
            {data.map(cur => {
                if (isActive(cur)) {
                    return (
                        <Col key={cur.id} {...cardSizes}>
                            <PolicyCard
                                epolicy={cur.epolicy}
                                isn={cur.isn}
                                policyType={cur.productName}
                                activeUntilDate={moment(
                                    cur.endDate,
                                    'DD-MM-YYYY'
                                )}
                                insuredName={cur.id}
                            />
                        </Col>
                    )
                } else {
                    return null
                }
            })}
        </Row>
    )
}

export default React.memo(PoliciesList)
