import {UploadsType} from '../../../types/requests'

interface SetUploadsParamCallbackInterface {
    (uploads: UploadsType): UploadsType
}

export default (
    file: File,
    setUploads: {(callback: SetUploadsParamCallbackInterface): void}
) => {
    const reader = new FileReader()

    reader.addEventListener(
        'load',
        () => {
            const indexOfDescription = reader
                .result!.toString()
                .indexOf('base64')
            const result = reader
                .result!.toString()
                .slice(indexOfDescription + 7) // эта хрень чтобы усечь все дескрипторы, которые бэк не хочет хавать

            setUploads((prevState: UploadsType) => [
                ...prevState,
                {
                    fileName: file.name,
                    fileData: result,
                },
            ])
        },
        false
    )

    reader.readAsDataURL(file)
    return false
}
