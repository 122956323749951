import * as React from 'react'
import {FC} from 'react'
import {Row, Col} from 'antd/lib/grid'
import 'antd/lib/grid/style'
import Menu from 'antd/lib/menu'
import 'antd/lib/menu/style'
import message from 'antd/lib/message'
import 'antd/lib/message/style'
import FileProtectOutlined from '@ant-design/icons/FileProtectOutlined'
import MailOutlined from '@ant-design/icons/MailOutlined'
import {Heading} from '../../../../kit/heading'
import Button from '../../../../kit/button'
import {Link, useLocation} from 'react-router-dom'
import {ROUTES} from '../../../../../navigation/routes'

const showChat = () => {
    const jdiv = document.getElementsByTagName('jdiv')[0] as HTMLElement
    if (jdiv === undefined) {
        message.warning(
            'Чат еще не загрузился, попробуйте через несколько секунд.'
        )
        return
    }
    jdiv.style.display = 'block'
    window.jivo_api.open()
}

interface PageHeaderProps {}

const PageHeader: FC<PageHeaderProps> = () => {
    const location = useLocation()

    return (
        <Row gutter={[24, 24]} align={'middle'}>
            <Col sm={19}>
                <Heading>Мой ДМС</Heading>
            </Col>

            <Col sm={5}>
                <Button
                    style={{
                        marginTop: '2rem',
                        marginBottom: '1rem',
                        lineHeight: '1rem',
                    }}
                    onClick={showChat}
                >
                    Заявка на организацию медицинской помощи
                </Button>
            </Col>

            <Col sm={24}>
                <Menu
                    mode='horizontal'
                    defaultSelectedKeys={[location.pathname]}
                >
                    <Menu.Item key={ROUTES.DMS}>
                        <Link to={ROUTES.DMS}>
                            {/* @ts-ignore */}
                            <FileProtectOutlined /> Договоры
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={ROUTES.DMS_GUARANTEE_MAILS}>
                        <Link to={ROUTES.DMS_GUARANTEE_MAILS}>
                            {/* @ts-ignore */}
                            <MailOutlined /> Гарантийные письма
                        </Link>
                    </Menu.Item>
                </Menu>
            </Col>
        </Row>
    )
}

export default PageHeader
