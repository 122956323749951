import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAgreements} from '../../../store/actions/creators-for-requests/agreements'
import {RootStateType} from '../../../store/reducers'
import {Heading} from '../../kit/heading'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../navigation/routes'
import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'
import PoliciesList from './policies-list'
import BonusesPromo from '../../kit/bonuses-promo/'
import './index.less'
import useBonuses from '../../../hooks/use-bonuses'
import {getUserAgreements} from '../../../store/actions/creators-for-requests/user-agreements'

const Policies: React.FC = props => {
    const dispatch = useDispatch(),
        {data: agreements} = useSelector(
            (state: RootStateType) => state.agreements
        ),
        {data: userAgreements} = useSelector(
            (state: RootStateType) => state.userAgreements
        ),
        allAgreements = [...(agreements || []), ...(userAgreements || [])]

    const bonusesInfo = useBonuses()

    React.useEffect(() => {
        dispatch(getAgreements())
        dispatch(getUserAgreements())
    }, [dispatch])

    return (
        <main className={'main policies-main'}>
            <div className={'policies-main__content'}>
                <Row justify={'space-between'}>
                    <Col>
                        <Heading>Активные полисы</Heading>
                    </Col>
                    <Col flex={'0 0 90px'}>
                        <Link
                            to={ROUTES.POLICIES_ARCHIVE}
                            className={'main__heading-link'}
                        >
                            Архив
                        </Link>
                    </Col>
                </Row>
                <PoliciesList
                    data={allAgreements}
                    className={'policies-main__policy-card-list'}
                />
            </div>
            <div className={'policies-main__bonuses-wrapper'}>
                <BonusesPromo bonusesInfo={bonusesInfo} />
            </div>
        </main>
    )
}

export default React.memo(Policies)
