import {combineReducers, Reducer} from 'redux'
import {info} from './info'
import {authentication} from './authentication'
import {agreements} from './agreements'
import {userAgreements} from './userAgreements'
import {dmsContracts} from './dmsContracts'
import {appeals} from './appeals'
import {bonuses} from './bonuses'
import {TYPES} from '../actions/actionTypes'
import kiasLocations from './kias-locations'

const reducers = combineReducers({
    info,
    authentication,
    agreements,
    userAgreements,
    dmsContracts,
    appeals,
    bonuses,
    kiasLocations,
})

export const rootReducer: Reducer = (state, action) => {
    if (action.type === TYPES.CLEAR_STATE) {
        state = undefined
    }

    return reducers(state, action)
}

export type RootStateType = ReturnType<typeof reducers>
