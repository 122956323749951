import {FormikErrors} from 'formik'
import {LoginFormValuesInterface} from '../../../types/forms'
import {phoneRE} from '../../../helpers/reg-expressions'

export default (values: LoginFormValuesInterface) => {
    const errors: FormikErrors<LoginFormValuesInterface> = {}
    if (!phoneRE.test(values.phone)) {
        errors.phone = 'Введите телефон'
    }
    return errors
}
