import * as React from 'react'
import './index.less'

interface ButtonPropsInterface
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    buttonType?: 'primary' | 'secondary' | 'inverted'
    iconClassName?: string
}

const Button = React.memo(
    ({
        buttonType = 'primary',
        iconClassName = '',
        className = '',
        children,
        ...rest
    }: ButtonPropsInterface) => {
        return (
            <button
                {...rest}
                className={`button button_${buttonType} ${
                    iconClassName ? 'button_with-icon' : ''
                } ${className}`}
            >
                {iconClassName ? (
                    <span className={`${iconClassName} button__icon`} />
                ) : null}
                {children}
            </button>
        )
    }
)

export default Button
