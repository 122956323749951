import * as React from 'react'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import MarginWrapper from '../../layout/margin-wrapper'
import {Heading} from '../../kit/heading'
import {Header} from '../../layout/header'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'
import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import ClientAgentFirstPanel from '../../blocks/client-agent-first-panel'
import ClientAgentSecondPanel from '../../blocks/client-agent-second-panel'
import {default as readBecomeAgentStatusFetch} from '../../../requests/read-become-agent-status'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../helpers/for-changing-requests-state/create-request-state'
import BecomeAgentStatusInterface from '../../../types/models/become-agent-status-interface'
import {RequestStateInterface} from '../../../types/state'

const becomeAgentStatusInitialState = createInitialState<
    BecomeAgentStatusInterface
>()

const ClientAgent: FC = () => {
    /*
        Уже тут мы должны
        понять загружались ли данные о клиенте,
        если нет, то загрузить их
        далее нужно запросить статус клиент-агент
     */
    const [becomeAgentStatus, setBecomeAgentStatus] = useState<
            RequestStateInterface<BecomeAgentStatusInterface>
        >(becomeAgentStatusInitialState),
        readBecomeAgentStatus = useCallback(async () => {
            setBecomeAgentStatus(createStartedState())
            try {
                const responseData = await readBecomeAgentStatusFetch()
                if (responseData.success === true)
                    setBecomeAgentStatus(createSuccessState(responseData.data))
                else setBecomeAgentStatus(createErrorState(responseData.errors))
            } catch (e) {
                setBecomeAgentStatus(createErrorState([e.message]))
            }
        }, []),
        headingMemoized = useMemo(() => {
            const {data} = becomeAgentStatus
            let heading = 'Зарабатывай с ЕВРОИНС!'

            if (data !== null) {
                if (
                    data.state === 'Подписан' &&
                    data.contractNumber !== undefined
                )
                    heading = `Приветствуем, Агент ${data.contractNumber}!`
            }

            return heading
        }, [becomeAgentStatus]),
        handleSubmitBecomeAgentForm = useCallback(() => {
            console.log('onSubmitBecomeAgentForm')
            // Обновляем статус
            readBecomeAgentStatus().then()
        }, [readBecomeAgentStatus])

    useEffect(() => {
        readBecomeAgentStatus().then()
    }, [readBecomeAgentStatus])

    console.log(becomeAgentStatus)
    // todo Heading - проверить все статусы что приходят
    return (
        <>
            <Header />
            <main className={'main'}>
                <MarginWrapper>
                    <Heading>{headingMemoized}</Heading>
                    <p className={'text_large'}>
                        Более 1000 наших клиентов уже получили выплаты по
                        программе «Зарабатывай»
                    </p>
                    {becomeAgentStatus.requestStatus === 'didNotLoad' ? (
                        <RingLoaderCustomized />
                    ) : (
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={24} md={20} lg={12} xl={11}>
                                <ClientAgentFirstPanel
                                    becomeAgentStatus={becomeAgentStatus}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={20} lg={12} xl={11}>
                                <ClientAgentSecondPanel
                                    onSubmitBecomeAgentForm={
                                        handleSubmitBecomeAgentForm
                                    }
                                    becomeAgentStatus={becomeAgentStatus}
                                />
                            </Col>
                        </Row>
                    )}
                </MarginWrapper>
            </main>
        </>
    )
}

export default ClientAgent
