import * as React from 'react'
import {FunctionComponent} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootStateType} from '../../../store/reducers'
import LoginForm from '../../forms/login-form/'
import './index.less'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {PUBLIC_RECAPTCHA_KEY} from '../../../constants'
import {ROUTES} from '../../../navigation/routes'
import EnterPagesBg from '../../layout/bg/enter-pages'

interface HistoryStateInterface {
    from: string | undefined
}

export const Auth: FunctionComponent = () => {
    const {authenticated} = useSelector(
            (state: RootStateType) => state.authentication
        ),
        history = useHistory<HistoryStateInterface>()

    React.useEffect(() => {
        if (authenticated) {
            if (history.location.state && history.location.state.from) {
                history.push(history.location.state.from)
            } else {
                history.push(ROUTES.POLICIES)
            }
        }
    }, [history, authenticated])

    return (
        <EnterPagesBg>
            <GoogleReCaptchaProvider reCaptchaKey={PUBLIC_RECAPTCHA_KEY}>
                <div className={'back-to-main-site'}>
                    <a href={'https://www.euro-ins.ru'}>Вернуться на сайт</a>
                </div>
                <h1 className='auth-heading'>Личный кабинет клиента</h1>
                <LoginForm />
            </GoogleReCaptchaProvider>
        </EnterPagesBg>
    )
}
