import * as React from 'react'
import {FC} from 'react'
import Upload from 'antd/lib/upload'
import 'antd/lib/upload/style'
import {RcFile} from 'antd/lib/upload'

interface UploadDocumentPropsInterface {
    label: string
    fileList: RcFile[]
    beforeUpload: (file: RcFile, fileList: RcFile[]) => boolean | Promise<void>
    onRemove: () => void
    error?: string
}

const UploadDocument: FC<UploadDocumentPropsInterface> = ({
    label,
    fileList,
    beforeUpload,
    onRemove,
    error = '',
}) => {
    const errorClass =
        error.length > 0 ? 'become-agent__upload-button_error' : ''

    return (
        <label className={'become-agent__field'}>
            <span className={'become-agent__label'}>{label}</span>
            <Upload
                accept={'application/pdf, image/*'}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
            >
                <button className={`become-agent__upload-button ${errorClass}`}>
                    +
                </button>
            </Upload>
            <span className={'become-agent__upload-error'}>{error}</span>
        </label>
    )
}

export default UploadDocument
