import {URL_CREATE_BECOME_AGENT} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'
import CreateBecomeAgentRequestInterface from '../types/requests/create-become-agent'
import {CreateBecomeAgentResponseType} from '../types/responses/create-become-agent'

const createBecomeAgent = (
    data: CreateBecomeAgentRequestInterface
): Promise<CreateBecomeAgentResponseType> =>
    fetch(URL_CREATE_BECOME_AGENT, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// const createBecomeAgent = (
//     data: CreateBecomeAgentRequestInterface
// ): Promise<CreateBecomeAgentResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: true,
//                 data: {
//                     isn: 59477486,
//                 },
//             })
//         }, 2000)
//     })

/*
    Фейковый ошибочный запрос
 */
// const createBecomeAgent = (
//     data: CreateBecomeAgentRequestInterface
// ): Promise<CreateBecomeAgentResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: false,
//                 errors: ['Ошибка №1', 'Ошибка №2'],
//             })
//         }, 2000)
//     })

export default createBecomeAgent
