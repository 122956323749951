import {UserAgreementsStateInterface} from '../../types/state'
import {LoadUserAgreementsActionsType} from '../../types/actions'
import {TYPES} from '../actions/actionTypes'

export const initialState: UserAgreementsStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
}

export function userAgreements(
    state = initialState,
    action: LoadUserAgreementsActionsType
): UserAgreementsStateInterface {
    switch (action.type) {
        case TYPES.LOAD_USER_AGREEMENTS_STARTED:
            return {
                requestStatus: 'started',
                errorMessage: '',
                data: null,
            }
        case TYPES.LOAD_USER_AGREEMENTS_SUCCESS:
            return {
                requestStatus: 'success',
                errorMessage: '',
                data: action.data,
            }
        case TYPES.LOAD_USER_AGREEMENTS_ERROR:
            return {
                requestStatus: 'error',
                errorMessage: action.errorMessage,
                data: null,
            }
        default:
            return state
    }
}
