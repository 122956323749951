import * as React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {App} from './components/layout/app'
import {Router} from 'react-router-dom'
import store from './store/store'
import './components/common-styles/index.less'
import {createBrowserHistory} from 'history'

const history = createBrowserHistory()

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
            {/* <Maps /> */}
        </Router>
    </Provider>,
    document.getElementById('root')
)
