import {citiesDictionary} from './cities-dictionary'
import {cityByIsn} from './city-by-isn'
import {combineReducers} from 'redux'

export const kiasLocations = combineReducers({
    citiesDictionary,
    cityByIsn,
})

export default kiasLocations
