import * as React from 'react'
import {NavLink} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import './index.less'
import {logout} from '../../../../store/actions/creators-for-requests/authentication'
import {default as MENU} from '../../../../navigation/menu'

// todo - рефактор хидера по принципам БЭМ

const Menu: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    return (
        <nav className={'nav'}>
            {MENU.map(cur => (
                <NavLink
                    key={cur.desktopIconClassName}
                    className={'nav__button'}
                    to={cur.url}
                    activeClassName={cur.desktopIconClassName + '_active'}
                >
                    <div className={cur.desktopIconClassName + ' nav__icon'} />
                    {cur.name}
                </NavLink>
            ))}
            <div onClick={() => dispatch(logout())} className={'nav__button'}>
                <div className={'info-menu-exit nav__icon'} />
                Выход
            </div>
        </nav>
    )
}

export default React.memo(Menu)
