import * as React from 'react'
import './index.less'

interface CheckForEmptyPropsInterface extends React.ComponentProps<any> {
    value: string | number | null
    label: string
}

const PersonalInfoItem = ({value, label}: CheckForEmptyPropsInterface) => {
    return (
        <div className={'personal-info-item'}>
            <div className={'text_bold text_large'}>{label}:</div>
            <div className={'personal-info-item__info'}>
                {value ? value : 'Не заполнено'}
            </div>
        </div>
    )
}

export default React.memo(PersonalInfoItem)
