import * as React from 'react'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import './index.less'

interface CardPropsInterface extends React.ComponentProps<any> {
    title: string
    linkTo: string
    linkText?: string
    className?: string
}

const PersonalInfoCard: FC<CardPropsInterface> = ({
    title,
    linkTo = '',
    linkText = 'Изменить',
    className = '',
    children,
}) => {
    const [isVisible, setIsVisible] = React.useState<boolean>(false)

    return (
        <div
            className={`personal-info-card ${className} ${
                isVisible ? 'personal-info-card_show' : ''
            }`}
        >
            <div className={'personal-info-card__title text_bold'}>{title}</div>
            <div className={'personal-info-card__content'}>
                {children ? children : <div className={'empty-card'} />}
            </div>
            {/* <div className={'personal-info-card__change-button'}>
                <Link className={'button button_primary'} to={linkTo}>
                    {linkText}
                </Link>
            </div> */}
            <div
                onClick={setIsVisible.bind(null, !isVisible)}
                className={'personal-info-card__arrow'}
            />
        </div>
    )
}

export default React.memo(PersonalInfoCard)
