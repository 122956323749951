import {TYPES} from '../actions/actionTypes'
import {InfoStateInterface} from '../../types/state'
import {LoadInfoActionsType} from '../../types/actions'

export const initialState: InfoStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
}

export function info(
    state = initialState,
    action: LoadInfoActionsType
): InfoStateInterface {
    switch (action.type) {
        case TYPES.LOAD_INFO_STARTED:
            return {
                requestStatus: 'started',
                errorMessage: '',
                data: null,
            }
        case TYPES.LOAD_INFO_SUCCESS:
            return {
                requestStatus: 'success',
                errorMessage: '',
                data: action.data,
            }
        case TYPES.LOAD_INFO_ERROR:
            return {
                requestStatus: 'error',
                errorMessage: action.errorMessage,
                data: null,
            }
        default:
            return state
    }
}
