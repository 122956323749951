import * as React from 'react'
import {FC, TextareaHTMLAttributes} from 'react'
import './index.less'

interface TextAreaPropsInterface
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    value: string
}

const TextArea: FC<TextAreaPropsInterface> = ({className = '', ...props}) => {
    return <textarea className={'textarea ' + className} {...props} />
}

export default TextArea
