import {TYPES} from '../../actions/actionTypes'
import {LoadCitiesDictionaryActionsType} from '../../../types/actions'
import {CitiesDictionaryStateInterface} from '../../../types/state'

export const initialState: CitiesDictionaryStateInterface = {
    requestStatus: 'didNotLoad',
    data: [],
}

export function citiesDictionary(
    state = initialState,
    action: LoadCitiesDictionaryActionsType
): CitiesDictionaryStateInterface {
    switch (action.type) {
        case TYPES.LOAD_CITIES_DICTIONARY_STARTED:
            return {
                ...state,
                requestStatus: 'started',
            }

        case TYPES.LOAD_CITIES_DICTIONARY_SUCCESS:
            return {
                requestStatus: 'success',
                data: action.data,
            }
        default:
            return state
    }
}
