import * as React from 'react'
import {FunctionComponent} from 'react'
import MarginWrapper from '../../../layout/margin-wrapper/'
import {Heading} from '../../../kit/heading'
import '../../../common-styles/index.less'

const InsuranceCaseAccident: FunctionComponent = props => {
    return (
        <main className={'main'}>
            <MarginWrapper>
                <div className={'heading-wrapper'}>
                    <Heading>Несчастный случай</Heading>
                </div>
                <ul>
                    <li>
                        вне зависимости от того, где и с кем находится ребенок,
                        нужно обратиться за медицинской помощью и соблюдать
                        рекомендации врача с целью уменьшения последствий
                        страхового случая;
                    </li>
                    <li>
                        сообщить страховщику о наступлении страхового случая не
                        позже 30 дней с даты наступления события. Желательно
                        передать информацию как можно раньше;
                    </li>
                    <li>
                        подать в компанию заявление по установленной форме.
                        Приложить подтверждающие документы (эпикриз,
                        свидетельство о смерти, выписку, заключение врача,
                        справку об инвалидности и проч.). Полный список
                        документов уточните у менеджера компании;
                    </li>
                    <li>
                        выплату при страховании ребенка получает страхователь, а
                        в случае смерти застрахованного лица –
                        выгодоприобретатель, то есть лицо, назначенное
                        страхователем (обычно выгодоприобретателем назначается
                        любой родственник — родители, бабушки, дедушки или
                        другие родственники);
                    </li>
                    <li>
                        при получении страховой выплаты нужно представить
                        документ, удостоверяющий личность. Желательно иметь при
                        себе оригинал договора;
                    </li>
                    <li>
                        компания рассматривает заявления клиентов в минимальный
                        срок. Клиент получает выплату в течение 15 дней после
                        получения страховщиком всех необходимых документов и
                        составления страхового акта.
                    </li>
                </ul>
            </MarginWrapper>
        </main>
    )
}

export default React.memo(InsuranceCaseAccident)
