import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import ClientAgentPanel from '../../kit/client-agent-panel'
import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'
import Field from '../../kit/forms-common/field'
import Button from '../../kit/button'
import {RequestStateInterface} from '../../../types/state'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../helpers/for-changing-requests-state/create-request-state'
import CashBackFormInterface from '../../../types/forms/cash-back'
import {FormikErrors, useFormik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import {getInfo} from '../../../store/actions/creators-for-requests/info'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import {ClientAgentSaleInterface} from '../../../types/models/client-agent-sale'
import './index.less'
import createCashBackClaim from '../../../requests/create-cashback-claim'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'

interface PaymentClaimFormPropsInterface {
    sale: ClientAgentSaleInterface | null
    onBack: () => void
}

const validate = (values: CashBackFormInterface) => {
        console.log('validate ', values)
        const errors: FormikErrors<CashBackFormInterface> = {}
        if (values.bankName.length === 0)
            errors.bankName = 'Введите наименование банка'
        if (values.bik.length === 0) errors.bik = 'Введите БИК'
        if (values.number.length === 0) errors.number = 'Введите номер счета'
        return errors
    },
    initialState = createInitialState<null>()

const PaymentClaimForm: FC<PaymentClaimFormPropsInterface> = ({
    sale,
    onBack,
}) => {
    const [cashBack, setCashBack] = useState<RequestStateInterface<null>>(
            initialState
        ),
        dispatch = useDispatch(),
        {data} = useSelector((state: RootStateType) => state.info),
        handleBack = useCallback(
            e => {
                e.preventDefault()
                onBack()
            },
            [onBack]
        ),
        handleSubmit = useCallback(
            async (values: CashBackFormInterface) => {
                console.log('handleSubmit', values)
                try {
                    setCashBack(createStartedState())
                    const result = await createCashBackClaim({
                        ...values,
                        cashBackIsn: (sale as ClientAgentSaleInterface).ecIsn,
                    })
                    if (result.success) setCashBack(createSuccessState(null))
                    else setCashBack(createErrorState(result.errors))
                } catch (e) {
                    setCashBack(createErrorState([e.message]))
                }
            },
            [sale]
        ),
        formik = useFormik<CashBackFormInterface>({
            initialValues: {
                bankName: '',
                bik: '',
                number: '',
            },
            validateOnBlur: false,
            validateOnChange: false,
            validate,
            onSubmit: handleSubmit,
        })

    useEffect(() => {
        dispatch(getInfo())
    }, [dispatch])

    if (sale === null) return null

    return (
        <ClientAgentPanel>
            <p className={'text_bold text_center'}>
                Заявка на получение выплаты <br />
                за примененный промокод
            </p>
            {data === null ? (
                <RingLoaderCustomized verticalMargin={'60px'} />
            ) : (
                <p className={'text_center'}>
                    Получатель:{' '}
                    {`${data.lastName} ${data.firstName} ${data.middleName}`}{' '}
                    <br />
                    Паспорт:{' '}
                    {`${data.passport.serial} ${data.passport.number} от ${data.passport.issuedDate}`}{' '}
                    <br />
                    Тип Выплаты: КВ XXX-0123456789 <br />
                    Размер выплаты: {sale.amount} руб.
                </p>
            )}

            <form onSubmit={formik.handleSubmit}>
                {cashBack.requestStatus === 'started' ? (
                    <RingLoaderCustomized verticalMargin={'60px'} />
                ) : cashBack.requestStatus === 'success' ? (
                    <p className={'text_center'}>Заявка успешно отправлена!</p>
                ) : (
                    <Row gutter={[24, 8]} justify={'center'}>
                        <Col sm={12}>
                            <Field
                                label={'Наименование банка'}
                                fieldName={'bankName'}
                                value={formik.values.bankName}
                                errors={formik.errors.bankName}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                label={'БИК'}
                                fieldName={'bik'}
                                value={formik.values.bik}
                                errors={formik.errors.bik}
                                onChange={formik.handleChange}
                            />
                        </Col>
                        <Col sm={12}>
                            <Field
                                label={'Номер расчетного счета'}
                                fieldName={'number'}
                                value={formik.values.number}
                                errors={formik.errors.number}
                                onChange={formik.handleChange}
                            />
                        </Col>
                    </Row>
                )}

                {cashBack.requestStatus === 'error' ? (
                    <ErrorParagraph errors={cashBack.errorMessage} />
                ) : null}

                <Row
                    gutter={16}
                    justify={'center'}
                    className={'pcf__control-block'}
                >
                    <Col flex={'0 0 auto'}>
                        <Button buttonType={'secondary'} onClick={handleBack}>
                            Назад
                        </Button>
                    </Col>
                    {cashBack.requestStatus === 'success' ? null : (
                        <Col flex={'0 0 auto'}>
                            <Button>Отправить заявку</Button>
                        </Col>
                    )}
                </Row>
            </form>
        </ClientAgentPanel>
    )
}

export default PaymentClaimForm
