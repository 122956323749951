import {
    ActivateWelcomeBonusesErrorInterface,
    ActivateWelcomeBonusesRevertInitialInterface,
    ActivateWelcomeBonusesStartedInterface,
    ActivateWelcomeBonusesSuccessInterface,
    ClearStateInterface,
    LoadAgreementsErrorInterface,
    LoadAgreementsStartedInterface,
    LoadAgreementsSuccessInterface,
    LoadDmsContractsErrorInterface,
    LoadDmsContractsStartedInterface,
    LoadDmsContractsSuccessInterface,
    LoadAppealsErrorInterface,
    LoadAppealsStartedInterface,
    LoadAppealsSuccessInterface,
    LoadBonusesErrorInterface,
    LoadBonusesHistoryErrorInterface,
    LoadBonusesHistoryStartedInterface,
    LoadBonusesHistorySuccessInterface,
    LoadBonusesIsActivatedErrorInterface,
    LoadBonusesIsActivatedStartedInterface,
    LoadBonusesIsActivatedSuccessInterface,
    LoadBonusesStartedInterface,
    LoadBonusesSuccessInterface,
    LoadCitiesDictionaryStartedInterface,
    LoadCitiesDictionarySuccessInterface,
    LoadCityByIsnErrorInterface,
    LoadCityByIsnStartedInterface,
    LoadCityByIsnSuccessInterface,
    LoadInfoErrorInterface,
    LoadInfoStartedInterface,
    LoadInfoSuccessInterface,
    LoginErrorInterface,
    LoginStartedInterface,
    LoginSuccessInterface,
    LogoutErrorInterface,
    LogoutStartedInterface,
    LogoutSuccessInterface,
    SetBonusesDisplayStatusInterface,
    LoadUserAgreementsSuccessInterface,
    LoadUserAgreementsErrorInterface,
    LoadUserAgreementsStartedInterface,
} from '../../types/actions'
import {TYPES} from './actionTypes'
import {
    AgreementInterface,
    AppealInterface,
    BonusesHistoryInterface,
    BonusesInterface,
    BonusesIsActivatedInterface,
    CitiesDictionaryInterface,
    CityByIsnInterface,
    InfoInterface,
    UserAgreementInterface,
} from '../../types'
import {BonusesDisplayStatus} from '../reducers/bonuses/count-and-card'
import DmsContractMainPartInterface from '../../types/models/dms-contract-main-part'

/*
    Аутентификация
 */

export function loginStarted(): LoginStartedInterface {
    return {
        type: TYPES.LOGIN_STARTED,
    }
}

export function loginSuccess(): LoginSuccessInterface {
    return {
        type: TYPES.LOGIN_SUCCESS,
    }
}

export function loginError(errorMessage: string): LoginErrorInterface {
    return {
        type: TYPES.LOGIN_ERROR,
        errorMessage,
    }
}

/*

*/

export function logoutStarted(): LogoutStartedInterface {
    return {
        type: TYPES.LOGOUT_STARTED,
    }
}

export function logoutSuccess(): LogoutSuccessInterface {
    return {
        type: TYPES.LOGOUT_SUCCESS,
    }
}

export function logoutError(errorMessage: string): LogoutErrorInterface {
    return {
        type: TYPES.LOGOUT_ERROR,
        errorMessage,
    }
}

/*
    Получение информации
 */

export function loadInfoStarted(): LoadInfoStartedInterface {
    return {
        type: TYPES.LOAD_INFO_STARTED,
    }
}

export function loadInfoSuccess(data: InfoInterface): LoadInfoSuccessInterface {
    return {
        type: TYPES.LOAD_INFO_SUCCESS,
        data,
    }
}

export function loadInfoError(errorMessage: string): LoadInfoErrorInterface {
    return {
        type: TYPES.LOAD_INFO_ERROR,
        errorMessage,
    }
}

/*
    активация бонусов
*/

export function welcomeBonusesActivationStarted(): ActivateWelcomeBonusesStartedInterface {
    return {
        type: TYPES.ACTIVATE_WELCOME_BONUSES_STARTED,
    }
}

export function welcomeBonusesActivationSuccess(): ActivateWelcomeBonusesSuccessInterface {
    return {
        type: TYPES.ACTIVATE_WELCOME_BONUSES_SUCCESS,
    }
}

export function welcomeBonusesActivationError(
    errorMessage: string
): ActivateWelcomeBonusesErrorInterface {
    return {
        type: TYPES.ACTIVATE_WELCOME_BONUSES_ERROR,
        errorMessage,
    }
}

export function welcomeBonusesActivationRevertInitialState(): ActivateWelcomeBonusesRevertInitialInterface {
    return {
        type: TYPES.ACTIVATE_WELCOME_BONUSES_REVERT_INITIAL,
    }
}

/*
    Получение соглашений
 */

export function loadAgreementsStarted(): LoadAgreementsStartedInterface {
    return {
        type: TYPES.LOAD_AGREEMENTS_STARTED,
    }
}

export function loadAgreementsSuccess(
    data: AgreementInterface[]
): LoadAgreementsSuccessInterface {
    return {
        type: TYPES.LOAD_AGREEMENTS_SUCCESS,
        data,
    }
}

export function loadAgreementsError(
    errorMessage: string
): LoadAgreementsErrorInterface {
    return {
        type: TYPES.LOAD_AGREEMENTS_ERROR,
        errorMessage,
    }
}

/*
    Получение соглашений пользователя из калька ОСАГО
 */

export function loadUserAgreementsStarted(): LoadUserAgreementsStartedInterface {
    return {
        type: TYPES.LOAD_USER_AGREEMENTS_STARTED,
    }
}

export function loadUserAgreementsSuccess(
    data: UserAgreementInterface[]
): LoadUserAgreementsSuccessInterface {
    return {
        type: TYPES.LOAD_USER_AGREEMENTS_SUCCESS,
        data,
    }
}

export function loadUserAgreementsError(
    errorMessage: string
): LoadUserAgreementsErrorInterface {
    return {
        type: TYPES.LOAD_USER_AGREEMENTS_ERROR,
        errorMessage,
    }
}

/*
    Получение ДМС контрактов
 */

export function loadDmsContractsStarted(): LoadDmsContractsStartedInterface {
    return {
        type: TYPES.LOAD_DMSCONTRACTS_STARTED,
    }
}

export function loadDmsContractsSuccess(
    data: DmsContractMainPartInterface[]
): LoadDmsContractsSuccessInterface {
    return {
        type: TYPES.LOAD_DMSCONTRACTS_SUCCESS,
        data,
    }
}

export function loadDmsContractsError(
    errorMessage: string
): LoadDmsContractsErrorInterface {
    return {
        type: TYPES.LOAD_DMSCONTRACTS_ERROR,
        errorMessage,
    }
}

/*
    Получение соглашений
 */

export function loadAppealsStarted(): LoadAppealsStartedInterface {
    return {
        type: TYPES.LOAD_APPEALS_STARTED,
    }
}

export function loadAppealsSuccess(
    data: AppealInterface[]
): LoadAppealsSuccessInterface {
    return {
        type: TYPES.LOAD_APPEALS_SUCCESS,
        data,
    }
}

export function loadAppealsError(
    errorMessage: string
): LoadAppealsErrorInterface {
    return {
        type: TYPES.LOAD_APPEALS_ERROR,
        errorMessage,
    }
}

/*
    Получение бонусов
 */

export function loadBonusesStarted(): LoadBonusesStartedInterface {
    return {
        type: TYPES.LOAD_BONUSES_STARTED,
    }
}

export function loadBonusesSuccess(
    data: BonusesInterface
): LoadBonusesSuccessInterface {
    return {
        type: TYPES.LOAD_BONUSES_SUCCESS,
        data,
    }
}

export function loadBonusesError(
    errorMessage: string
): LoadBonusesErrorInterface {
    return {
        type: TYPES.LOAD_BONUSES_ERROR,
        errorMessage,
    }
}

export function setBonusesStatus(
    status: BonusesDisplayStatus
): SetBonusesDisplayStatusInterface {
    return {
        type: TYPES.SET_BONUSES_STATUS,
        payload: status,
    }
}

// активированы ли бонусы

export function loadBonusesIsActivatedStarted(): LoadBonusesIsActivatedStartedInterface {
    return {
        type: TYPES.LOAD_BONUSES_IS_ACTIVATED_STARTED,
    }
}

export function loadBonusesIsActivatedSuccess(
    data: BonusesIsActivatedInterface
): LoadBonusesIsActivatedSuccessInterface {
    return {
        type: TYPES.LOAD_BONUSES_IS_ACTIVATED_SUCCESS,
        data,
    }
}

export function loadBonusesIsActivatedError(
    errorMessage: string
): LoadBonusesIsActivatedErrorInterface {
    return {
        type: TYPES.LOAD_BONUSES_IS_ACTIVATED_ERROR,
        errorMessage,
    }
}

// история бонусов
export function loadBonusesHistoryStarted(): LoadBonusesHistoryStartedInterface {
    return {
        type: TYPES.LOAD_BONUSES_HISTORY_STARTED,
    }
}

export function loadBonusesHistorySuccess(
    data: BonusesHistoryInterface[]
): LoadBonusesHistorySuccessInterface {
    return {
        type: TYPES.LOAD_BONUSES_HISTORY_SUCCESS,
        payload: data,
    }
}

export function loadBonusesHistoryError(
    error: string
): LoadBonusesHistoryErrorInterface {
    return {
        type: TYPES.LOAD_BONUSES_HISTORY_ERROR,
        payload: error,
    }
}

// cities dictionary
export function loadCitiesDictionaryStarted(): LoadCitiesDictionaryStartedInterface {
    return {
        type: TYPES.LOAD_CITIES_DICTIONARY_STARTED,
    }
}

export function loadCitiesDictionarySuccess(
    data: CitiesDictionaryInterface[]
): LoadCitiesDictionarySuccessInterface {
    return {
        type: TYPES.LOAD_CITIES_DICTIONARY_SUCCESS,
        data,
    }
}

// get city name by isn

export function loadCityByIsnStarted(): LoadCityByIsnStartedInterface {
    return {
        type: TYPES.LOAD_CITY_BY_ISN_STARTED,
    }
}

export function loadCityByIsnSuccess(
    data: CityByIsnInterface
): LoadCityByIsnSuccessInterface {
    return {
        type: TYPES.LOAD_CITY_BY_ISN_SUCCESS,
        payload: data,
    }
}

export function loadCityByIsnError(error: string): LoadCityByIsnErrorInterface {
    return {
        type: TYPES.LOAD_CITY_BY_ISN_ERROR,
        payload: error,
    }
}

// отчистка стэйта

export function clearState(): ClearStateInterface {
    return {
        type: TYPES.CLEAR_STATE,
    }
}
