import {URL_CHECK_DMS_CONTRACT} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'
import CheckDmsContractRequestInterface from '../../types/requests/check-dms-contract'
import {CheckDmsContractResponseType} from '../../types/responses/check-dms-contract'

const checkDmsContract = (
    body: CheckDmsContractRequestInterface
): Promise<CheckDmsContractResponseType> =>
    fetch(`${URL_CHECK_DMS_CONTRACT}`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(body),
    }).then(handleResponse)

export default checkDmsContract
