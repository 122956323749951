import {FunctionComponent, default as React, useEffect} from 'react'
import {Heading} from '../../kit/heading'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import {getAppeals} from '../../../store/actions/creators-for-requests/appeals'
import MarginWrapper from '../../layout/margin-wrapper/'
import Paragraph from '../../kit/paragraph/'
import moment from 'moment'
import './index.less'
import {Link} from 'react-router-dom'
import '../../common-styles/index.less'
import {ROUTES} from '../../../navigation/routes'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import 'antd/lib/row/style'
import 'antd/lib/col/style'
import RingLoaderCustomized from '../../kit/ring-loader-customized'

const sizes = {
    xs: 24,
    sm: 24,
    xl: 12,
}

const Appeals: FunctionComponent = () => {
    const dispatch = useDispatch(),
        {data} = useSelector((state: RootStateType) => state.appeals)

    useEffect(() => {
        dispatch(getAppeals())
    }, [dispatch])

    return (
        <main className={'main'}>
            <MarginWrapper>
                <Row justify={'space-between'}>
                    <Col>
                        <Heading>Обращения</Heading>
                    </Col>
                    <Col flex={'0 0 230px'}>
                        <Link
                            to={ROUTES.CREATE_APPEAL}
                            className={'main__heading-link'}
                        >
                            Создать обращение
                        </Link>
                    </Col>
                </Row>
                <Paragraph>
                    В данной таблице отображены Ваши страховые случая и
                    обращения в служб у поддержки компании.
                </Paragraph>
                {data ? (
                    data.length > 0 ? (
                        <Row>
                            <Col {...sizes}>
                                <div className={'appeals-table'}>
                                    <div className={'appeals-table__row_head'}>
                                        <div
                                            className={
                                                'appeals-table__cell appeals-table__cell_heading-cell'
                                            }
                                        >
                                            Номер обращения
                                        </div>
                                        <div
                                            className={
                                                'appeals-table__cell appeals-table__cell_heading-cell'
                                            }
                                        >
                                            Дата создания
                                        </div>
                                        <div
                                            className={
                                                'appeals-table__cell appeals-table__cell_heading-cell'
                                            }
                                        >
                                            Статус
                                        </div>
                                    </div>
                                    {data.map((cur, id) => {
                                        return (
                                            <Link
                                                to={`${ROUTES.APPEALS}/${id}`}
                                                className={'appeals-table__row'}
                                                key={cur.id}
                                            >
                                                <div
                                                    className={
                                                        'appeals-table__cell'
                                                    }
                                                >
                                                    №{cur.id}
                                                </div>
                                                <div
                                                    className={
                                                        'appeals-table__cell'
                                                    }
                                                >
                                                    {moment(
                                                        cur.dateCreated,
                                                        'DD-MM-YYYY'
                                                    ).format('DD.MM.YYYY')}
                                                </div>
                                                <div
                                                    style={
                                                        cur.status === 'Закрыто'
                                                            ? {
                                                                  color:
                                                                      'rgba(0,200,60,1)',
                                                              }
                                                            : {}
                                                    }
                                                    className={
                                                        'appeals-table__cell'
                                                    }
                                                >
                                                    {cur.status ===
                                                    'Рассматривается'
                                                        ? 'Открыто'
                                                        : cur.status}
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <Paragraph>Обращений еще не было.</Paragraph>
                    )
                ) : (
                    <RingLoaderCustomized />
                )}
            </MarginWrapper>
        </main>
    )
}

export default React.memo(Appeals)
