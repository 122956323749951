import * as React from 'react'
import {BonusesHistoryInterface, BonusesInterface} from '../../../../../types'
import {formatBonusesAmount} from '../../../../../helpers/for-formatting/formatBonuses'

interface BonusesActivatedPropsInterface {
    bonuses: BonusesInterface | null
    showHistory: boolean
    bonusesHistory: BonusesHistoryInterface[] | null
}

export default ({
    bonuses,
    bonusesHistory,
    showHistory,
}: BonusesActivatedPropsInterface) => {
    const lastHistoryOperations = React.useMemo(() => {
        if (bonusesHistory === null) return
        let arrayIdx = bonusesHistory.length - 1
        const result: BonusesHistoryInterface[] = []
        for (let i = 0; i < 3; i++) {
            if (arrayIdx >= 0) {
                // @ts-ignore
                result.push(bonusesHistory[arrayIdx])
                arrayIdx--
            }
        }
        return result.reverse()
    }, [bonusesHistory])

    return (
        <>
            <div>
                <div
                    className={
                        'bonuses-promo__text_huge bonuses-promo__text_blue'
                    }
                >
                    {bonuses!.count}
                </div>
                <div
                    className={
                        'bonuses-promo__text_big bonuses-promo__text_blue'
                    }
                >
                    баллов доступно
                </div>
            </div>
            <div className={'changing-area__card'}>
                <div className={'bonuses-promo__text_middle'}>
                    Номер вашей карты:
                </div>
                <div className={'bonuses-promo__text_big'}>
                    {bonuses?.cardNumber}
                </div>
            </div>
            {showHistory &&
                lastHistoryOperations &&
                lastHistoryOperations.length > 0 && (
                    <div>
                        <div className={'bonuses-promo__text_middle'}>
                            Последние операции по счету:
                        </div>
                        <div
                            className={
                                'changing-area__operations-history operations-history'
                            }
                        >
                            {lastHistoryOperations.map((val, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className={
                                            'operations-history__operation'
                                        }
                                    >
                                        <span>{val.name}</span>
                                        <span className={'text_bold'}>
                                            {formatBonusesAmount(val.amount)}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
        </>
    )
}
