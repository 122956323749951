import * as React from 'react'
import './index.less'
import Col from 'antd/es/col'
import 'antd/es/col/style'
import Row from 'antd/es/grid/row'
import ELink from '../../../../kit/e-link'

export interface CalcCardPropsInterface {
    bgClassName: string
    title: string
    calcURL: string
}

const CalcCard = React.memo(
    ({bgClassName, title, calcURL}: CalcCardPropsInterface) => {
        return (
            <ELink to={calcURL}>
                <Row className={`card-calc`}>
                    <Col span={14} className={`card-calc__description`}>
                        {title}
                        <div
                            className={'card-calc__icon shop-calculator-icon'}
                        />
                    </Col>
                    <Col
                        span={10}
                        className={`card-calc__image card-calc__image_${bgClassName}`}
                    />
                </Row>
            </ELink>
        )
    }
)

export default CalcCard
