import {useCallback} from 'react'
import {useHistory, useParams} from 'react-router'
import {useDispatch} from 'react-redux'
import {getInfo} from '../../../../store/actions/creators-for-requests/info'
import {ROUTES} from '../../../../navigation/routes'
import {POPUP_FORM_ID} from '../components/personal-info-list'

interface PersonalDataParamsInterface {
    popupFormId: POPUP_FORM_ID
}

const usePersonalInfoCardHooks = () => {
    const history = useHistory(),
        dispatch = useDispatch(),
        {popupFormId} = useParams<PersonalDataParamsInterface>(),
        goToPersonalData = useCallback(() => {
            dispatch(getInfo())
            history.push(ROUTES.PERSONAL_DATA)
        }, [dispatch, history]),
        closePopup = useCallback(() => {
            history.push(ROUTES.PERSONAL_DATA)
        }, [history])

    return {
        goToPersonalData,
        closePopup,
        popupFormId,
    }
}

export default usePersonalInfoCardHooks
