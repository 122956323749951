import * as React from 'react'
import {FunctionComponent} from 'react'
import './index.less'

interface HeadingPropsInterface
    extends React.HTMLAttributes<HTMLHeadingElement> {
    level?: 1 | 2 | 3 | 4
}

export const Heading: FunctionComponent<HeadingPropsInterface> = ({
    level = 1,
    children,
    className: propsClassName = '',
    ...props
}) => {
    const className = `heading heading_${level} ` + propsClassName

    switch (level) {
        case 1:
            return <h1 className={className}>{children}</h1>
        case 2:
            return (
                <h2 className={className} {...props}>
                    {children}
                </h2>
            )
        case 3:
            return (
                <h3 className={className} {...props}>
                    {children}
                </h3>
            )
        case 4:
            return (
                <h4 className={className} {...props}>
                    {children}
                </h4>
            )
    }
}
