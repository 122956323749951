import {FormikErrors} from 'formik'
import {RegisterFormValuesInterface} from '../../../types/forms'
import moment from 'moment'

export default (values: RegisterFormValuesInterface) => {
    const errors: FormikErrors<RegisterFormValuesInterface> = {}

    Object.keys(values).forEach(key => {
        if (!values[key] || ~values[key].indexOf('_')) {
            if (key === 'middleName') {
                return // необязательные поля
            }
            errors[key] = 'Заполните поле'
            return
        }
    })

    if (!/\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}/.test(values.phone)) {
        errors.phone = 'Введите телефон'
    }

    if (values.birthday) {
        const birthday = moment(values.birthday, 'DD.MM.YYYY')
        if (birthday.isValid()) {
            if (moment().diff(birthday, 'years') < 18) {
                errors.birthday = 'Должен быть совершеннолетним'
            }
        } else {
            errors.birthday = 'Укажите корректную дату'
        }
    } else {
        errors.birthday = 'Укажите дату'
    }

    return errors
}
