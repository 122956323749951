import {FC, default as React, useMemo} from 'react'
import PersonalInfoCard from '../personal-info-card'
import PersonalInfoItem from '../personal-info-item'
import formatDate from '../../../../../helpers/for-formatting/format-date'
import {POPUP_FORM_ID} from '../personal-info-list'
import PopupWrapper from '../popup-wrapper'
import PassportForm from '../../../../forms/passport-form'
import {ROUTES} from '../../../../../navigation/routes'
import {PassportFormInterface} from '../../../../../types/forms'
import usePersonalInfoCardHooks from '../../hooks/use-personal-info-card-hooks'

interface PassportCardPropsInterface {
    data: PassportFormInterface
}

const CARD_TITLE = 'Паспорт РФ'

const PassportCard: FC<PassportCardPropsInterface> = ({data}) => {
    const linkToPopup = useMemo(
            () => `${ROUTES.PERSONAL_DATA}/${POPUP_FORM_ID.PASSPORT}`,
            []
        ),
        {goToPersonalData, closePopup, popupFormId} = usePersonalInfoCardHooks()

    const openPopup = popupFormId === POPUP_FORM_ID.PASSPORT

    const values = React.useMemo(() => {
        return {
            serial: data.serial,
            number: data.number,
            issuedDate: formatDate(data.issuedDate),
            issuer: data.issuer,
            issuerCode: data.issuerCode,
        }
    }, [data])

    return (
        <>
            <PersonalInfoCard title={CARD_TITLE} linkTo={linkToPopup}>
                <PersonalInfoItem label={'Серия'} value={values.serial} />
                <PersonalInfoItem label={'Номер'} value={values.number} />
                <PersonalInfoItem
                    label={'Дата выдачи'}
                    value={values.issuedDate}
                />
                <PersonalInfoItem label={'Кем выдан'} value={values.issuer} />
                <PersonalInfoItem
                    label={'Код подразделения'}
                    value={values.issuerCode}
                />
            </PersonalInfoCard>

            {/* <PopupWrapper open={openPopup} onClose={closePopup}>
                <PassportForm
                    title={CARD_TITLE}
                    data={data}
                    onSuccessfulDataChange={goToPersonalData}
                />
            </PopupWrapper> */}
        </>
    )
}

export default React.memo(PassportCard)
