import {URL_FILE} from '../../constants/urls'
import {handleResponseWithBlob} from '../../helpers/for-requests'
import {GetPdfResponseType} from '../../types/responses/get-pdf'
import GetPdfRequestInterface from '../../types/requests/get-pdf'

const getPdfDocument = (
    data: GetPdfRequestInterface,
    type: string = '?type=A'
): Promise<GetPdfResponseType> =>
    fetch(`${URL_FILE}/${data.isn}/${data.refIsn}${type}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(handleResponseWithBlob)

export default getPdfDocument
