import * as React from 'react'
import Col from 'antd/lib/col'
import {Heading} from '../../kit/heading'
import Row from 'antd/lib/row'
import './index.less'
import CalcsCardList from './calcs-card-list'

const Shop = React.memo(() => {
    return (
        <main className={'main main-shop'}>
            <section className={'main-shop__content'}>
                <Row justify={'space-between'}>
                    <Col span={24}>
                        <Heading>Онлайн расчет и оформление полиса</Heading>
                    </Col>
                    <Col span={24}>
                        <CalcsCardList />
                    </Col>
                </Row>
            </section>

            <section className={'main-shop__sales_wrapper'}></section>
        </main>
    )
})

export default Shop
