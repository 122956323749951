import {ROUTES} from '../navigation/routes'

export type IFRAME = {calcUrl: string; name: string; route: ROUTES}

export const CALC_IFRAMES = [
    {
        name: 'dms',
        calcUrl: 'https://euro-ins.ru/front/html/health/standart_prod.html',
        route: ROUTES.SHOP_DMS_STANDARD,
    },
    {
        name: 'osago',
        calcUrl: 'https://euro-ins.ru/front/html/osago/osago.prod.html',
        route: ROUTES.SHOP_OSAGO_STANDARD,
    },
    {
        name: 'property',
        calcUrl: 'https://euro-ins.ru/front/html/property/main_prod.html',
        route: ROUTES.SHOP_PROPERTY_STANDARD,
    },
    {
        name: 'kasko',
        calcUrl: 'https://euro-ins.ru/front/html/kasko/kasko.express.prod.html',
        route: ROUTES.SHOP_KASKO_STANDARD,
    },
    {
        name: 'ns-sport',
        calcUrl: 'https://euro-ins.ru/front/html/accident/sport_prod.html',
        route: ROUTES.SHOP_NS_SPORT,
    },
    {
        name: 'ns-covid',
        calcUrl: 'https://euro-ins.ru/front/html/accident/covid_prod.html',
        route: ROUTES.SHOP_NS_COVID,
    },
    {
        name: 'ns-antimite',
        calcUrl: 'https://euro-ins.ru/front/html/accident/antimite_prod.html',
        route: ROUTES.SHOP_NS_ANTIMITE,
    },
] as const
