import {useCallback, useState} from 'react'
import {FileInfoStateType} from './index'

interface FieldsStateAndCallbacksInterface {
    email: string
    passportMain: FileInfoStateType
    passportAddress: FileInfoStateType
    snils: FileInfoStateType
    handleSubmit: (e: any) => void
    handleChangeEmail: (e: any) => void
    handleSelectPassportMain: (file: FileInfoStateType) => boolean
    handleSelectPassportAddress: (file: FileInfoStateType) => boolean
    handleSelectSnils: (file: FileInfoStateType) => boolean
    handleRemovePassportMain: () => void
    handleRemovePassportAddress: () => void
    handleRemoveSnils: () => void
}

const useFieldsStateAndCallbacks = (): FieldsStateAndCallbacksInterface => {
    const [email, setEmail] = useState<string>(''),
        [passportMain, setPassportMain] = useState<FileInfoStateType>(null),
        [passportAddress, setPassportAddress] = useState<FileInfoStateType>(
            null
        ),
        [snils, setSnils] = useState<FileInfoStateType>(null),
        handleSubmit = useCallback((e: any) => {
            e.preventDefault()
        }, []),
        handleChangeEmail = useCallback((e: any) => {
            setEmail(e.target.value)
        }, []),
        handleSelectPassportMain = useCallback((file: any) => {
            setPassportMain(file)
            return false
        }, []),
        handleRemovePassportMain = useCallback(() => {
            setPassportMain(null)
        }, []),
        handleSelectPassportAddress = useCallback(fileInfo => {
            setPassportAddress(fileInfo)
            return false
        }, []),
        handleRemovePassportAddress = useCallback(
            () => setPassportAddress(null),
            []
        ),
        handleSelectSnils = useCallback(fileInfo => {
            setSnils(fileInfo)
            return false
        }, []),
        handleRemoveSnils = useCallback(() => setSnils(null), [])

    return {
        email,
        passportMain,
        passportAddress,
        snils,
        handleSubmit,
        handleChangeEmail,
        handleSelectPassportMain,
        handleSelectPassportAddress,
        handleSelectSnils,
        handleRemovePassportMain,
        handleRemovePassportAddress,
        handleRemoveSnils,
    }
}

export default useFieldsStateAndCallbacks
