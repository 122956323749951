import {AuthenticationRequestDataInterface} from '../../../types/requests'
import {
    clearState,
    loginError,
    loginStarted,
    loginSuccess,
    logoutStarted,
    logoutSuccess,
} from '../actionCreators'
import {URL_COOKIE_STATUS, URL_LOGIN, URL_LOGOUT} from '../../../constants/urls'
import {AuthenticationResponseType} from '../../../types/responses'
import {handleResponse} from '../../../helpers/for-requests'

export function authenticate(data: AuthenticationRequestDataInterface) {
    return async function(dispatch: any) {
        dispatch(loginStarted())
        return fetch(URL_LOGIN, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then(handleResponse)
            .then((data: AuthenticationResponseType) => {
                if (data.success) {
                    dispatch(loginSuccess())
                } else if (data.errors && data.errors.join) {
                    dispatch(loginError(data.errors.join(',\n')))
                } else {
                    dispatch(loginError(JSON.stringify(data)))
                }
            })
            .catch((error: Error) => dispatch(loginError(error.message)))
    }
}

export function logout() {
    return async function(dispatch: any) {
        dispatch(logoutStarted())
        return fetch(URL_LOGOUT, {
            method: 'GET',
            credentials: 'include',
        })
            .then(res => {
                if (res.status === 200) {
                    dispatch(logoutSuccess())
                    dispatch(clearState())
                } else dispatch(loginError('ошибка логаута'))
            })
            .catch((error: Error) => dispatch(loginError(error.message)))
    }
}

export function checkAuthentication() {
    return async function(dispatch: any) {
        return fetch(URL_COOKIE_STATUS, {
            method: 'GET',
            credentials: 'include',
        })
            .then(response => {
                if (response.status === 200) dispatch(loginSuccess())
                else if (response.status === 401) dispatch(logoutSuccess())
            })
            .catch((error: Error) => dispatch(loginError(error.message)))
    }
}
