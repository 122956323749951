import * as React from 'react'
import {useFormik} from 'formik'
import Button from '../../kit/button'
import ELink from '../../kit/e-link'
import {useDispatch, useSelector} from 'react-redux'
import './index.less'
import {ROUTES} from '../../../navigation/routes'
import {RootStateType} from '../../../store/reducers'
import Field from '../../kit/forms-common/field'
import FieldWithMask from '../../kit/forms-common/field/with-mask'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'
import LinkBelowTheForm from '../../kit/forms-common/link-below-the-form'
import MobileOutlined from '@ant-design/icons/MobileOutlined'

import onSubmitCallback from './on-submit'
import validateCallback from './validate'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import LinkToApp from './link-to-app'

const LoginForm = (props: {}) => {
    const dispatch = useDispatch()
    const {executeRecaptcha} = useGoogleReCaptcha()
    const errors = useSelector(
            (state: RootStateType) => state.authentication.errorMessage
        ),
        loginStatus = useSelector(
            (state: RootStateType) => state.authentication.requestStatus
        ),
        formik = useFormik({
            initialValues: {
                phone: '',
                password: '',
            },
            validate: validateCallback,
            onSubmit: values =>
                onSubmitCallback(values, executeRecaptcha, dispatch),
        })
    return (
        <form className={'login-form'} onSubmit={formik.handleSubmit}>
            {loginStatus !== 'started' ? (
                <>
                    <FieldWithMask
                        label={'Телефон'}
                        fieldName={'phone'}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        errors={formik.errors.phone}
                        mask={'+7 (999) 999-99-99'}
                        type={'tel'}
                    />
                    <Field
                        label={'Пароль'}
                        onChange={formik.handleChange}
                        type={'password'}
                        value={formik.values.password}
                        fieldName={'password'}
                    />
                    <ErrorParagraph errors={errors} />
                    <div
                        className={
                            'login-form__buttons login-form__buttons_center'
                        }
                    >
                        <Button buttonType={'primary'}>Вход</Button>
                        <ELink
                            to={ROUTES.REGISTRATION}
                            style={{fontWeight: 400}}
                            buttonType={'primary'}
                        >
                            Регистрация
                        </ELink>
                    </div>
                    <LinkToApp />
                    {/* <LinkBelowTheForm to={ROUTES.PASSWORD_RESTORE}>
                        Выслать новый пароль
                    </LinkBelowTheForm> */}
                </>
            ) : (
                <RingLoaderCustomized verticalMargin={'0'} />
            )}
        </form>
    )
}

export default React.memo(LoginForm)
