import './index.less'
import * as React from 'react'
import moment from 'moment'
import Button from '../../../kit/button'
import ELink from '../../../kit/e-link'
import {numberOfMonthToString, VALID_POLICY_NAMES} from '../../../../constants'
import getInsuranceType from '../../../../constants/types-of-policies'
import RingLoaderCustomized from '../../../kit/ring-loader-customized'
import {useEffect, useMemo, useState, ReactNode} from 'react'
import getListOfDocuments from '../../../../requests/get-list-of-documents'
import getPdfDocument from '../../../../requests/get-pdf/get-pdf-document'
import getPdfEpolicy from '../../../../requests/get-pdf/get-pdf-epolicy'
import downloadFile from '../../../../helpers/download-file'
import {DocumentInfoInterface} from '../../../../types/models/document-info'
import {GetListOfDocumentsResponseType} from '../../../../types/responses/get-list-of-documents'
import {EPolicyShortDataInterface} from '../../../../types/index'
import OsagoProject from './osago-project'

interface PolicyCardPropsInterface {
    epolicy: EPolicyShortDataInterface | null
    isn: number
    policyType: 'ОСАГО' | string
    activeUntilDate: moment.Moment
    insuredName: string
    linkToRenewAgreement?: string
    children?: ReactNode
}

const PolicyCard = ({
    epolicy,
    isn,
    policyType,
    activeUntilDate,
    insuredName,
    linkToRenewAgreement = undefined,
    children = undefined,
}: PolicyCardPropsInterface) => {
    const [isLoading, setIsLoading] = useState(true)
    let [documentIsn, setDocumentIsn] = useState<number | null>(null)

    const activeUntilDateFormatted: string = useMemo(() => {
        let result: string = ''
        result += activeUntilDate.date() + ' '
        result += numberOfMonthToString[activeUntilDate.month()] + ' '
        result += activeUntilDate.year()
        return result
    }, [activeUntilDate])

    // проверяет дату окончания страховки, если остался меньше одного месяца - вернет тру
    const isPolicyEndDateSoon: boolean = useMemo(() => {
        const dateNowPlusMonth = moment(Date.now())
        dateNowPlusMonth.add(1, 'M')
        return activeUntilDate.isBefore(dateNowPlusMonth)
    }, [activeUntilDate])

    useEffect(() => {
        // Провереят на наличие документов доступных для скачивания,
        // Сначала получает массив объектов с инфой по документам (либо пустой массив),
        // затем фильтрует его и кладёт в стейт (documentIsn) isn подходящей пдф-ки
        //
        setIsLoading(true) // Отображем спинер
        if (epolicy !== null) {
            // Сразу же отключаем спинер для договоров ОСАГО,
            // при условии что у них есть epolicy.
            // Это позволяет ускорить отображение кнопок для ОСАГО
            setIsLoading(false)
        } else {
            // Фетчим список документов с апихи,
            // в ответ приходит либо массив с объектами, либо пустой массив.
            getListOfDocuments({isn: isn})
                .then((data: GetListOfDocumentsResponseType) => {
                    if (data.success === true) {
                        return data.data
                    } else {
                        setIsLoading(false) // останавливаем спинер в случае ошибки
                        throw new Error(data.errors.toString())
                    }
                })
                .then((documentsList: DocumentInfoInterface[]) => {
                    if (documentsList.length === 0) {
                        // Если пришёл пустой массив, то устанавливаем в стейт null
                        // в качестве isn для пдф
                        setDocumentIsn(null)
                    } else {
                        // Проходим по массиву и ищем, содержатся ли имена документов
                        // в списке разрешённых имён (VALID_POLICY_NAMES)
                        let validDocument:
                            | DocumentInfoInterface
                            | undefined = documentsList.find(
                            (document: DocumentInfoInterface) => {
                                return VALID_POLICY_NAMES.some(validName => {
                                    return document.name.includes(validName)
                                })
                            }
                        )
                        if (validDocument !== undefined) {
                            // Если содержатся, то пихаем isn конкретного документа
                            // в стейт (documentIsn)
                            setDocumentIsn(validDocument.isn)
                        } else {
                            // Если не содержатся, то пихаем в стейт null
                            setDocumentIsn(null)
                        }
                    }
                })
                .catch(errors => {
                    console.groupCollapsed(
                        `Не удалось получить список файлов для isn: ${isn} \nПеречисление ошибок:`
                    )
                    console.error(errors)
                    console.groupEnd()
                })
                .finally(() => {
                    // В конце выключаем спинер
                    setIsLoading(false)
                })
        }
    }, [epolicy, isn])

    // Проверяем, надо ли показывать кнопку
    // Если спинер отключён, и при этом есть либо epolicy, либо isn пдф-ки,
    // то показываем
    const showButton = (documentIsn !== null || epolicy !== null) && !isLoading
    const isOsagoProject = policyType === 'ОСАГО' && isn < 0

    const onClickHandler = () => {
        // В зависимости от того, есть ли у документа epolicy или нет,
        // назначаем одну из двух функций загрузки
        // (они разные, у них разные url для запросов)
        if (epolicy !== null) {
            return getPdfEpolicy(epolicy).then(downloadFile)
        } else {
            return getPdfDocument({
                isn: isn,
                refIsn: documentIsn!,
            }).then(downloadFile)
        }
    }

    return (
        <div className={'policy-card'}>
            <div className={'policy-card__info'}>
                <span className={'policy-card__policy-type'}>
                    {getInsuranceType(policyType)}
                </span>
                <span
                    className={`policy-card__active-until-date ${
                        isPolicyEndDateSoon
                            ? 'policy-card__active-until-date_end-soon'
                            : ''
                    }`}
                >
                    {'до ' + activeUntilDateFormatted + ' года'}
                </span>
                <span className={'policy-card__insured-name'}>
                    {insuredName}
                </span>
            </div>
            <div className={'policy-card__buttons'}>
                {isLoading && (
                    <RingLoaderCustomized size={34} verticalMargin={'0px'} />
                )}
                {showButton && policyType !== 'ДМС' ? (
                    <Button onClick={onClickHandler} buttonType='secondary'>
                        Договор
                    </Button>
                ) : null}
                {linkToRenewAgreement ? (
                    <ELink buttonType='primary' to={linkToRenewAgreement}>
                        {' '}
                        Продлить{' '}
                    </ELink>
                ) : null}
                {isOsagoProject ? <OsagoProject isn={isn} /> : null}
            </div>
        </div>
    )
}

export default React.memo(PolicyCard)
