function downloadFile(blob: Blob, filename: string = 'policy.pdf'): void {
    // const filename = 'policy.pdf'
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    // document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    // document.body.removeChild(link)
}
export default downloadFile
