import {DmsContractsStateInterface} from '../../types/state'
import {LoadDmsContractsActionsType} from '../../types/actions'
import {TYPES} from '../actions/actionTypes'

export const initialState: DmsContractsStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
}

export function dmsContracts(
    state = initialState,
    action: LoadDmsContractsActionsType
): DmsContractsStateInterface {
    switch (action.type) {
        case TYPES.LOAD_DMSCONTRACTS_STARTED:
            return {
                requestStatus: 'started',
                errorMessage: '',
                data: null,
            }
        case TYPES.LOAD_DMSCONTRACTS_SUCCESS:
            return {
                requestStatus: 'success',
                errorMessage: '',
                data: action.data,
            }
        case TYPES.LOAD_DMSCONTRACTS_ERROR:
            return {
                requestStatus: 'error',
                errorMessage: action.errorMessage,
                data: null,
            }
        default:
            return state
    }
}
