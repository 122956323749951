import * as React from 'react'
import Form from 'antd/es/form'
import 'antd/es/form/style'
import Input from 'antd/es/input'
import 'antd/es/input/style'
import Upload from 'antd/es/upload/'
import 'antd/es/upload/style'
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import 'antd/es/form/style'
import 'antd/es/input/style'
import 'antd/es/upload/style'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'
import Button from '../../kit/button'

import onSubmitCallback from './on-submit'
import beforeUploadCallback from './before-upload'
import {UploadsType} from '../../../types/requests'
import {useHistory} from 'react-router'
import './index.less'

const submitButtonStyle = {
    width: '150px',
}

const AppealForm = (props: {}) => {
    const history = useHistory()
    const [errors, setErrors] = React.useState<string>(''),
        [uploads, setUploads] = React.useState<UploadsType>([]),
        beforeUpload = React.useCallback(
            (file: any) => beforeUploadCallback(file, setUploads),
            []
        ),
        onRemoveFile = React.useCallback((file: any) => {
            setUploads(uploads =>
                uploads.filter(cur => cur.fileName !== file.name)
            )
            return true
        }, []),
        onFormSubmit = React.useCallback(
            (values: any) =>
                onSubmitCallback(values, uploads, setErrors, history),
            [history, uploads]
        )

    return (
        <Form className={'appeal-form'} onFinish={onFormSubmit}>
            <ErrorParagraph errors={errors} />
            <Form.Item
                name={'textAppeal'}
                labelCol={{span: 24}}
                label={'Текст обращения'}
                rules={[{required: true}]}
            >
                <Input.TextArea rows={6} />
            </Form.Item>
            <Form.Item labelCol={{span: 24}} label={'Загрузить файл'}>
                <Upload
                    multiple={true}
                    onRemove={onRemoveFile}
                    beforeUpload={beforeUpload}
                >
                    <Button buttonType={'secondary'}>
                        {/* @ts-ignore */}
                        <UploadOutlined /> Загрузить
                    </Button>
                </Upload>
            </Form.Item>
            <Form.Item>
                <Button buttonType='primary' style={submitButtonStyle}>
                    Отправить
                </Button>
            </Form.Item>
        </Form>
    )
}

export default React.memo(AppealForm)
