import * as React from 'react'
import './index.less'
import {NavLink} from 'react-router-dom'
import {logout} from '../../../../store/actions/creators-for-requests/authentication'
import {useDispatch} from 'react-redux'
import MENU from '../../../../navigation/menu'

const BurgerMenu: React.FunctionComponent = props => {
    const dispatch = useDispatch()
    const menuRef = React.useRef<HTMLDivElement>(null)

    const handleBurgerOpenClick = React.useCallback(() => {
        menuRef.current!.classList.add('burger-menu_opened')
    }, [])

    const handleBurgerCloseClick = React.useCallback(() => {
        menuRef.current!.classList.remove('burger-menu_opened')
    }, [])

    return (
        <>
            <div onClick={handleBurgerOpenClick} className={'burger-icon'}>
                <div className={'burger-icon__line'} />
                <div className={'burger-icon__line'} />
                <div className={'burger-icon__line'} />
            </div>
            <div className={'burger-menu'} ref={menuRef}>
                <div className={'close-icon'} onClick={handleBurgerCloseClick}>
                    <div
                        className={'close-icon__line close-icon__line_rotate1'}
                    />
                    <div
                        className={'close-icon__line close-icon__line_rotate2'}
                    />
                </div>
                <nav className={'burger-menu__nav'}>
                    {MENU.map(cur => (
                        <NavLink
                            key={cur.desktopIconClassName}
                            onClick={handleBurgerCloseClick}
                            className={'burger-menu__linked-button'}
                            to={cur.url}
                        >
                            {cur.name}
                        </NavLink>
                    ))}
                    <div
                        className={'burger-menu__linked-button'}
                        onClick={() => dispatch(logout())}
                    >
                        Выйти
                    </div>
                </nav>
            </div>
        </>
    )
}

export default React.memo(BurgerMenu)
