import * as React from 'react'
import {Heading} from '../../kit/heading'
import MarginWrapper from '../../layout/margin-wrapper/'
import InsuranceCaseCard from './components/insurance-case-card/'
import '../../common-styles/icons.less'
import './index.less'
import Paragraph from '../../kit/paragraph/'
import {insuranceTypes} from '../../../constants'
import '../../common-styles/index.less'

import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'

const cardSizes = {
    xs: 24,
    sm: 12,
    xl: 8,
    xxl: 6,
}

const InsuranceCase: React.FunctionComponent = props => {
    return (
        <main className={'main'}>
            <MarginWrapper>
                <Heading>Страховой случай</Heading>

                <Paragraph>
                    Выберите вид страхования, по которому произошел страховой
                    случай и следуйте инструкции
                </Paragraph>
                <div className={'insurance-case-cards'}>
                    <Row justify={'start'} gutter={[24, 30]}>
                        {insuranceTypes.map(type => (
                            <Col key={type.url} {...cardSizes}>
                                <InsuranceCaseCard
                                    key={type.url}
                                    title={type.name}
                                    iconClassname={type.iconClass}
                                    instructionUrl={type.url}
                                    callButtonUrl='#'
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </MarginWrapper>
        </main>
    )
}

export default React.memo(InsuranceCase)
