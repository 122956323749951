import * as React from 'react'
import './index.less'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

// todo - Что это?

const ScrollToTop = () => {
    const {pathname} = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}

const MarginWrapper = ({
    children,
    className = '',
}: {
    children: React.ReactNode
    className?: string
}) => {
    return (
        <div className={`margin-wrapper ${className}`}>
            <ScrollToTop />
            {children}
        </div>
    )
}

export default React.memo(MarginWrapper)
