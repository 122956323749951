import * as React from 'react'
import {FC} from 'react'
import ClientAgentPanel from '../../kit/client-agent-panel'
import Button from '../../kit/button'
import './index.less'
import InvitationSendingForm from '../../forms/invitation-sending-form'

interface InvitationSendingPropsInterface {
    onShowRegistry: () => void
}

const InvitationSending: FC<InvitationSendingPropsInterface> = ({
    onShowRegistry,
}) => {
    return (
        <ClientAgentPanel bgFilled>
            <InvitationSendingForm className={'invitation-sending__form'} />
            <p className={'text_center'}>
                Вы всегда можете проверить по реестру рекомендаций, кто из Ваших
                адресатов воспользовался отправленным промокодом при регистрации
                в личном кабинете и покупке страховых продуктов ЕВРОИНС
            </p>
            <div className={'text_center'}>
                <Button buttonType={'inverted'} onClick={onShowRegistry}>
                    Реестр
                </Button>
            </div>
        </ClientAgentPanel>
    )
}

export default InvitationSending
