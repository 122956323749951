import {
    ReadPurchasesPaymentByCodeResponseType,
    ReadPurchasesPaymentByCodeSuccessResponseInterface,
} from '../types/responses/read-purchases-payment-by-code'
import {URL_SALES} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'

const readPurchasesPaymentByCode = (): Promise<ReadPurchasesPaymentByCodeResponseType> =>
    fetch(URL_SALES, {
        method: 'GET',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// const readPurchasesPaymentByCode = (): Promise<ReadPurchasesPaymentByCodeResponseType> =>
//     new Promise<ReadPurchasesPaymentByCodeSuccessResponseInterface>(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: true,
//                 data: [
//                     {
//                         ecIsn: 777462304,
//                         datePay: '17.06.2020',
//                         name: 'Петров Иван Алексеевич',
//                         amount: 100,
//                         className: 'Комиссия',
//                         statusName: 'Не выплачено',
//                         reqName: '',
//                         prodName: 'Комиссия',
//                     },
//                     {
//                         ecIsn: 777462304,
//                         datePay: '17.06.2019',
//                         name: 'Конев Заяц Медведович',
//                         amount: 120,
//                         className: 'Комиссия',
//                         statusName: 'Выплачено',
//                         reqName: '',
//                         prodName: 'Комиссия',
//                     },
//                 ],
//             })
//         }, 2000)
//     })

export default readPurchasesPaymentByCode
