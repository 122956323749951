import * as React from 'react'
import {Heading} from '../../../../kit/heading'
import MarginWrapper from '../../../../layout/margin-wrapper'
import RingLoaderCustomized from '../../../../kit/ring-loader-customized'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {RootStateType} from '../../../../../store/reducers'
import {useHistory, useParams} from 'react-router-dom'
import {
    addCalculatorToPage,
    CALCULATOR_PATHS,
} from '../../../../../helpers/for-calculators'
import {ROUTES} from '../../../../../navigation/routes'

interface WindowWithCalc extends Window {
    OsagoClientCalculator: any
}

// const initCalc = (agreementIsn: string) => {
//     // @ts-ignore
//     const OsagoClientCalculator = window.OsagoClientCalculator
//     console.log('initCalc', OsagoClientCalculator)
//     if (OsagoClientCalculator) {
//         new OsagoClientCalculator('eu-calc-cont', {agreementIsn})
//     } else {
//         setTimeout(initCalc, 100)
//     }
// }

export const ShopOsagoStandard: React.FunctionComponent = () => {
    let params = useParams<{isn: string}>()
    const history = useHistory()

    const {data} = useSelector((state: RootStateType) => state.info)

    const createCalc = () => {
        const el = document.getElementById('eu-calc-cont')
        if (el) {
            new (window as WindowWithCalc &
                typeof globalThis).OsagoClientCalculator('eu-calc-cont', {
                agreementIsn: Number(params.isn),
                onSuccessSendingScans: () => {
                    history.push(ROUTES.POLICIES)
                },
            })
        }
    }

    useEffect(() => {
        console.log('shop osago mounted and data fetched')
        // @ts-ignore
        if (!window.OsagoClientCalculator) {
            window.addEventListener('eCalcLoaded', createCalc)
            addCalculatorToPage(CALCULATOR_PATHS.OSAGO_STANDARD)
        } else {
            createCalc()
        }

        // DEV
        // @ts-ignore
        // if (!window.OsagoClientCalculator) {
        //     [
        //         'http://euro-ins.lh/front/v2/node_modules/react/umd/react.development.js',
        //         'http://euro-ins.lh/front/v2/node_modules/react-dom/umd/react-dom.development.js',
        //         'http://euro-ins.lh/front/dist/js/calc/osago/client/runtime.dev.js',
        //         'http://euro-ins.lh/front/dist/js/calc/osago/client/vendors.dev.js',
        //         'http://euro-ins.lh/front/dist/js/calc/osago/client/index.dev.js',
        //     ].forEach(item => {
        //         const element = document.createElement('script')
        //         element.src = item
        //         document.body.appendChild(element)
        //     })
        // }
        // initCalc(params.isn)

        return () => {
            const OsagoClientCalculator = (window as WindowWithCalc &
                typeof globalThis).OsagoClientCalculator
            if (OsagoClientCalculator) OsagoClientCalculator.unmountApp()
        }
    }, [data, params, createCalc])

    if (data === null) return null

    if (
        data?.isn &&
        !['119943525', '119691674', '120725631', '119675622'].includes(
            data?.isn
        )
    ) {
        return (
            <main className={'main'}>
                <MarginWrapper>
                    <Heading>ОСАГО</Heading>
                    <div>Скоро тут будет калькулятор ОСАГО</div>
                </MarginWrapper>
            </main>
        )
    }

    return (
        <main className={'main'}>
            <MarginWrapper>
                <Heading>ОСАГО</Heading>
                <div id={'eu-calc-cont'}>
                    <RingLoaderCustomized />
                </div>
                <div style={{width: 100, height: 200}} />
                {/*<link*/}
                {/*    href={*/}
                {/*        'http://euro-ins.lh/front/dist/js/calc/osago/client/index.dev.css'*/}
                {/*    }*/}
                {/*    rel={'stylesheet'}*/}
                {/*/>*/}
                {/*<link*/}
                {/*    href={*/}
                {/*        'http://euro-ins.lh/front/dist/js/calc/osago/client/vendors.dev.css'*/}
                {/*    }*/}
                {/*    rel={'stylesheet'}*/}
                {/*/>*/}
            </MarginWrapper>
        </main>
    )
}
