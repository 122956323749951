import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../store/reducers'
import {useEffect} from 'react'
import {
    getBonuses,
    loadBonusesHistory,
} from '../store/actions/creators-for-requests/bonuses'
import {getInfo} from '../store/actions/creators-for-requests/info'
import {BonusesHistoryInterface, BonusesInterface} from '../types'
import {activateWelcomeBonuses} from '../store/actions/creators-for-requests/bonuses'

export interface UseBonusesReturnInterface {
    bonuses: BonusesInterface | null
    chargeWelcomeBonusesCallback: {(cardNumber?: string): void}
    bonusesHistory: BonusesHistoryInterface[] | null
}

const useBonuses = (): UseBonusesReturnInterface => {
    const dispatch = useDispatch()
    const info = useSelector((state: RootStateType) => state.info.data)
    const bonusesCountAndCard = useSelector(
        (state: RootStateType) => state.bonuses.clientBonuses.data
    )
    const bonusesHistory = useSelector(
        (state: RootStateType) => state.bonuses.bonusesHistory.data
    )

    const chargeWelcomeBonusesCallback = React.useCallback(
        (cardNumber?: string) => {
            if (info) {
                const reqData: {kiasIsn: number; cardNumber?: string} = {
                    kiasIsn: +info.isn,
                    cardNumber: cardNumber,
                }
                if (!cardNumber) {
                    delete reqData.cardNumber
                }
                dispatch(activateWelcomeBonuses(reqData))
            } else return
        },
        [dispatch, info]
    )

    useEffect(() => {
        if (info) {
            if (bonusesCountAndCard?.cardNumber) {
                dispatch(loadBonusesHistory({kiasIsn: +info.isn}))
            }
        }
    }, [dispatch, info, bonusesCountAndCard])

    useEffect(() => {
        if (info) {
            // Если информация о пользователе уже загружена,
            // мы извлекаем kiasId
            // и делаем запрос баллов
            dispatch(getBonuses({kiasIsn: +info.isn}))
        }
    }, [dispatch, info])

    return {
        bonuses: bonusesCountAndCard,
        chargeWelcomeBonusesCallback,
        bonusesHistory,
    }
}

export default useBonuses
