import * as React from 'react'
import Row from 'antd/es/grid/row'
import Col from 'antd/es/grid/col'
import 'antd/es/row/style'
import CalcCard from './calc-card'
import cardsContent from '../../../../constants/shop-cards-content'

const CalcsCardList = React.memo(() => {
    return (
        <Row gutter={[24, 30]} justify={'space-around'}>
            {cardsContent.map(cur => (
                <Col flex={`0`} key={cur.calcURL}>
                    {' '}
                    <CalcCard {...cur} />{' '}
                </Col>
            ))}
        </Row>
    )
})

export default CalcsCardList
