import {AuthenticationStateInterface} from '../../types/state'
import {AuthenticationActionsType} from '../../types/actions'
import {TYPES} from '../actions/actionTypes'

export const initialState: AuthenticationStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    authenticated: null,
}

export function authentication(
    state = initialState,
    action: AuthenticationActionsType
): AuthenticationStateInterface {
    switch (action.type) {
        case TYPES.LOGIN_STARTED:
            return {
                ...state,
                errorMessage: '',
                requestStatus: 'started',
            }
        case TYPES.LOGIN_SUCCESS:
            return {
                requestStatus: 'success',
                errorMessage: '',
                authenticated: true,
            }
        case TYPES.LOGIN_ERROR:
            return {
                requestStatus: 'error',
                errorMessage: action.errorMessage,
                authenticated: false,
            }

        case TYPES.LOGOUT_STARTED: {
            return {
                ...state,
                requestStatus: 'started',
            }
        }

        case TYPES.LOGOUT_SUCCESS: {
            return {
                ...state,
                requestStatus: 'success',
                authenticated: false,
            }
        }
        case TYPES.LOGOUT_ERROR: {
            return {
                ...state,
                requestStatus: 'error',
                errorMessage: action.errorMessage,
            }
        }

        default:
            return state
    }
}
