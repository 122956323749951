import * as React from 'react'
import {FC, useState} from 'react'
import UploadDocument from '../upload-document'
import {useCallback} from 'react'
import {RcFile} from 'antd/lib/upload'

export interface FileInfoInterface {
    fileRemark: string // "Подпись к файлу 2",
    fileData: {
        fileName: string // "pixel.png",
        fileData: string // "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII="
    }
}

interface UploadDocumentContainerInterface {
    label: string
    onSelectFile: (fileInfo: FileInfoInterface) => boolean
    onRemoveFile: () => void
}

const cutFileWithoutMeta = (fileReader: FileReader): string => {
        let result: string = ''

        if (typeof fileReader.result === 'string') {
            const index = fileReader.result.indexOf('base64')
            result = fileReader.result.slice(index + 7)
        }

        return result
    },
    readFile = async (file: RcFile): Promise<string> => {
        return new Promise(resolve => {
            const reader = new FileReader()

            reader.addEventListener('load', function() {
                const fileFormatted: string = cutFileWithoutMeta(this)
                resolve(fileFormatted)
            })

            reader.readAsDataURL(file)
        })
    }

const UploadDocumentContainer: FC<UploadDocumentContainerInterface> = ({
    label,
    onSelectFile,
    onRemoveFile,
}) => {
    const [files, setFiles] = useState<RcFile[]>([]),
        getFileInfo = useCallback(
            (fileData: string, file: RcFile): FileInfoInterface => {
                const fileRemark = `Форма "Стать агеном" ЛЛК. ${label}.`
                return {
                    fileRemark, // "Подпись к файлу 2",
                    fileData: {
                        fileName: file.name, // "pixel.png",
                        fileData, // "iVBORw0KGgoANSUhII="
                    },
                }
            },
            [label]
        ),
        handleUploadFile = useCallback(
            (file: any) => {
                setFiles([file])

                readFile(file).then(fileData => {
                    const fileInfo = getFileInfo(fileData, file)
                    onSelectFile(fileInfo)
                })

                return false
            },
            [getFileInfo, onSelectFile]
        ),
        handleRemoveFile = useCallback(() => {
            setFiles([])
            onRemoveFile()
        }, [onRemoveFile])

    return (
        <UploadDocument
            label={label}
            fileList={files}
            beforeUpload={handleUploadFile}
            onRemove={handleRemoveFile}
        />
    )
}

export default UploadDocumentContainer
