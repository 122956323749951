import Button from '../../../kit/button'
import * as React from 'react'
import {FC} from 'react'
import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined'
import {URL_SAVE_DMS_CONTRACT} from '../../../../constants/urls'

interface SavePolicyProps {
    contractIsn: number
}

const SavePolicy: FC<SavePolicyProps> = ({contractIsn}) => {
    const handleDownloadPolicyPdf = async () => {
        window.open(`${URL_SAVE_DMS_CONTRACT}/${contractIsn}.pdf`, '_blank')
    }

    return (
        <Button style={{margin: '5px'}} onClick={handleDownloadPolicyPdf}>
            {/* @ts-ignore */}
            <FilePdfOutlined /> Полис
        </Button>
    )
}

export default SavePolicy
