import {URL_DMS_GET_GUARANTEE} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'
import {GetGuaranteeResponseType} from '../../types/responses/get-guarantee'

const getGuarantee = (): Promise<GetGuaranteeResponseType> =>
    fetch(URL_DMS_GET_GUARANTEE, {credentials: 'include'}).then(handleResponse)
// .then(res => {
//     console.log(res)
//     res.data = [
//         {
//             date: '24.04.2023',
//             id: 'ГП-000-012020/23',
//             lpuName:
//                 'ЧУЗ "Центральная клиническая больница "РЖД-Медицина"',
//             status: 'Подписан',
//         },
//         {
//             date: '26.04.2023',
//             id: 'ГП-000-012067/23',
//             lpuName:
//                 'ЧУЗ "Центральная клиническая больница "РЖД-Медицина"',
//             status: 'Подписан',
//         },
//         {
//             date: '02.05.2023',
//             id: 'ГП-000-012177/23',
//             lpuName:
//                 'ЧУЗ "Центральная клиническая больница "РЖД-Медицина"',
//             status: 'Подписан',
//         },
//     ]
//     return res
// })

export default getGuarantee
