import * as React from 'react'
import './index.less'

interface LogoPropsInterface {
    className?: string
}

const Logo: React.FC<LogoPropsInterface> = ({className}) => {
    return <div className={`logo ${className ? className : ''}`} />
}

export default Logo
