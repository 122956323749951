import * as React from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {ROUTES} from '../../../navigation/routes'
import Policies from '../../pages/policies'
import PoliciesArchive from '../../pages/policies/archive'
import Appeals from '../../pages/appeals'
import CreateAppeal from '../../pages/appeals/create'
import Appeal from '../../pages/appeals/appeal'
import InsuranceCaseAccident from '../../pages/insurance-case/accident'
import InsuranceCaseAuto from '../../pages/insurance-case/auto'
import InsuranceCaseTravel from '../../pages/insurance-case/travel'
import InsuranceCaseHealth from '../../pages/insurance-case/health'
import InsuranceCaseProperty from '../../pages/insurance-case/property'
import InsuranceCase from '../../pages/insurance-case'
import {PersonalData} from '../../pages/personal-data'
import Bonuses from '../../pages/bonuses'
import {Header} from '../header'
import {Footer} from '../footer'
import {RootStateType} from '../../../store/reducers'
import {checkAuthentication} from '../../../store/actions/creators-for-requests/authentication'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import {NotFound} from '../../pages/not-found'
import ClientAgent from '../../pages/client-agent'
import Shop from '../../pages/shop'
import {CalcIframesRoutes} from '../../pages/shop/iframe-list'
import {ShopTravelStandard} from '../../pages/shop/travel/standard'
import EuroinsChat from '../euroins-chat'
import Dms from '../../pages/dms'
import {ShopOsagoStandard} from '../../pages/shop/osago/standard'
import GuaranteeMails from '../../pages/dms/guarantee-mails'
import Contract from '../../pages/dms/contract'
import {useEffect} from 'react'

// prettier-ignore
const PrivateSwitch: React.FC = (props: {}) => {
    const history = useHistory()
    
    const dispatch = useDispatch(),
        isAuthenticated = useSelector(
            (state: RootStateType) => state.authentication.authenticated
        )

    useEffect(() => {
        dispatch(checkAuthentication())

        const unlisten = history.listen((location, action) => {
            dispatch(checkAuthentication())
        })

        return () => unlisten()
    }, [])

    return isAuthenticated ? (
        <>
            <Header/>
            <Switch>
                <Route exact path={ROUTES.POLICIES} component={Policies}/>
                <Route exact path={ROUTES.POLICIES_ARCHIVE} component={PoliciesArchive}/>
                <Route exact path={ROUTES.APPEALS} component={Appeals}/>
                <Route exact path={ROUTES.CREATE_APPEAL} component={CreateAppeal}/>
                <Route exact path={ROUTES.APPEAL} component={Appeal}/>
                <Route exact path={ROUTES.INSURANCE_CASE_ACCIDENT} component={InsuranceCaseAccident}/>
                <Route exact path={ROUTES.INSURANCE_CASE_AUTO} component={InsuranceCaseAuto}/>
                <Route exact path={ROUTES.INSURANCE_CASE_TRAVEL} component={InsuranceCaseTravel}/>
                <Route exact path={ROUTES.INSURANCE_CASE_HEALTH} component={InsuranceCaseHealth}/>
                <Route exact path={ROUTES.INSURANCE_CASE_PROPERTY} component={InsuranceCaseProperty}/>
                <Route exact path={ROUTES.INSURANCE_CASE} component={InsuranceCase}/>
                <Route exact path={ROUTES.PERSONAL_DATA} component={PersonalData}/>
                <Route exact path={[ROUTES.PERSONAL_DATA_FORM, ROUTES.PERSONAL_DATA]} component={PersonalData}/>
                <Route exact path={ROUTES.BONUSES} component={Bonuses}/>
                <Route exact path={ROUTES.CLIENT_AGENT} component={ClientAgent}/>
                <Route exact path={[ROUTES.SHOP_OSAGO_STANDARD, ROUTES.SHOP_OSAGO_STANDARD_ISN]} component={ShopOsagoStandard} />
                <Route exact path={ROUTES.SHOP_TRAVEL_STANDARD} component={ShopTravelStandard} />
                <Route exact path={ROUTES.SHOP} component={Shop}/>
                <Route exact path={ROUTES.DMS_GUARANTEE_MAILS} component={GuaranteeMails}/>
                <Route exact path={ROUTES.DMS_CONTRACT} component={Contract}/>
                <Route exact path={ROUTES.DMS} component={Dms}/>
                <CalcIframesRoutes />
                <Route path={ROUTES.NOT_FOUND} component={NotFound} />
            </Switch>
            <EuroinsChat />
            <Footer/>
        </>
    ) : isAuthenticated === null ? <RingLoaderCustomized verticalMargin={'0'}/> : <Redirect to={{pathname: ROUTES.AUTH, state: {from: window.location.pathname}}}/>
}

export default PrivateSwitch
