import {ReadInvitationsResponseType} from '../types/responses/read-invitations'
import {URL_INVITATIONS} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'

const readInvitations = (): Promise<ReadInvitationsResponseType> =>
    fetch(URL_INVITATIONS, {
        method: 'GET',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// const readInvitations = (): Promise<ReadInvitationsResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: true,
//                 data: [],
//             })
//         }, 2000)
//     })

/*
    Фейковый ошибочный запрос
 */
// const readInvitations = (): Promise<ReadInvitationsResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: false,
//                 errors: ['Ошибка №1', 'Ошибка №2'],
//             })
//         }, 2000)
//     })

export default readInvitations
