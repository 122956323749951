import * as React from 'react'
import './index.less'
import Logo from '../../kit/logo/'
import MarginWrapper from '../margin-wrapper/'

export const Footer: React.FunctionComponent = () => {
    return (
        <footer className='footer'>
            <MarginWrapper className='footer-wrapper'>
                <Logo />
                <div className='footer__contacts contacts'>
                    <span className='contacts__label'>
                        Круглосуточный контакт-центр
                    </span>
                    <a
                        href='tel:+74959265155'
                        className='contacts__phone-number'
                    >
                        +7 (495) 926-51-55
                    </a>
                </div>
                <div className='footer__links footer-links'>
                    <div className='footer-links__column'>
                        <a
                            className='footer__link'
                            href='https://euro-ins.ru/o-kompanii'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            О компании
                        </a>
                        <a
                            className='footer__link'
                            href='https://euro-ins.ru/kontakty'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Контакты
                        </a>
                        <a
                            className='footer__link'
                            href='https://euro-ins.ru/#insurance_case'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Экстренная помощь
                        </a>
                    </div>
                    <div className='footer-links__column'>
                        <a
                            className='footer__link'
                            href='https://euro-ins.ru/o-kompanii/partnery'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Партнеры
                        </a>
                        <a
                            className='footer__link'
                            href='https://euro-ins.ru/news'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Новости
                        </a>
                    </div>
                </div>
                <div className='footer__license-info'>
                    <span>© 2009-2020, OOO РСО «ЕВРОИНС»</span>
                    <span>
                        Все материалы, размещенные на данном сайте, защищены
                        законом об авторских правах
                    </span>
                    <span>и являются собственностью страховой компании.</span>
                    <span>
                        Лицензии на страхование ЦБ РФ СЛ №3954, СИ №3954, ОС
                        №3954-03 тел. +7 (495) 926-51-55
                    </span>
                </div>
            </MarginWrapper>
        </footer>
    )
}
