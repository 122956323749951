export enum TYPES {
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_ERROR,

    LOGOUT_STARTED,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,

    LOAD_INFO_STARTED,
    LOAD_INFO_SUCCESS,
    LOAD_INFO_ERROR,

    LOAD_AGREEMENTS_STARTED,
    LOAD_AGREEMENTS_SUCCESS,
    LOAD_AGREEMENTS_ERROR,

    LOAD_USER_AGREEMENTS_STARTED,
    LOAD_USER_AGREEMENTS_SUCCESS,
    LOAD_USER_AGREEMENTS_ERROR,

    LOAD_DMSCONTRACTS_STARTED,
    LOAD_DMSCONTRACTS_SUCCESS,
    LOAD_DMSCONTRACTS_ERROR,

    LOAD_APPEALS_STARTED,
    LOAD_APPEALS_SUCCESS,
    LOAD_APPEALS_ERROR,

    LOAD_BONUSES_STARTED,
    LOAD_BONUSES_SUCCESS,
    LOAD_BONUSES_ERROR,
    SET_BONUSES_STATUS,

    LOAD_BONUSES_IS_ACTIVATED_STARTED,
    LOAD_BONUSES_IS_ACTIVATED_SUCCESS,
    LOAD_BONUSES_IS_ACTIVATED_ERROR,

    LOAD_BONUSES_HISTORY_STARTED,
    LOAD_BONUSES_HISTORY_SUCCESS,
    LOAD_BONUSES_HISTORY_ERROR,

    ACTIVATE_WELCOME_BONUSES_STARTED,
    ACTIVATE_WELCOME_BONUSES_SUCCESS,
    ACTIVATE_WELCOME_BONUSES_ERROR,
    ACTIVATE_WELCOME_BONUSES_REVERT_INITIAL,

    LOAD_CITIES_DICTIONARY_STARTED,
    LOAD_CITIES_DICTIONARY_SUCCESS,

    LOAD_CITY_BY_ISN_STARTED,
    LOAD_CITY_BY_ISN_SUCCESS,
    LOAD_CITY_BY_ISN_ERROR,

    CLEAR_STATE,
}
