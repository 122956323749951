import {ActivateWelcomeBonusesStateInterface} from '../../../types/state'
import {ActivateWelcomeBonusesActionsInterface} from '../../../types/actions'
import {TYPES} from '../../actions/actionTypes'

export const initialState: ActivateWelcomeBonusesStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
}

export function activateWelcome(
    state = initialState,
    action: ActivateWelcomeBonusesActionsInterface
): ActivateWelcomeBonusesStateInterface {
    switch (action.type) {
        case TYPES.ACTIVATE_WELCOME_BONUSES_STARTED:
            return {
                ...initialState,
                requestStatus: 'started',
            }
        case TYPES.ACTIVATE_WELCOME_BONUSES_SUCCESS:
            return {
                errorMessage: '',
                requestStatus: 'success',
            }
        case TYPES.ACTIVATE_WELCOME_BONUSES_ERROR: {
            return {
                errorMessage: action.errorMessage,
                requestStatus: 'error',
            }
        }

        case TYPES.ACTIVATE_WELCOME_BONUSES_REVERT_INITIAL: {
            return {
                ...initialState,
            }
        }

        default:
            return state
    }
}
