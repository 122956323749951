import {FC, default as React, useMemo} from 'react'
import PersonalInfoItem from '../personal-info-item'
import formatGender from '../../../../../helpers/for-formatting/format-gender'
import PersonalInfoCard from '../personal-info-card'
import {ContactsFormInterface} from '../../../../../types/forms'
import {POPUP_FORM_ID} from '../personal-info-list'
import {ROUTES} from '../../../../../navigation/routes'
import PopupWrapper from '../popup-wrapper'
import ContactsForm from '../../../../forms/contacts-form'
import usePersonalInfoCardHooks from '../../hooks/use-personal-info-card-hooks'

interface ContactDataCardPropsInterface {
    data: ContactsFormInterface
}

const CARD_TITLE = 'Контактные данные'

const ContactDataCard: FC<ContactDataCardPropsInterface> = ({data}) => {
    const linkToPopup = useMemo(
            () => `${ROUTES.PERSONAL_DATA}/${POPUP_FORM_ID.CONTACT_DATA}`,
            []
        ),
        {goToPersonalData, closePopup, popupFormId} = usePersonalInfoCardHooks()

    const openPopup = popupFormId === POPUP_FORM_ID.CONTACT_DATA,
        fullName = [data.lastName, data.firstName, data.middleName].join(' ')

    const values = React.useMemo(() => {
        return {
            phone: data.phone,
            email: data.email,
            sex: formatGender(data.sex),
            birthday: data.birthday,
        }
    }, [data])

    return (
        <>
            <PersonalInfoCard title={CARD_TITLE} linkTo={linkToPopup}>
                <PersonalInfoItem label={'ФИО'} value={fullName} />
                <PersonalInfoItem value={values.phone} label={'Телефон'} />
                <PersonalInfoItem
                    label={'Электронная почта'}
                    value={values.email}
                />
                <PersonalInfoItem label={'Пол'} value={values.sex} />
                <PersonalInfoItem
                    label={'Дата рождения'}
                    value={values.birthday}
                />
            </PersonalInfoCard>

            {/* <PopupWrapper open={openPopup} onClose={closePopup}>
                <ContactsForm
                    title={CARD_TITLE}
                    data={data}
                    onSuccessfulDataChange={goToPersonalData}
                />
            </PopupWrapper> */}
        </>
    )
}

export default React.memo(ContactDataCard)
