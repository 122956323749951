import * as React from 'react'
import {FC} from 'react'
import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'
import ClientAgentPanel from '../../kit/client-agent-panel'
import './index.less'
import Button from '../../kit/button'

interface ClientAgentPromoCodePropsInterface {
    onShowPurchasesByCode: () => void
    // onGetPaid: (sale: ClientAgentSaleInterface) => void
}

const ClientAgentPromoCode: FC<ClientAgentPromoCodePropsInterface> = ({
    onShowPurchasesByCode,
    // onGetPaid,
}) => {
    return (
        <ClientAgentPanel className={'promo-code-panel'}>
            <p>Ваш промокод для приглашения клиентов: </p>
            <div className={'promo-code-panel__code'}>9215478462</div>
            <p className={'text_center'}>
                Как пользоваться персональным промокодом?
            </p>
            <p className={'text_center'}>
                - впишите номер и имя потенциального клиента в форме отправки;{' '}
                <br />
                - отправьте сообщение с промокодом* и рекомендацией; <br />
                - ожидайте регистрации и покупок <br />
            </p>
            <p className={'text_bold text_center'}>
                *промокод можно передать и любым другим удобным способом (устная
                форма, печатные материалы, посты в социальных сетях, email
                рассылки и пр.)
            </p>
            <p className={'text_center'}>
                Выгода для получателя: При регистрации по промокоду начисляется
                дополнительные 2000 баллов на бонусный счет. Баллы сразу можно
                использовать для оплаты части полиса.
            </p>
            <p className={'text_center'}>
                Ваша выгода: Клиент навсегда закрепляется за Вами. Со всех его
                покупок в личном кабинете Вам начисляется вознаграждение до 20%.
            </p>

            <Row gutter={16}>
                <Col>
                    <Button
                        buttonType={'secondary'}
                        onClick={onShowPurchasesByCode}
                    >
                        Покупки по коду
                    </Button>
                </Col>
                {/*<Col>*/}
                {/*    <Button onClick={onGetPaid}>Получить выплату</Button>*/}
                {/*</Col>*/}
            </Row>
        </ClientAgentPanel>
    )
}

export default ClientAgentPromoCode
