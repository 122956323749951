import * as React from 'react'
import {FC} from 'react'
import './index.less'
import '../../../kit/e-link/index.less'

interface LinkToAppProps {}

const LinkToApp: FC<LinkToAppProps> = () => {
    return (
        <div className={'app-links'}>
            <p className={'app-links__title'}>
                Скачать мобильное приложение ДМС:
            </p>
            <div className={'app-links__links'}>
                <a
                    className={
                        'e-link e-link-button e-link-button_secondary app-links__link'
                    }
                    href={'https://apps.rustore.ru/app/ru.euroins.dms'}
                >
                    RuStore
                </a>
                <a
                    className={
                        'e-link e-link-button e-link-button_secondary app-links__link'
                    }
                    href={
                        'https://apps.apple.com/ru/app/%D0%B5%D0%B2%D1%80%D0%BE%D0%B8%D0%BD%D1%81-%D0%BC%D0%B5%D0%B4/id6451315472'
                    }
                >
                    App Store
                </a>
            </div>
        </div>
    )
}

export default LinkToApp
