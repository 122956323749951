import * as React from 'react'
import {FC, useMemo} from 'react'
import './index.less'

interface ClientAgentPanelPropsInterface {
    bgFilled?: boolean
    className?: string
    children?: any
}

const ClientAgentPanel: FC<ClientAgentPanelPropsInterface> = ({
    bgFilled = false,
    className = '',
    children,
}) => {
    const allClassNames = useMemo(
        () =>
            `client-agent-panel${
                bgFilled ? ' client-agent-panel_bg_yellow' : ''
            } ${className}`,
        [bgFilled, className]
    )

    return <div className={allClassNames}>{children}</div>
}

export default ClientAgentPanel
