import * as React from 'react'
import Paragraph from '../../paragraph'

const ErrorParagraph = React.memo(({errors}: {errors: string | undefined}) => {
    return errors ? (
        <Paragraph
            overrideStyles={{
                color: 'red',
                paddingBottom: '0',
                textAlign: 'center',
            }}
        >
            {errors}
        </Paragraph>
    ) : null
})

export default ErrorParagraph
