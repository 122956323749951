import {
    loadUserAgreementsError,
    loadUserAgreementsStarted,
    loadUserAgreementsSuccess,
} from '../actionCreators'
import {URL_USER_AGREEMENTS} from '../../../constants/urls'
import {handleResponse} from '../../../helpers/for-requests'
import {GetUserAgreementsResponseType} from '../../../types/responses'

export function getUserAgreements() {
    return async function(dispatch: any) {
        dispatch(loadUserAgreementsStarted())
        return fetch(URL_USER_AGREEMENTS, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((data: GetUserAgreementsResponseType) => {
                if (data.success) {
                    dispatch(loadUserAgreementsSuccess(data.data))
                } else {
                    dispatch(loadUserAgreementsError(data.error))
                }
            })
            .catch((error: Error) =>
                dispatch(loadUserAgreementsError(error.message))
            )
    }
}
