import {Link} from 'react-router-dom'
import * as React from 'react'
import './index.less'

const LinkBelowTheForm = ({
    to,
    children,
}: {
    to: string
    children: React.ReactChild
}) => {
    return (
        <Link className='link-below-the-form' to={to}>
            {children}
        </Link>
    )
}

export default React.memo(LinkBelowTheForm)
