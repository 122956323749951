import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import Table from 'antd/lib/table'
import 'antd/lib/table/style'
import {Header} from '../../../layout/header'
import MarginWrapper from '../../../layout/margin-wrapper'
import PageHeader from '../components/page-header'
import {RequestStateInterface} from '../../../../types/state'
import GuaranteeMail from '../../../../types/models/guarantee-mail'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../helpers/for-changing-requests-state/create-request-state'
import getGuarantee from '../../../../requests/dms/get-guarantee'
import hideJdiv from '../../../../helpers/jivo/hide-jdiv'
import getPdfDocument from '../../../../requests/get-pdf/get-pdf-document'
import Button from '../../../kit/button'
import downloadFile from '../../../../helpers/download-file'

const getInitialState = createInitialState<GuaranteeMail[]>()

interface GuaranteeMailsProps {}

const GuaranteeMails: FC<GuaranteeMailsProps> = () => {
    const [getGuaranteeMailsState, setGetGuaranteeMailsState] = useState<
        RequestStateInterface<GuaranteeMail[]>
    >(getInitialState)
    const isLoading = getGuaranteeMailsState.requestStatus === 'started'

    const runGetGuaranteeMails = async () => {
        setGetGuaranteeMailsState(createStartedState())
        try {
            const result = await getGuarantee()
            if (result.success)
                setGetGuaranteeMailsState(createSuccessState(result.data))
            else setGetGuaranteeMailsState(createErrorState(result.errors))
        } catch (e) {
            // @ts-ignore
            setGetGuaranteeMailsState(createErrorState([e.message]))
        }
    }

    useEffect(() => {
        runGetGuaranteeMails()
    }, [])

    useEffect(
        () => () => {
            hideJdiv()
        },
        []
    )

    const onSave = (record: GuaranteeMail) => {
        getPdfDocument(
            {
                refIsn: record.refIsn,
                isn: record.isn,
            },
            '?type=D'
        ).then(blob => downloadFile(blob, `guarantee-${record.isn}.pdf`))
    }

    return (
        <>
            <Header />
            <MarginWrapper className={'main'}>
                <PageHeader />

                <Table
                    dataSource={getGuaranteeMailsState.data || []}
                    loading={isLoading}
                    pagination={false}
                    rowKey={record => record.id}
                >
                    <Table.Column title={'Идентификатор'} dataIndex={'id'} />
                    <Table.Column title={'Дата'} dataIndex={'date'} />
                    <Table.Column title={'Статус'} dataIndex={'status'} />
                    <Table.Column
                        title={'Наименование'}
                        dataIndex={'lpuName'}
                    />
                    <Table.Column
                        title={''}
                        dataIndex={'actions'}
                        render={(v, record: GuaranteeMail) => {
                            return (
                                <Button onClick={() => onSave(record)}>
                                    Скачать
                                </Button>
                            )
                        }}
                    />
                </Table>
            </MarginWrapper>
        </>
    )
}

export default GuaranteeMails
