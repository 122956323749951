import * as React from 'react'
import MarginWrapper from '../../../layout/margin-wrapper'
import {Heading} from '../../../kit/heading'
import '../../../common-styles/index.less'
import AppealForm from '../../../forms/appeal-form'
import {ROUTES} from '../../../../navigation/routes'
import {Link} from 'react-router-dom'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import 'antd/lib/row/style'
import 'antd/lib/col/style'

const CreateAppeal = () => {
    return (
        <main className={'main'}>
            <MarginWrapper>
                <Row justify={'space-between'}>
                    <Col>
                        <Heading>Создать обращение</Heading>
                    </Col>
                    <Col flex={'0 0 90px'}>
                        <Link
                            to={ROUTES.APPEALS}
                            className={'main__heading-link'}
                        >
                            Назад
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} lg={24}>
                        <AppealForm />
                    </Col>
                </Row>
            </MarginWrapper>
        </main>
    )
}

export default React.memo(CreateAppeal)
