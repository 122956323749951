import './index.less'
import * as React from 'react'
import ELink from '../../../../kit/e-link'

interface PolicyCardPropsInterface {
    title: string
    iconClassname: string
    callButtonUrl: string
    instructionUrl: string
    children?: React.ReactNode
}

const InsuranceCaseCard = ({
    title,
    iconClassname,
    callButtonUrl,
    instructionUrl,
}: PolicyCardPropsInterface) => {
    return (
        <div className={'insurance-case-card'}>
            <div className={`${iconClassname} insurance-case-card__icon`} />
            <span className={'insurance-case-card__title'}>{title}</span>
            <div className={'insurance-case-card__buttons'}>
                <ELink buttonType='secondary' to={callButtonUrl}>
                    звонок
                </ELink>
                <ELink buttonType='primary' to={instructionUrl}>
                    инструкция
                </ELink>
            </div>
        </div>
    )
}

export default React.memo(InsuranceCaseCard)
