import * as React from 'react'
import './index.less'
import {UseBonusesReturnInterface} from '../../../hooks/use-bonuses'
import RenderBonusesContent from './render-bonuses-content'
import RingLoaderCustomized from '../ring-loader-customized'
import {useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import {BonusesDisplayStatus} from '../../../store/reducers/bonuses/count-and-card'

interface BonusesPromoPropsInterface {
    bonusesInfo: UseBonusesReturnInterface
    showBonusesHistory?: boolean
}

const BonusesPromo = ({
    showBonusesHistory = true,
    bonusesInfo,
}: BonusesPromoPropsInterface) => {
    const bonusesDisplayMode = useSelector(
        (state: RootStateType) => state.bonuses.clientBonuses.displayStatus
    )

    // отображаем загрузку, если:
    // 1) нет инфы по бонусам
    // 2) есть инфа, есть номер карты, нет истории бонусов
    // 3) bonusesDisplayMode === BonusesDisplayStatus.initial
    if (
        bonusesDisplayMode === BonusesDisplayStatus.initial ||
        !bonusesInfo.bonuses ||
        (bonusesInfo.bonuses?.cardNumber && !bonusesInfo.bonusesHistory)
    )
        return <RingLoaderCustomized color={'white'} />

    return (
        <div className={'bonuses-promo'}>
            <div className={'bonuses-promo__content'}>
                <div className={'bonuses-promo__header'}>EUROINS CLUB</div>
                <div
                    className={
                        'bonuses-promo__changing-area changing-area text_bold'
                    }
                >
                    <RenderBonusesContent
                        showBonusesHistory={showBonusesHistory}
                        bonusesInfo={bonusesInfo}
                        bonusesDisplayMode={bonusesDisplayMode}
                    />
                </div>
                <a className={'bonuses-promo__rules'} href={'mock'}>
                    Правила программы EUROINS CLUB
                </a>
            </div>
        </div>
    )
}

export default React.memo(BonusesPromo)
