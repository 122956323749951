import {FileInfoStateType} from './index'
import validateEmail from '../../../helpers/for-validations/validate-email'
import {InfoInterface} from '../../../types'

const validate = (
    files: FileInfoStateType[],
    email: string,
    clientInfo: InfoInterface | null
): string[] => {
    const errors: string[] = []

    files.some(file => {
        if (file === null) {
            errors.push('Все файлы обязательны')
            return true
        }
        return false
    })

    if (!validateEmail(email)) errors.push('Введите корректный email')

    if (clientInfo === null) errors.push('Данные профиля еще не загружены')

    return errors
}

export default validate
