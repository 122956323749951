import React from 'react'

export const NotFound = () => {
    return (
        <div className={'main'}>
            <div className={'margin-wrapper'}>
                <h1 style={{width: '350px', margin: '100px auto'}}>
                    Страница не найдена
                </h1>
            </div>
        </div>
    )
}
