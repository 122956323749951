import {TYPES} from '../../actions/actionTypes'
import {LoadCityByIsnActionsType} from '../../../types/actions'
import {GetCityByIsnStateInterface} from '../../../types/state'

export const initialState: GetCityByIsnStateInterface = {
    requestStatus: 'didNotLoad',
    data: null,
    errorMessage: '',
}

export function cityByIsn(
    state = initialState,
    action: LoadCityByIsnActionsType
): GetCityByIsnStateInterface {
    switch (action.type) {
        case TYPES.LOAD_CITY_BY_ISN_STARTED:
            return {
                requestStatus: 'started',
                data: null,
                errorMessage: '',
            }
        case TYPES.LOAD_CITY_BY_ISN_SUCCESS:
            return {
                requestStatus: 'success',
                data: action.payload,
                errorMessage: '',
            }
        case TYPES.LOAD_CITY_BY_ISN_ERROR:
            return {
                requestStatus: 'error',
                data: null,
                errorMessage: action.payload,
            }
        default:
            return state
    }
}
