import * as React from 'react'
import {FC} from 'react'
import {Heading} from '../../kit/heading'
import ClientAgentPanel from '../../kit/client-agent-panel'

const HowWorkWithEuroins: FC = () => {
    return (
        <ClientAgentPanel>
            <Heading level={2} className={'text_center'}>
                Рекомендуй ЕВРОИНС и получай выплаты
            </Heading>
            <p className={'text_center'}>Как зарабатывать с ЕВРОИНС?</p>
            <p>
                Каждый клиент компании может стать агентом ЕВРОИНС, зарабатывая
                без лишних усилий. Предлагаем новый, уникальный метод, с которым
                Вы:
            </p>
            <ul>
                <li>
                    получаете доступ к инструментам рассылки с индивидуальным
                    кодом;
                </li>
                <li>приглашаете друзей и знакомых в личный кабинет ЕВРОИНС;</li>
                <li>
                    получаете вознаграждение за каждый оформленный полис в
                    приложении или в личном кабинете на сайте ЕВРОИНС, ведь
                    каждый зарегистрировавшийся в личном кабинете по
                    приглашению;
                </li>
                <li>Ваш клиент.</li>
            </ul>
            <p>
                Форма сотрудничества — агентский договор с комиссионным
                вознаграждением. Для того, чтобы начать сотрудничество,
                необходимо отправить заявку «Стать агентом».
            </p>
        </ClientAgentPanel>
    )
}

export default HowWorkWithEuroins
