import {
    loadDmsContractsError,
    loadDmsContractsStarted,
    loadDmsContractsSuccess,
} from '../actionCreators'
import {URL_DMS_CONTRACTS} from '../../../constants/urls'
import {handleResponse} from '../../../helpers/for-requests'
import {ReadDmsContractsResponseType} from '../../../types/responses/read-dms-contracts'

export function getDmsContracts() {
    return async function(dispatch: any) {
        dispatch(loadDmsContractsStarted())
        return fetch(URL_DMS_CONTRACTS, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((data: ReadDmsContractsResponseType) => {
                if (data.success === true) {
                    dispatch(loadDmsContractsSuccess(data.data))
                } else dispatch(loadDmsContractsError(data.errors[0]))
            })
            .catch((error: Error) =>
                dispatch(loadDmsContractsError(error.message))
            )
    }
}
