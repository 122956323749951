import * as React from 'react'
import {FC, memo} from 'react'
import ClientAgentPanel from '../../../kit/client-agent-panel'

interface BecomeAgentStatusInWorkPropsInterface {
    number?: string
    state?: string
}

const BecomeAgentStatusView: FC<BecomeAgentStatusInWorkPropsInterface> = memo(
    ({number = '', state = ''}) => {
        return (
            <ClientAgentPanel bgFilled>
                <p className={'become-agent__heading'}>Заявка отправлена!</p>
                <p className={'text_center'}>
                    Спасибо, Вами создана заявка на заключение агентского
                    договора. Ваши данные получены и будут рассмотрены в
                    ближайшее время.
                </p>
                <p className={'text_center text_bold'}>
                    Номер заявки: {number} <br />
                    Статус заявки: {state}
                </p>
            </ClientAgentPanel>
        )
    }
)

export default BecomeAgentStatusView
