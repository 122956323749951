import InviteRequestInterface from '../types/requests/invite'
import {InviteResponseType} from '../types/responses/invite'
import {URL_INVITE} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'

const invite = (data: InviteRequestInterface): Promise<InviteResponseType> =>
    fetch(URL_INVITE, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// const invite = (data: InviteRequestInterface): Promise<InviteResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: true,
//             })
//         }, 2000)
//     })

/*
    Фейковый ошибочный запрос
 */
// const invite = (data: InviteRequestInterface): Promise<InviteResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: false,
//                 errors: ['Ошибка №1', 'Ошибка №2'],
//             })
//         }, 2000)
//     })

export default invite
