import {
    loadAgreementsError,
    loadAgreementsStarted,
    loadAgreementsSuccess,
} from '../actionCreators'
import {URL_AGREEMENTS} from '../../../constants/urls'
import {handleResponse} from '../../../helpers/for-requests'
import {GetAgreementsResponseType} from '../../../types/responses'

export function getAgreements() {
    return async function(dispatch: any) {
        dispatch(loadAgreementsStarted())
        return fetch(URL_AGREEMENTS, {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((data: GetAgreementsResponseType) => {
                if (data.success) {
                    dispatch(loadAgreementsSuccess(data.data))
                } else dispatch(loadAgreementsError(data.error))
            })
            .catch((error: Error) =>
                dispatch(loadAgreementsError(error.message))
            )
    }
}
