import {TYPES} from '../../actions/actionTypes'
import {BonusesStateInterface} from '../../../types/state'
import {LoadBonusesActionsInterface} from '../../../types/actions'

export enum BonusesDisplayStatus {
    initial,
    notActivated,
    subEvent,
    activated,
}

export const initialState: BonusesStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
    displayStatus: BonusesDisplayStatus.initial,
}

export function clientBonuses(
    state = initialState,
    action: LoadBonusesActionsInterface
): BonusesStateInterface {
    switch (action.type) {
        case TYPES.SET_BONUSES_STATUS: {
            return {
                ...state,
                displayStatus: action.payload,
            }
        }
        case TYPES.LOAD_BONUSES_STARTED:
            return {
                ...initialState,
                requestStatus: 'started',
            }
        case TYPES.LOAD_BONUSES_SUCCESS:
            return {
                ...state,
                requestStatus: 'success',
                data: action.data,
            }
        case TYPES.LOAD_BONUSES_ERROR:
            return {
                ...state,
                requestStatus: 'error',
                errorMessage: action.errorMessage,
            }
        default:
            return state
    }
}
