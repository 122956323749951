import {PutDmsContractResponseType} from '../../types/responses/put-dms-contract'
import {URL_DMS_CONTRACT_SEARCH_AND_ADD} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'

const putDmsContract = (
    contract: number
): Promise<PutDmsContractResponseType> =>
    fetch(`${URL_DMS_CONTRACT_SEARCH_AND_ADD}/${contract}`, {
        method: 'PUT',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(handleResponse)

export default putDmsContract
