import {URL_READ_BECOME_AGENT_STATUS} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'
import {ReadBecomeAgentStatusResponseType} from '../types/responses/read-become-agent-status'
// import BecomeAgentStatusInterface from '../types/models/become-agent-status-interface'

const readBecomeAgentStatus = (): Promise<ReadBecomeAgentStatusResponseType> =>
    fetch(URL_READ_BECOME_AGENT_STATUS, {
        method: 'GET',
        credentials: 'include',
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// let requestCounter = 0
// const readBecomeAgentStatus = (): Promise<ReadBecomeAgentStatusResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             const data: BecomeAgentStatusInterface = {
//                 number: '',
//                 state: 'Нет заявок',
//             }
//             if (requestCounter++ === 1) {
//                 data.number = '45357349'
//                 data.state = 'В работе'
//             }
//
//             resolve({
//                 success: true,
//                 data,
//             })
//         }, 2500)
//     })

/*
    Фейковый ошибочный запрос
 */
// const readBecomeAgentStatus = () =>
//     fetch(URL_READ_BECOME_AGENT_STATUS, {
//         method: 'GET',
//         credentials: 'include',
//     }).then(handleResponse)

export default readBecomeAgentStatus
