import * as React from 'react'
import {useFormik} from 'formik'
import Button from '../../kit/button'
import './index.less'
import {ROUTES} from '../../../navigation/routes'
import validate from './validate'
import Field from '../../kit/forms-common/field'
import FieldWithMask from '../../kit/forms-common/field/with-mask'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'
import LinkBelowTheForm from '../../kit/forms-common/link-below-the-form'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {RegisterFormValuesInterface} from '../../../types/forms'
import initialValues from './initial-values'
import {RegistrationInterface} from '../../../types/requests'
import registrationRequest from '../../../requests/registration'
import CheckDmsContractRequestInterface from '../../../types/requests/check-dms-contract'
import checkDmsContract from '../../../requests/contracts/check-dms-contract'
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import {useState} from 'react'

const RegisterForm = ({onSuccess}: {onSuccess: () => void}) => {
    const {executeRecaptcha} = useGoogleReCaptcha()

    const [errors, setErrors] = useState<string>(''),
        formik = useFormik<RegisterFormValuesInterface>({
            initialValues: initialValues,
            validate,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: async values => {
                if (!executeRecaptcha) return

                const userToken = await executeRecaptcha('login') // берем данные о юзере чтобы отправить на бэк для проверки

                const checkDmsReqBody = extractCheckDmsContractReqBody(values)
                const res = await checkDmsContract(checkDmsReqBody)

                if ('check' in res && res.check) {
                    const registrationReqBody = extractRegistrationReqBody(
                        values,
                        userToken
                    )

                    await registrationRequest(registrationReqBody)
                        .then(json => {
                            if (json.success === true) {
                                onSuccess()
                            } else if (json.success === false) {
                                setErrors(
                                    Array.isArray(json.errors)
                                        ? json.errors.join('.\n')
                                        : json.errors.toString()
                                )
                            } else throw new Error(json.error)
                        })
                        .catch(error => {
                            setErrors(error.message)
                        })
                } else if ('check' in res && !res.check) {
                    Modal.error({
                        title: 'Проверка договора ДМС',
                        content:
                            'Договор не найден. Пожалуйста, обратитесь в техническую поддержку',
                    })
                } else {
                    Modal.error({
                        title: 'Ошибка проверки договора ДМС',
                        content: `Пожалуйста, обратитесь в техническую поддержку`,
                    })
                }
            },
        }),
        extractCheckDmsContractReqBody = (
            values: RegisterFormValuesInterface
        ): CheckDmsContractRequestInterface => {
            const {firstName, lastName, middleName, dmsAgrId} = values

            return {
                firstName,
                lastName,
                parentName: middleName,
                dmsAgrId,
            }
        },
        extractRegistrationReqBody = (
            values: RegisterFormValuesInterface,
            userToken: string
        ): RegistrationInterface => {
            const {firstName, lastName, middleName, birthday, phone} = values
            const formattedPhone = phone.replace(/\D/g, '').slice(1) // убираем все НЕ-цифры + семерку
            const reqBody: RegistrationInterface = {
                recaptchaResponse: userToken,
                firstName,
                lastName,
                middleName,
                birthday,
                phone: formattedPhone,
            }

            return reqBody
        }

    return (
        <form className={'register-form'} onSubmit={formik.handleSubmit}>
            <Field
                label={'Фамилия'}
                fieldName={'lastName'}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                errors={formik.errors.lastName}
                required={true}
            />
            <Field
                label={'Имя'}
                fieldName={'firstName'}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                errors={formik.errors.firstName}
                required={true}
            />
            <Field
                label={'Отчество'}
                fieldName={'middleName'}
                value={formik.values.middleName}
                onChange={formik.handleChange}
            />
            <FieldWithMask
                label={'Телефон'}
                fieldName={'phone'}
                value={formik.values.phone}
                onChange={formik.handleChange}
                errors={formik.errors.phone}
                mask={'+7 (999) 999-99-99'}
                type={'tel'}
                required={true}
            />
            <FieldWithMask
                label={'Дата рождения'}
                fieldName={'birthday'}
                value={formik.values.birthday}
                onChange={formik.handleChange}
                errors={formik.errors.birthday}
                mask={'99.99.9999'}
                required={true}
            />
            <Field
                label={'Номер договора ДМС (7-8 цифр)'}
                fieldName={'dmsAgrId'}
                value={formik.values.dmsAgrId}
                onChange={formik.handleChange}
                errors={formik.errors.dmsAgrId}
                required={true}
                placeholder={'Например: 9872772'}
            />
            <ErrorParagraph errors={errors} />
            <div className={'register-form__buttons'}>
                <Button buttonType={'primary'}>Зарегистрироваться</Button>
            </div>
            <LinkBelowTheForm to={ROUTES.AUTH}>
                Вернуться на страницу входа
            </LinkBelowTheForm>
        </form>
    )
}

export default React.memo(RegisterForm)
