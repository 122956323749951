import * as React from 'react'
import {FC, memo, useCallback, useState} from 'react'
import TextArea from '../../kit/forms-common/text-area'
import Field from '../../kit/forms-common/field'
import Button from '../../kit/button'
import {FormikErrors, useFormik} from 'formik'
import {InvitationSendingFormInterface} from '../../../types/forms'
import FieldWithMask from '../../kit/forms-common/field/with-mask'
import {phoneRE} from '../../../helpers/reg-expressions'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import './index.less'
import {RequestStateInterface} from '../../../types/state'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../helpers/for-changing-requests-state/create-request-state'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'
import inviteFetch from '../../../requests/invite'

interface InvitationSendingFormPropsInterface {
    className: string
}

const validate = (values: InvitationSendingFormInterface) => {
        console.log('validate ', values)
        const errors: FormikErrors<InvitationSendingFormInterface> = {}
        if (!phoneRE.test(values.phone)) errors.phone = 'Введите телефон'
        if (values.name.length < 3) errors.name = 'Введите ФИО'
        return errors
    },
    inviteInitialState = createInitialState<null>()

const InvitationSendingForm: FC<InvitationSendingFormPropsInterface> = memo(
    ({className}) => {
        const [invite, setInvite] = useState<RequestStateInterface<null>>(
                inviteInitialState
            ),
            handleBackClick = useCallback(e => {
                e.preventDefault()
                setInvite(inviteInitialState)
            }, []),
            handleSubmit = useCallback(
                async (values: InvitationSendingFormInterface) => {
                    console.log('handleSubmit ', values)
                    try {
                        setInvite(createStartedState())
                        const result = await inviteFetch(values)
                        if (result.success) setInvite(createSuccessState(null))
                        else setInvite(createErrorState(result.errors))
                    } catch (e) {
                        setInvite(createErrorState([e.message]))
                    }
                },
                []
            ),
            formik = useFormik<InvitationSendingFormInterface>({
                initialValues: {
                    name: '',
                    phone: '',
                },
                validateOnBlur: false,
                validateOnChange: false,
                validate,
                onSubmit: handleSubmit,
            })

        return (
            <form className={className} onSubmit={formik.handleSubmit}>
                <p className={'become-agent__heading'}>Форма отправки</p>

                {invite.requestStatus === 'started' ? (
                    <RingLoaderCustomized verticalMargin={'60px'} />
                ) : invite.requestStatus === 'success' ? (
                    <>
                        <p className={'text_center'}>
                            Сообщение отправлено адресату!
                        </p>
                        <p className={'text_center'}>
                            <Button
                                buttonType={'inverted'}
                                onClick={handleBackClick}
                            >
                                Назад
                            </Button>
                        </p>
                    </>
                ) : (
                    <>
                        <p className={'text_center'}>
                            Указанный Вами адресат получит сообщение от EUROINS
                        </p>
                        <div className={'text_center'}>
                            <Field
                                label={'Введите ФИО получателя:'}
                                fieldName={'name'}
                                errors={formik.errors.name}
                                required
                                component={
                                    <TextArea
                                        name={'name'}
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        rows={2}
                                    />
                                }
                            />
                            <FieldWithMask
                                inputClassName={'invitation-sending__phone'}
                                label={'Телефон получателя'}
                                mask={'+7 (999) 999-99-99'}
                                fieldName={'phone'}
                                value={formik.values.phone}
                                errors={formik.errors.phone}
                                type={'tel'}
                                onChange={formik.handleChange}
                                required
                            />
                            {invite.requestStatus === 'error' ? (
                                <ErrorParagraph errors={invite.errorMessage} />
                            ) : null}
                            <Button
                                className={'is-form__send-button'}
                                buttonType={'inverted'}
                            >
                                Отправить смс
                            </Button>
                        </div>
                    </>
                )}
            </form>
        )
    }
)

export default InvitationSendingForm
