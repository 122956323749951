import {URL_ADD_APPEAL} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'
import {SendAppealInterface} from '../../types/requests'

const sendAppealRequest = (data: SendAppealInterface) => {
    return fetch(URL_ADD_APPEAL, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse)
}

export default sendAppealRequest
