import * as React from 'react'
import '../index.less'
import InputMask from 'react-input-mask'
import ErrorParagraph from '../../error-paragraph'

interface FieldWithMaskPropsInterface {
    label: string
    mask: string
    fieldName: string
    value: string | string[] | number
    onChange: (
        eventOrPath: string | React.ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
    errors?: string | undefined
    inputClassName?: string
    type?: string
    required?: boolean
    disabled?: boolean
}

const FieldWithMask = ({
    mask,
    label,
    fieldName,
    value,
    onChange,
    errors = undefined,
    type = 'text',
    inputClassName = '',
    required = false,
    disabled = false,
}: FieldWithMaskPropsInterface) => {
    return (
        <>
            <div className={'field'}>
                <label className={'field__label'} htmlFor={fieldName}>
                    {required ? <span style={{color: 'red'}}>*</span> : null}{' '}
                    {label}
                </label>
                <InputMask
                    disabled={disabled}
                    mask={mask}
                    value={value}
                    onChange={onChange}
                >
                    {/* @ts-ignore */}
                    {(props: any) => (
                        <input
                            {...props}
                            name={fieldName}
                            value={value}
                            onChange={onChange}
                            className={`input ${inputClassName}`}
                            type={type}
                            disabled={disabled}
                        />
                    )}
                </InputMask>
            </div>
            {errors ? <ErrorParagraph errors={errors} /> : null}
        </>
    )
}

export default React.memo(FieldWithMask)
