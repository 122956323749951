import * as React from 'react'

const BonusesActivationError = () => {
    return (
        <>
            <div>
                <div
                    className={
                        'bonuses-promo__text_huge bonuses-promo__text_blue'
                    }
                >
                    Готово!
                </div>
                <div
                    className={
                        'bonuses-promo__text_big bonuses-promo__text_blue'
                    }
                >
                    Карта найдена в системе
                </div>
            </div>
            <div className={'bonuses-promo__text_middle text_weight-normal'}>
                <div>Привязка вашего профиля займет некоторое время.</div>
                <div>
                    Обратитесь в службу поддержки{' '}
                    <a href='tel:+74959265155'>+74959265155</a>
                </div>
            </div>
            <div className={'bonuses-promo__text_middle text_weight-normal'}>
                После успешной привязки карты Вам будет доступен раздел бонусы
            </div>
        </>
    )
}

export default React.memo(BonusesActivationError)
