import {TYPES} from '../../actions/actionTypes'
import {BonusesIsActivatedStateInterface} from '../../../types/state'
import {LoadBonusesIsActivatedActionsInterface} from '../../../types/actions'

export const initialState: BonusesIsActivatedStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
}

export function isActivated(
    state = initialState,
    action: LoadBonusesIsActivatedActionsInterface
): BonusesIsActivatedStateInterface {
    switch (action.type) {
        case TYPES.LOAD_BONUSES_IS_ACTIVATED_STARTED:
            return {
                requestStatus: 'started',
                errorMessage: '',
                data: null,
            }
        case TYPES.LOAD_BONUSES_IS_ACTIVATED_SUCCESS:
            return {
                requestStatus: 'success',
                errorMessage: '',
                data: action.data,
            }
        case TYPES.LOAD_BONUSES_IS_ACTIVATED_ERROR:
            return {
                requestStatus: 'error',
                errorMessage: action.errorMessage,
                data: null,
            }
        default:
            return state
    }
}
