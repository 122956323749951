import {
    loadBonusesError,
    loadBonusesHistoryError,
    loadBonusesHistoryStarted,
    loadBonusesHistorySuccess,
    loadBonusesIsActivatedError,
    loadBonusesIsActivatedStarted,
    loadBonusesIsActivatedSuccess,
    loadBonusesStarted,
    loadBonusesSuccess,
    setBonusesStatus,
    welcomeBonusesActivationError,
    welcomeBonusesActivationStarted,
    welcomeBonusesActivationSuccess,
} from '../actionCreators'
import {
    URL_BONUSES_ACTIVATE_WELCOME_BONUSES,
    URL_BONUSES_GET_AMOUNT,
    URL_BONUSES_HISTORY,
    URL_BONUSES_IS_WELCOME_BONUSES_ACTIVATED,
} from '../../../constants/urls'
import {handleResponse} from '../../../helpers/for-requests'
import {
    ActivateWelcomeBonusesResponseType,
    GetBonusesResponseType,
    IsWelcomeBonusesActivatedResponseType,
    LoadBonusesHistoryResponseType,
} from '../../../types/responses'
import {
    ActivateWelcomeBonusesDataRequestInterface,
    GetBonusesRequestDataInterface,
    IsWelcomeBonusesActivatedDataRequestInterface,
    LoadBonusesHistoryDataRequestInterface,
} from '../../../types/requests'
import {BonusesDisplayStatus} from '../../reducers/bonuses/count-and-card'

export function getBonuses(data: GetBonusesRequestDataInterface) {
    return async function(dispatch: any) {
        dispatch(loadBonusesStarted())
        return fetch(URL_BONUSES_GET_AMOUNT, {
            method: 'POST',
            body: JSON.stringify(data),
            //     credentials: "include"
        })
            .then(handleResponse)
            .then((json: GetBonusesResponseType) => {
                if (json.success) {
                    dispatch(loadBonusesSuccess(json.data))
                    if ('cardNumber' in json.data) {
                        dispatch(
                            setBonusesStatus(BonusesDisplayStatus.activated)
                        )
                    } else if (
                        'isSubEvent' in json.data &&
                        json.data!.isSubEvent
                    ) {
                        dispatch(
                            setBonusesStatus(BonusesDisplayStatus.subEvent)
                        )
                    } else {
                        dispatch(
                            setBonusesStatus(BonusesDisplayStatus.notActivated)
                        )
                    }
                } else dispatch(loadBonusesError(json.errors.join(' ')))
            })
            .catch((error: Error) => dispatch(loadBonusesError(error.message)))
    }
}

export function isBonusesActivated(
    data: IsWelcomeBonusesActivatedDataRequestInterface
) {
    return async function(dispatch: any) {
        dispatch(loadBonusesIsActivatedStarted())
        return fetch(URL_BONUSES_IS_WELCOME_BONUSES_ACTIVATED, {
            method: 'POST',
            body: JSON.stringify(data),
            //   credentials: "include"
        })
            .then(handleResponse)
            .then((json: IsWelcomeBonusesActivatedResponseType) => {
                if (json.success)
                    dispatch(loadBonusesIsActivatedSuccess(json.data))
                else
                    dispatch(
                        loadBonusesIsActivatedError(json.errors.join(', '))
                    )
            })
            .catch((error: Error) =>
                dispatch(loadBonusesIsActivatedError(error.message))
            )
    }
}

export function activateWelcomeBonuses(
    data: ActivateWelcomeBonusesDataRequestInterface
) {
    return async function(dispatch: any) {
        dispatch(welcomeBonusesActivationStarted())
        return fetch(URL_BONUSES_ACTIVATE_WELCOME_BONUSES, {
            method: 'POST',
            body: JSON.stringify(data),
            //   credentials: "include"
        })
            .then(handleResponse)
            .then((json: ActivateWelcomeBonusesResponseType) => {
                if (json.success) {
                    dispatch(welcomeBonusesActivationSuccess())
                    dispatch(getBonuses({kiasIsn: +data.kiasIsn}))
                } else if (!json.success && json.errors.error) {
                    dispatch(welcomeBonusesActivationError(json.errors.error))
                }
            })
            .catch(console.log)
    }
}

export const loadBonusesHistory = (
    data: LoadBonusesHistoryDataRequestInterface
) => async (dispatch: any) => {
    dispatch(loadBonusesHistoryStarted())
    return fetch(URL_BONUSES_HISTORY, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(handleResponse)
        .then((json: LoadBonusesHistoryResponseType) => {
            if (json.success) {
                dispatch(loadBonusesHistorySuccess(json.data))
            } else if (!json.success) {
                dispatch(loadBonusesHistoryError(json.error))
            }
        })
        .catch(console.log)
}
