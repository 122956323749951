import {FC, default as React, useMemo} from 'react'
import PersonalInfoItem from '../personal-info-item'
import formatDate from '../../../../../helpers/for-formatting/format-date'
import PersonalInfoCard from '../personal-info-card'
import {DriverLicenseFormInterface} from '../../../../../types/forms'
import {POPUP_FORM_ID} from '../personal-info-list'
import {ROUTES} from '../../../../../navigation/routes'
import PopupWrapper from '../popup-wrapper'
import DriverLicenseForm from '../../../../forms/driver-license-form'
import usePersonalInfoCardHooks from '../../hooks/use-personal-info-card-hooks'

interface DriverLicenseCardPropsInterface {
    data: DriverLicenseFormInterface
}

const CARD_TITLE = 'Водительское удостоверение'

const DriverLicenseCard: FC<DriverLicenseCardPropsInterface> = ({data}) => {
    const linkToPopup = useMemo(
            () => `${ROUTES.PERSONAL_DATA}/${POPUP_FORM_ID.DRIVER_LICENSE}`,
            []
        ),
        {goToPersonalData, closePopup, popupFormId} = usePersonalInfoCardHooks()

    const openPopup = popupFormId === POPUP_FORM_ID.DRIVER_LICENSE

    const values = React.useMemo(() => {
        return {
            issuedDate: formatDate(data.issuedDate),
            drivingDateBegin: formatDate(data.drivingDateBegin),
        }
    }, [data])

    return (
        <>
            <PersonalInfoCard title={CARD_TITLE} linkTo={linkToPopup}>
                <PersonalInfoItem label={'Серия'} value={data.serial} />
                <PersonalInfoItem label={'Номер'} value={data.number} />
                <PersonalInfoItem
                    label={'Дата выдачи'}
                    value={values.issuedDate}
                />
                <PersonalInfoItem
                    label={'Начало водительского стажа'}
                    value={values.drivingDateBegin}
                />
            </PersonalInfoCard>

            {/* <PopupWrapper open={openPopup} onClose={closePopup}>
                <DriverLicenseForm
                    title={CARD_TITLE}
                    data={data}
                    onSuccessfulDataChange={goToPersonalData}
                />
            </PopupWrapper> */}
        </>
    )
}

export default React.memo(DriverLicenseCard)
