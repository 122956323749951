import * as React from 'react'
import Paragraph from '../../../kit/paragraph'
import {ROUTES} from '../../../../navigation/routes'
import {Link} from 'react-router-dom'
import './index.less'

const AfterRegSuccess = (props: {}) => {
    return (
        <div className={'registration-success'}>
            <Paragraph>
                Успешно! Вам выслано смс с паролем для входа на указанный
                мобильный телефон.
            </Paragraph>
            <Link className='registration-success__link' to={ROUTES.AUTH}>
                Вернуться на страницу входа
            </Link>
        </div>
    )
}

export default React.memo(AfterRegSuccess)
