import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import ClientAgentPanel from '../../kit/client-agent-panel'
import './index.less'
import Button from '../../kit/button'
import {RequestStateInterface} from '../../../types/state'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../helpers/for-changing-requests-state/create-request-state'
import {InvitationsType} from '../../../types/models/invitation'
import readInvitations from '../../../requests/read-invitations'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'

interface ClientAgentRegistryPropsInterface {
    onBack: () => void
}

const initialState = createInitialState<InvitationsType>()

const ClientAgentRegistry: FC<ClientAgentRegistryPropsInterface> = ({
    onBack,
}) => {
    const [invitations, setInvitations] = useState<
            RequestStateInterface<InvitationsType>
        >(initialState),
        handleComponentDidMount = useCallback(async () => {
            try {
                setInvitations(createStartedState())
                const result = await readInvitations()
                if (result.success)
                    setInvitations(createSuccessState(result.data))
                else setInvitations(createErrorState(result.errors))
            } catch (e) {
                setInvitations(createErrorState([e.message]))
            }
        }, [])

    useEffect(() => {
        handleComponentDidMount().then()
    }, [handleComponentDidMount])

    return (
        <ClientAgentPanel>
            <p className={'become-agent__heading'}>Реестр рекомендаций</p>
            <p className={'text_center'}>
                Кто из Ваших адресатов воспользовался промокодом
            </p>
            {invitations.requestStatus === 'started' ? (
                <RingLoaderCustomized verticalMargin={'60px auto 0'} />
            ) : invitations.requestStatus === 'error' ? (
                <ErrorParagraph errors={invitations.errorMessage} />
            ) : invitations.data !== null && invitations.data.length < 1 ? (
                <p>Вы пока не отправляли приглашений</p>
            ) : (
                <ul className={'registry-list client-agent-registry__list'}>
                    {invitations.data?.map((invitation, i) => (
                        <li className={'registry-list__item'} key={i}>
                            <div>
                                <p className={'registry-list__phone'}>
                                    {invitation.phone}
                                </p>
                                <div className={'registry-list__date'}>
                                    {invitation.date}
                                </div>
                                <div className={'registry-list__client-name'}>
                                    {invitation.name}
                                </div>
                            </div>
                            <div className={'registry-list__status'}>
                                {invitation.registered
                                    ? 'Активирован'
                                    : 'Не активирован'}
                            </div>
                        </li>
                    ))}
                </ul>
            )}

            <div className={'text_center'}>
                <Button buttonType={'secondary'} onClick={onBack}>
                    Назад
                </Button>
            </div>
        </ClientAgentPanel>
    )
}

export default ClientAgentRegistry
