import {FC, default as React} from 'react'
import Row from 'antd/lib/row'
import 'antd/lib/row/style'
import Col from 'antd/lib/col'
import 'antd/lib/col/style'
import {InfoInterface} from '../../../../../types'
import {
    ContactsFormInterface,
    DriverLicenseFormInterface,
    PassportFormInterface,
    RegistrationAddressFormInterface,
} from '../../../../../types/forms'
import RingLoaderCustomized from '../../../../kit/ring-loader-customized'
import PassportCard from '../passport-card'
import ContactDataCard from '../contact-data-card'
import DriverLicenseCard from '../driver-license-card'
import './index.less'
import RegistrationAddressCard from '../registration-address-card'

interface PersonalInfoListPropsInterface {
    data: InfoInterface | null
}

export enum POPUP_FORM_ID {
    PASSPORT = 'passport',
    CONTACT_DATA = 'contact-data',
    DRIVER_LICENSE = 'driver-license',
    REGISTRATION_ADDRESS = 'registration-address',
}

const cardSizes = {
    xs: 24,
    sm: 24,
    md: 12,
    xl: 8,
    xxl: 6,
}

const PersonalInfoList: FC<PersonalInfoListPropsInterface> = ({data}) => {
    if (data === null) return <RingLoaderCustomized />

    const {driverLicense, passport} = data,
        contactData: ContactsFormInterface = {
            birthday: data.birthday,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            phone: data.phone,
            sex: data.sex,
        },
        driverLicenseData: DriverLicenseFormInterface = {
            ...driverLicense,
            drivingDateBegin: driverLicense.drivingDateBegin
                ? driverLicense.drivingDateBegin
                : '',
            issuedDate: driverLicense.issuedDate
                ? driverLicense.issuedDate
                : '',
        },
        passportData: PassportFormInterface = {
            ...passport,
            issuedDate: passport.issuedDate ? passport.issuedDate : '',
        },
        registrationAddress: RegistrationAddressFormInterface | null = data.addresses
            ? data.addresses[0]
            : null

    return (
        <Row gutter={[24, 30]} className={'personal-data-list'}>
            <Col {...cardSizes}>
                <ContactDataCard data={contactData} />
            </Col>
            <Col {...cardSizes}>
                <PassportCard data={passportData} />
            </Col>
            <Col {...cardSizes}>
                <DriverLicenseCard data={driverLicenseData} />
            </Col>
            <Col {...cardSizes}>
                <RegistrationAddressCard data={registrationAddress} />
            </Col>
        </Row>
    )
}

export default React.memo(PersonalInfoList)
