import {URL_FILE} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'
import GetListOfDocumentsRequestInterface from '../types/requests/get-list-of-documents'
import {GetListOfDocumentsResponseType} from '../types/responses/get-list-of-documents'

const getListOfDocuments = (
    data: GetListOfDocumentsRequestInterface,
    type: string = '?type=A'
): Promise<GetListOfDocumentsResponseType> =>
    fetch(`${URL_FILE}/${data.isn}${type}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(handleResponse)

/*
    Фейковый успешный запрос
 */
// const getListOfDocuments = (
//     data: GetListOfDocumentsRequestInterface
// ): Promise<GetListOfDocumentsResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: true,
//                 data: [],
//             })
//         }, 2000)
//     })

/*
    Фейковый ошибочный запрос
 */
// const getListOfDocuments = (
//     data: GetListOfDocumentsRequestInterface
// ): Promise<GetListOfDocumentsResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({
//                 success: false,
//                 errors: ['Ошибка №1', 'Ошибка №2'],
//             })
//         }, 2000)
//     })

export default getListOfDocuments
