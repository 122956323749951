import {TYPES} from '../actions/actionTypes'
import {AppealsStateInterface} from '../../types/state'
import {LoadAppealsActionsType} from '../../types/actions'

export const initialState: AppealsStateInterface = {
    requestStatus: 'didNotLoad',
    errorMessage: '',
    data: null,
}

export function appeals(
    state = initialState,
    action: LoadAppealsActionsType
): AppealsStateInterface {
    switch (action.type) {
        case TYPES.LOAD_APPEALS_STARTED:
            return {
                requestStatus: 'started',
                errorMessage: '',
                data: null,
            }
        case TYPES.LOAD_APPEALS_SUCCESS:
            return {
                requestStatus: 'success',
                errorMessage: '',
                data: action.data,
            }
        case TYPES.LOAD_APPEALS_ERROR:
            return {
                requestStatus: 'error',
                errorMessage: action.errorMessage,
                data: null,
            }
        default:
            return state
    }
}
