import CashBackRequestInterface from '../types/requests/cash-back'
import {CreateCashBackClaimResponseType} from '../types/responses/create-cashback-claim'
import {URL_CASH_BACK} from '../constants/urls'
import {handleResponse} from '../helpers/for-requests'

const createCashBackClaim = (
    data: CashBackRequestInterface
): Promise<CreateCashBackClaimResponseType> =>
    fetch(URL_CASH_BACK, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse)

// const createCashBackClaim = (
//     data: CashBackRequestInterface
// ): Promise<CreateCashBackClaimResponseType> =>
//     new Promise(resolve => {
//         setTimeout(() => {
//             resolve({success: true})
//         }, 2000)
//     })

export default createCashBackClaim
