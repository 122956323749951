import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import BecomeAgentForm from '../../forms/become-agent-form'
import BecomeAgentStatusView from './become-agent-status-view'
import InitialPanel from './initial-panel'
import {RequestStateInterface} from '../../../types/state'
import BecomeAgentStatusInterface from '../../../types/models/become-agent-status-interface'
import InvitationSending from '../invitation-sending'
import ClientAgentRegistry from '../client-agent-registry'

enum PANEL_ROUTES {
    INITIAL_PANEL,
    BECOME_AGENT_FORM,
    BECOME_AGENT_STATUS_VIEW,
    INVITATION_SENDING_FORM,
    REGISTRY,
}

interface ClientAgentSecondPanelPropsInterface {
    onSubmitBecomeAgentForm: () => void
    becomeAgentStatus: RequestStateInterface<BecomeAgentStatusInterface>
}

const ClientAgentSecondPanel: FC<ClientAgentSecondPanelPropsInterface> = ({
    onSubmitBecomeAgentForm,
    becomeAgentStatus,
}) => {
    const [panelRoute, setPanelRoute] = useState<PANEL_ROUTES>(
            PANEL_ROUTES.INITIAL_PANEL
        ),
        handleShowRegistry = useCallback(() => {
            setPanelRoute(PANEL_ROUTES.REGISTRY)
        }, []),
        handleBack = useCallback(() => {
            setPanelRoute(PANEL_ROUTES.INVITATION_SENDING_FORM)
        }, [])

    useEffect(() => {
        if (becomeAgentStatus.data === null) return

        if (becomeAgentStatus.data.state === 'В работе')
            setPanelRoute(PANEL_ROUTES.BECOME_AGENT_STATUS_VIEW)
        if (becomeAgentStatus.data.state === 'Подписан')
            setPanelRoute(PANEL_ROUTES.INVITATION_SENDING_FORM)
        if (
            becomeAgentStatus.data.state === 'Аннулирован' ||
            becomeAgentStatus.data.state === 'Нет заявок'
        )
            setPanelRoute(PANEL_ROUTES.BECOME_AGENT_FORM)
    }, [becomeAgentStatus])

    switch (panelRoute) {
        // рендерится по умолчанию если статус 'Нет заявок'
        case PANEL_ROUTES.BECOME_AGENT_FORM:
            return (
                <BecomeAgentForm
                    onSubmitBecomeAgentForm={onSubmitBecomeAgentForm}
                />
            )
        case PANEL_ROUTES.BECOME_AGENT_STATUS_VIEW:
            return (
                <BecomeAgentStatusView
                    number={becomeAgentStatus.data?.number}
                    state={becomeAgentStatus.data?.state}
                />
            )
        case PANEL_ROUTES.INVITATION_SENDING_FORM:
            return <InvitationSending onShowRegistry={handleShowRegistry} />
        case PANEL_ROUTES.REGISTRY:
            return <ClientAgentRegistry onBack={handleBack} />
        case PANEL_ROUTES.INITIAL_PANEL:
            return <InitialPanel />
    }
}

export default ClientAgentSecondPanel
