import {ReadDmsContractResponseType} from '../../types/responses/read-dms-contract'
import {URL_DMS_CONTRACTS} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'

const getDmsContract = (
    isn: number | string
): Promise<ReadDmsContractResponseType> =>
    fetch(`${URL_DMS_CONTRACTS}/${isn}`, {credentials: 'include'}).then(
        handleResponse
    )

export default getDmsContract
