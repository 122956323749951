export enum CALCULATOR_PATHS {
    TRAVEL_MAIN = 'travel/main',
    OSAGO_STANDARD = 'osago/client',
}

export function addCalculatorToPage(pathPart: CALCULATOR_PATHS) {
    const scriptNode = document.createElement('script')
    scriptNode.src = `https://euro-ins.ru/front/dist/js/calc/${pathPart}/loader.js?${Date.now()}`
    scriptNode.setAttribute('type', 'text/javascript')
    scriptNode.setAttribute('async', 'true')
    document.head.append(scriptNode)
}

export function removeCalculatorFromPage(pathPart: CALCULATOR_PATHS) {
    const js = document.querySelectorAll(`[src*="${pathPart}"]`),
        css = document.querySelectorAll(`[href*="${pathPart}"]`)
    js.forEach(node => node.parentNode && node.parentNode.removeChild(node))
    css.forEach(node => node.parentNode && node.parentNode.removeChild(node))
}
