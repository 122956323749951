import * as React from 'react'
import {FC, useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootStateType} from '../../../store/reducers'
import Table from 'antd/lib/table'
import 'antd/lib/table/style'
import {Header} from '../../layout/header'
import MarginWrapper from '../../layout/margin-wrapper'
import {URL_FILE} from '../../../constants/urls'
import DmsContractMainPartInterface from '../../../types/models/dms-contract-main-part'
import moment from 'moment'
import './index.less'
import SearchDmsContractForm from '../../forms/search-dms-contract-form'
import Button from '../../kit/button'
import hideJdiv from '../../../helpers/jivo/hide-jdiv'
import RingLoaderCustomized from '../../kit/ring-loader-customized'
import PageHeader from './components/page-header'
import ELink from '../../kit/e-link'
import {ROUTES} from '../../../navigation/routes'
import SavePolicy from './contract/save-policy'
import {getDmsContracts} from '../../../store/actions/creators-for-requests/dmsContracts'

const dateFormat = 'DD.MM.YYYY'

const handleDownloadProgram = (record: DmsContractMainPartInterface) => {
    try {
        // @ts-ignore
        window
            .open(
                `${URL_FILE}/${record.subDocIsn}/${record.docPictIsn}?type=J`,
                '_blank'
            )
            .focus()
    } catch (errors) {
        console.groupCollapsed(`Перечисление ошибок:`)
        console.error(errors)
        console.groupEnd()
    }
}

const Dms: FC = () => {
    const getDmsContractsState = useSelector(
        (state: RootStateType) => state.dmsContracts
    )

    const [accountIsLoading, setAccountIsLoading] = useState<boolean>(true)

    const dispatch = useDispatch(),
        {data: accountInfo} = useSelector((state: RootStateType) => state.info)

    useEffect(() => {
        if (accountInfo?.lastName) {
            setAccountIsLoading(false)
        }
    }, [accountInfo])

    const runGetDmsContracts = useCallback(() => {
        dispatch(getDmsContracts())
    }, [])

    useEffect(
        () => () => {
            hideJdiv()
        },
        []
    )

    return (
        <>
            <Header />
            <main className={'main dms-main'}>
                <MarginWrapper>
                    <PageHeader />

                    <SearchDmsContractForm
                        className={'dms-main__search-form'}
                        runGetDmsContracts={runGetDmsContracts}
                        contracts={getDmsContractsState.data || []}
                    />

                    <Table
                        dataSource={getDmsContractsState.data || []}
                        rowKey={record => record.isn}
                        loading={
                            getDmsContractsState.requestStatus === 'started'
                        }
                        pagination={false}
                    >
                        <Table.Column
                            title='Номер договора'
                            dataIndex='id'
                            key='id'
                        />
                        <Table.Column
                            title='Статус'
                            dataIndex='status'
                            key='status'
                        />
                        <Table.Column
                            title='Дата начала'
                            dataIndex='beginDate'
                            key='beginDate'
                            sorter={(a: string, b: string, sortOrder) => {
                                if (a === b) return 0
                                if (
                                    moment(a, dateFormat).isBefore(
                                        moment(b, dateFormat)
                                    )
                                )
                                    return -1
                                else return 1
                            }}
                            defaultSortOrder='descend'
                        />
                        <Table.Column
                            title='Дата окончания'
                            dataIndex='endDate'
                            key='endDate'
                        />
                        <Table.Column
                            title='Программа'
                            dataIndex='docIsn'
                            key='docIsn'
                            render={(
                                docIsn,
                                record: DmsContractMainPartInterface
                            ) => (
                                <>
                                    <ELink
                                        buttonType={'primary'}
                                        style={{margin: '5px'}}
                                        to={ROUTES.DMS_CONTRACT.replace(
                                            ':isn',
                                            String(record.isn)
                                        )}
                                    >
                                        Договор
                                    </ELink>
                                    {accountIsLoading ? (
                                        <RingLoaderCustomized
                                            size={10}
                                            verticalMargin={'0px'}
                                        />
                                    ) : (
                                        <SavePolicy contractIsn={record.isn} />
                                    )}
                                    {record.subDocIsn !== 0 &&
                                        record.docPictIsn !== 0 && (
                                            <Button
                                                style={{margin: '5px'}}
                                                onClick={() => {
                                                    handleDownloadProgram(
                                                        record
                                                    )
                                                }}
                                            >
                                                Программа
                                            </Button>
                                        )}
                                </>
                            )}
                        />
                    </Table>
                </MarginWrapper>
            </main>
        </>
    )
}

export default Dms
